import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import ErrorField from "../../../Components/ErrorField";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  HTTPResponse,
  PageDesignConstants,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import {
  CommonError_Msgs,
  NDMMSCTCharecteristics_Msgs,
} from "../../../Helpers/HelperText";
import {
  DateInRange,
  //AllowOnlyNumbersOneToNine,
  GetLocalStorageData,
  GetLoggedInUserID,
  formatDate,
  isNumberOrEmpty,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import { AppContext } from "../../../Contexts/AppContextProvider";
import { AdditionalTreatmentService } from "../../../WebApiServices/AdditionalTreatment.service";
import DateControl from "../../../Components/DateControl";
import DatePickerComponent from "../../../Components/DatePickerComponent";
import dayjs from "dayjs";

const NDMMSCTCharacteristics = () => {
  const { ShowToast, ToggleLoader, HandleSessionTimeout, isSideMenuActive, setIsSideMenuActive } =
    useContext(AppContext);

  const [cohortTreatmentName, setCohortTreatmentName] = useState("");

  const [ndmmSCTCharData, setNdmmSCTCharData] = useState({
    NdmmCharId: 0,
    CohortTreatmentId: 0,
    ndmmEndFollowupDate: "",
    InductionEndDate: "",
    consolidationAgentStartDate: "",
    maintainenceStartDate: "",
    endOfFollowupDate: "",

    // Question 34
    ComorbidMedAge: false,
    ComorbidMedComorbidity: false,
    ComorbidMedOrganFunction: false,
    ComorbidMedPatientPreference: false,
    ComorbidMedOther: "",
    ComorbidMedOtherTxt: "",
    ComorbidMedUnk: false,

    // Question 35
    SCTTreatment: null,

    // Question 35.1
    NotRecieveSCT: null,

    // Question 35.2 A
    SCTRecievedA: null,
    SCTRecievedADate: "",

    // Question 35.2 B
    SCTRecievedB: null,

    // Question 35.2 C
    SCTRecievedC: null,
    SCTRecievedCNum: null,

    // Question 35.2 D
    SCTRecievedD: null,
    SCTRecievedDNum: null,

    // Question 35.2 E
    SCTRecievedE: null,
    SCTRecievedENum: null,

    // Question 35.2 F
    SCTRecievedF: null,
    SCTRecievedFTxt: null,
  });

  const [errorMsg, setErrorMsg] = useState({
    ComorbidMedEmpty: false,
    ComorbidTxtEmpty: false,
    SCTTreatmentEmpty: false,
    NotRecieveSCTEmpty: false,
    SCTRecievedBEmpty: false,
    SCTRecievedCEmpty: false,
    SCTRecievedCNumEmpty: false,
    SCTRecievedDEmpty: false,
    SCTRecievedDNumEmpty: false,
    SCTRecievedEEmpty: false,
    SCTRecievedENumEmpty: false,
    SCTRecievedFEmpty: false,
    SCTRecievedFTxtEmpty: false,
    SCTRecievedAEmpty: false,
    SCTRecievedADateEmpty: false,
    SCTRecievedADateValid: false,
    SCTRecievedAInvalidDate:false,
  });

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const pid = GetLocalStorageData("patientId") ?? 0;
  const storedData = GetLocalStorageData("CohortTreatment");
  const endOfFollowupDate = GetLocalStorageData("EndOfFollowUp");

  let cohortTreatmentModelListt = [];
  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
  }

  const TTIsPresent = cohortTreatmentModelListt.some(
    (treatment) =>
      (treatment.cohortTreatmentID === 9 ||
        treatment.cohortTreatmentID === 10) &&
      treatment.isEligible === true
  );

  const CarTIsPresent = cohortTreatmentModelListt.some(
    (treatment) =>
      (treatment.cohortTreatmentID === 13 ||
        treatment.cohortTreatmentID === 14) &&
      treatment.isEligible === true
  );

  const navigatePaths = {
    prevPage: "/NonCohortDefiningTreatment",
    currPage: "/NDMMSCTCharacteristics",
    nextPage: TTIsPresent
      ? "/TTCharacteristics"
      : CarTIsPresent
      ? "/CarTCharacteristics"
      : "/IMWGReporting",
  };

  function isValidNumber(value) {
    // Return false if the value is null
    if (value === null) {
      return false;
    }

    // Convert value to a number
    const number = parseFloat(value);

    // Check if the value is a number and within the range 0 to 20
    return !isNaN(number) && number >= 0 && number <= 20;
  }

  function isValidDecimal(value) {
    // Convert value to a number
    const number = parseFloat(value);

    // Regular expression to match numbers with up to one decimal place
    const decimalRegex = /^\d{1,2}(\.\d)?$|^100(\.0)?$/;

    // Validate that the number is within the range and matches the decimal format
    return (
      !isNaN(number) && number >= 0 && number <= 100 && decimalRegex.test(value)
    );
  }

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "ComorbidMedAge":
      case "ComorbidMedComorbidity":
      case "ComorbidMedOrganFunction":
      case "ComorbidMedPatientPreference":
      case "ComorbidMedOther":
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "ComorbidMedOther" && { ComorbidMedOtherTxt: "" }),
          ComorbidMedUnk: false,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          ComorbidMedEmpty: false,
          ComorbidTxtEmpty: false,
        }));
        break;

      case "ComorbidMedOtherTxt": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: value,
          ComorbidMedOther: true,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          ComorbidMedEmpty: false,
          ComorbidTxtEmpty: value === "",
        }));

        break;
      }

      case "ComorbidMedUnk": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
          ComorbidMedAge: false,
          ComorbidMedComorbidity: false,
          ComorbidMedOrganFunction: false,
          ComorbidMedPatientPreference: false,
          ComorbidMedOther: false,
          ComorbidMedOtherTxt: "",
        }));

        setErrorMsg((prev) => ({
          ...prev,
          ComorbidMedEmpty: false,
        }));

        break;
      }

      case "SCTTreatment": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
          SCTTreatment: Number(value), // Changed from sctTreatmentValue to Number(value)
          NotRecieveSCT: Number(value) === 2 ? prev.NotRecieveSCT : null,
          SCTRecievedA: null,
          SCTRecievedADate: "",
          SCTRecievedB: null,
          SCTRecievedC: null,
          SCTRecievedCNum: null,
          SCTRecievedD: null,
          SCTRecievedDNum: null,
          SCTRecievedE: null,
          SCTRecievedENum: null,
          SCTRecievedF: null,
          SCTRecievedFTxt: null,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          SCTTreatmentEmpty: false,
          NotRecieveSCTEmpty: false,
          SCTRecievedAEmpty: false,
          SCTRecievedBEmpty: false,
          SCTRecievedCEmpty: false,
          SCTRecievedCNumEmpty: false,
          SCTRecievedDEmpty: false,
          SCTRecievedDNumEmpty: false,
          SCTRecievedEEmpty: false,
          SCTRecievedENumEmpty: false,
          SCTRecievedFEmpty: false,
          SCTRecievedFTxtEmpty: false,
          SCTRecievedADateValid: false,
          SCTRecievedADateEmpty: false,
        }));

        break;
      }

      case "NotRecieveSCT": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
        }));

        setErrorMsg((prev) => ({
          ...prev,
          NotRecieveSCTEmpty: false,
        }));

        break;
      }

      case "SCTRecievedA": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
          SCTRecievedADate: "",
        }));

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedAEmpty: false,
          SCTRecievedADateValid: false,
          SCTRecievedADateEmpty: false,
          SCTRecievedAInvalidDate:false,
        }));

        break;
      }

      case "SCTRecievedB": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
        }));

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedBEmpty: false,
        }));

        break;
      }

      case "SCTRecievedC": {
        if (value == 99) {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: Number(value),
            SCTRecievedCNum: "",
          }));
        } else {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: value === null ? null : Number(value), // Handle empty string by setting it to null
          }));
        }

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedCEmpty: false,
          SCTRecievedCNumEmpty: false,
        }));

        break;
      }

      case "SCTRecievedCNum": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: value,
          SCTRecievedC: 1,
        }));
        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedCEmpty: false,
          SCTRecievedCNumEmpty: false,
        }));

        break;
      }

      case "SCTRecievedD": {
        if (value == 99) {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: Number(value),
            SCTRecievedDNum: "",
            //SCTRecievedDNumEmpty:false,
          }));
        } else {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: value === "" ? null : Number(value), // Handle empty string by setting it to null
          }));
        }

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedDEmpty: false,
          SCTRecievedDNumEmpty: false,
        }));

        break;
      }

      case "SCTRecievedDNum": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: value,
          SCTRecievedD: 1,
        }));
        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedDEmpty: false,
          SCTRecievedDNumEmpty: false,
        }));

        break;
      }
      case "SCTRecievedE": {
        if (value == 99) {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: Number(value),
            SCTRecievedENum: "",
          }));
        } else {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: value === "" ? null : Number(value), // Handle empty string by setting it to null
          }));
        }

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedEEmpty: false,
          SCTRecievedENumEmpty: false,
        }));

        break;
      }

      case "SCTRecievedENum": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: value,
          SCTRecievedE: 1,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedEEmpty: false,
          SCTRecievedENumEmpty: false,
        }));

        break;
      }

      case "SCTRecievedF": {
        if (value == 99) {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: Number(value),
            SCTRecievedFTxt: "",
          }));
        } else {
          setNdmmSCTCharData((prev) => ({
            ...prev,
            [name]: value === "" ? null : Number(value), // Handle empty string by setting it to null
            SCTRecievedF: Number(value),
            SCTRecievedFTxt: "",
          }));
        }

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedFEmpty: false,
          SCTRecievedFTxtEmpty: false,
        }));

        break;
      }

      case "SCTRecievedFTxt": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: value,
          SCTRecievedF: 6,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          SCTRecievedFTxtEmpty: false,
          SCTRecievedFEmpty: false,
        }));

        break;
      }
    }
  };

  function parseDate(dateStr) {
    const [month, day, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  }

  function validateTreatmentDates(
    cohortIndexDateStr,
    sctDateStr,
    inductionEndDateStr,
    consolidationStartDateStr = null,
    maintenanceStartDateStr = null,
    endOfFollowUpDateStr = null
  ) {
    debugger
    const cohortIndexDate = cohortIndexDateStr ? parseDate(cohortIndexDateStr) : null;
    const sctDate = sctDateStr ? parseDate(sctDateStr) : null;
    const inductionEndDate = inductionEndDateStr ? parseDate(inductionEndDateStr) : null;
    const consolidationStartDate = consolidationStartDateStr ? parseDate(consolidationStartDateStr) : null;
    const maintenanceStartDate = maintenanceStartDateStr ? parseDate(maintenanceStartDateStr) : null;
    const endOfFollowUpDate = endOfFollowUpDateStr ? parseDate(endOfFollowUpDateStr) : null;
  
    // Check date range only for non-null dates
    const dateInRange = 
      (!cohortIndexDate || !sctDate || cohortIndexDate <= sctDate) &&
      (!sctDate || !endOfFollowUpDate || sctDate <= endOfFollowUpDate);
  
    if (dateInRange) {
      // Validate that inductionEndDate ≤ SCT date (if both are known)
      if (inductionEndDate && sctDate && inductionEndDate > sctDate) {
        return false;
      }
  
      // Validate that SCT date ≤ consolidationStartDate (if both are known)
      if (sctDate && consolidationStartDate && sctDate > consolidationStartDate) {
        return false;
      }
  
      // Validate that SCT date ≤ maintenanceStartDate (if both are known)
      if (sctDate && maintenanceStartDate && sctDate > maintenanceStartDate) {
        return false;
      }
  
      // Validate that SCT date ≤ endOfFollowUpDate (if both are known)
      if (sctDate && endOfFollowUpDate && sctDate > endOfFollowUpDate) {
        return false;
      }
  
      // If all applicable validations pass, return true
      return true;
    }
  
    return false;
  }
  
  

  const HandleDateChange = (ctrlId, date) => {
    debugger
    if (ctrlId === "SCTRecievedADate" && date !== "") {
      setNdmmSCTCharData((prevData) => ({
        ...prevData,
        SCTRecievedADate: date,
        SCTRecievedA: 1,
      }));
      setErrorMsg((prev) => ({
        ...prev,
        SCTRecievedADateEmpty: false,
        SCTRecievedADateValid: false,
        SCTRecievedAEmpty: false,
        SCTRecievedAInvalidDate:false,
      }));
    }
  };

  const ValidateForm = () => {
    let valid = true;
    let errors = {
      ComorbidMedEmpty: false,
      ComorbidTxtEmpty: false,
      SCTTreatmentEmpty: false,
      NotRecieveSCTEmpty: false,
      SCTRecievedBEmpty: false,
      SCTRecievedCEmpty: false,
      SCTRecievedCNumEmpty: false,
      SCTRecievedDEmpty: false,
      SCTRecievedDNumEmpty: false,
      SCTRecievedEEmpty: false,
      SCTRecievedENumEmpty: false,
      SCTRecievedFEmpty: false,
      SCTRecievedFTxtEmpty: false,
      SCTRecievedAEmpty: false,
      SCTRecievedADateEmpty: false,
      SCTRecievedADateValid: false,
      SCTRecievedAInvalidDate:false,
    };

    if (
      cohortTreatmentModelListt[0].ndmm === true &&
      cohortTreatmentModelListt[0].isEligible === true &&
      (cohortTreatmentName === "DR (TIE)" ||
        cohortTreatmentName === "DRd (TIE)" ||
        cohortTreatmentName === "DVR (TIE)" ||
        cohortTreatmentName === "DVRd (TIE)" ||
        cohortTreatmentName === "VR (TIE)" ||
        cohortTreatmentName === "VRd (TIE)" ||
        cohortTreatmentName === "DKR (TIE)" ||
        cohortTreatmentName === "DKRd (TIE)" ||
        cohortTreatmentName === "IsaVR (TIE)" ||
        cohortTreatmentName === "IsaVRd (TIE)")
    ) {
      if (
        !ndmmSCTCharData.ComorbidMedAge &&
        !ndmmSCTCharData.ComorbidMedComorbidity &&
        !ndmmSCTCharData.ComorbidMedOrganFunction &&
        !ndmmSCTCharData.ComorbidMedPatientPreference &&
        !ndmmSCTCharData.ComorbidMedOther &&
        !ndmmSCTCharData.ComorbidMedUnk
      ) {
        errors.ComorbidMedEmpty = true;
        valid = false;
      }

      if (
        ndmmSCTCharData.ComorbidMedOther &&
        ndmmSCTCharData.ComorbidMedOtherTxt === ""
      ) {
        errors.ComorbidTxtEmpty = true;
        valid = false;
      }
    }

    //35.1
    if (
      cohortTreatmentModelListt[0].ndmm === true &&
      cohortTreatmentModelListt[0].isEligible === true &&
      (cohortTreatmentName === "DR (TE)" ||
        cohortTreatmentName === "DRd (TE)" ||
        cohortTreatmentName === "DVR (TE)" ||
        cohortTreatmentName === "DVRd (TE)" ||
        cohortTreatmentName === "VR (TE)" ||
        cohortTreatmentName === "VRd (TE)" ||
        cohortTreatmentName === "DKR (TE)" ||
        cohortTreatmentName === "DKRd (TE)" ||
        cohortTreatmentName === "IsaVR (TE)" ||
        cohortTreatmentName === "IsaVRd (TE)")
    ) {
      if (ndmmSCTCharData.SCTTreatment === null) {
        errors.SCTTreatmentEmpty = true;
        valid = false;
      }

      if (ndmmSCTCharData.SCTTreatment == 2) {
        if (
          ndmmSCTCharData.NotRecieveSCT === null ||
          ndmmSCTCharData.NotRecieveSCT === 0 ||
          ndmmSCTCharData.NotRecieveSCT === ""
        ) {
          errors.NotRecieveSCTEmpty = true;
          valid = false;
        }
      }
    }

    if (ndmmSCTCharData.SCTTreatment == 1) {
      debugger
      if (
        ndmmSCTCharData.SCTRecievedA === null &&
        ndmmSCTCharData.SCTRecievedADate === ""
      ) {
        errors.SCTRecievedAEmpty = true;
        valid = false;
      }
      if (
        ndmmSCTCharData.SCTRecievedA == 1 &&
        (ndmmSCTCharData.SCTRecievedADate === "" ||
          ndmmSCTCharData.SCTRecievedADate === null)
      ) {
        errors.SCTRecievedADateEmpty = true;
        valid = false;
      }


      debugger
      if (
        ndmmSCTCharData.SCTRecievedA === 1 &&
        (ndmmSCTCharData.SCTRecievedADate !== null ||
          ndmmSCTCharData.SCTRecievedADate !== "") && 
          DateInRange(ndmmSCTCharData.SCTRecievedADate, dayjs("01-01-1920"), dayjs())
      ) {
        const dateValid = validateTreatmentDates(
          cohortTreatmentModelListt[0].cohortIndexDate,
          ndmmSCTCharData.SCTRecievedADate,
          ndmmSCTCharData.InductionEndDate,
          ndmmSCTCharData.consolidationAgentStartDate,
          ndmmSCTCharData.maintainenceStartDate,
          ndmmSCTCharData.endOfFollowupDate
        );

        errors.SCTRecievedADateValid = !dateValid;

        if (!dateValid) {
          valid = false;
        }
      }

      if (ndmmSCTCharData.SCTRecievedADate === "Invalid Date")
      {
        errors.SCTRecievedAInvalidDate = true;
        valid = false
      }
      else if(ndmmSCTCharData.SCTRecievedADate != "" && ndmmSCTCharData.SCTRecievedADate !== "Invalid Date" && !DateInRange(ndmmSCTCharData.SCTRecievedADate, dayjs("01-01-1920"), dayjs())){
        errors.SCTRecievedAInvalidDate = true;
        valid = false
      }  



      //B
      if (
        ndmmSCTCharData.SCTRecievedB === null ||
        ndmmSCTCharData.SCTRecievedB === null ||
        ndmmSCTCharData.SCTRecievedB === 0
      ) {
        errors.SCTRecievedBEmpty = true;
        valid = false;
      }

      //C
      if (
        ndmmSCTCharData.SCTRecievedC === null &&
        (ndmmSCTCharData.SCTRecievedDNum === null ||
          ndmmSCTCharData.SCTRecievedCNum === "")
      ) {
        errors.SCTRecievedCEmpty = true;
        valid = false;
      }

      if (
        (ndmmSCTCharData.SCTRecievedC === 1 ||
          ndmmSCTCharData.SCTRecievedC === true) &&
        (ndmmSCTCharData.SCTRecievedCNum === null ||
          ndmmSCTCharData.SCTRecievedCNum === "")
      ) {
        errors.SCTRecievedCNumEmpty = true;
      }

      if (
        (ndmmSCTCharData.SCTRecievedC === 1 ||
          ndmmSCTCharData.SCTRecievedC === true) &&
        (ndmmSCTCharData.SCTRecievedCNum !== null ||
          ndmmSCTCharData.SCTRecievedCNum !== "")
      ) {
        const validDec = isValidDecimal(ndmmSCTCharData.SCTRecievedCNum);
        errors.SCTRecievedCNumEmpty = !validDec ? true : false;
        if (!validDec) {
          valid = false;
        }
      }

      //D

      if (
        ndmmSCTCharData.SCTRecievedD === null &&
        (ndmmSCTCharData.SCTRecievedDNum === null ||
          ndmmSCTCharData.SCTRecievedDNum === "")
      ) {
        errors.SCTRecievedDEmpty = true;
        valid = false;
      }

      if (
        (ndmmSCTCharData.SCTRecievedD === 1 ||
          ndmmSCTCharData.SCTRecievedD === true) &&
        (ndmmSCTCharData.SCTRecievedDNum === null ||
          ndmmSCTCharData.SCTRecievedDNum === "")
      ) {
        errors.SCTRecievedDEmpty = true;
      }
      if (
        (ndmmSCTCharData.SCTRecievedD === 1 ||
          ndmmSCTCharData.SCTRecievedD === true) &&
        (ndmmSCTCharData.SCTRecievedDNum !== null ||
          ndmmSCTCharData.SCTRecievedDNum !== "")
      ) {
        const validDec = isValidDecimal(ndmmSCTCharData.SCTRecievedDNum);
        errors.SCTRecievedDNumEmpty = !validDec ? true : false;
        if (!validDec) {
          valid = false;
        }
      }

      //E
      if (
        ndmmSCTCharData.SCTRecievedE === null &&
        ndmmSCTCharData.SCTRecievedENum === null
      ) {
        errors.SCTRecievedEEmpty = true;
        valid = false;
      }
      if (
        ndmmSCTCharData.SCTRecievedE == 1 &&
        (ndmmSCTCharData.SCTRecievedENum === null ||
          ndmmSCTCharData.SCTRecievedENum === "")
      ) {
        errors.SCTRecievedENumEmpty = true;
        valid = false;
      }

      if (
        ndmmSCTCharData.SCTRecievedE == 1 &&
        (ndmmSCTCharData.SCTRecievedENum !== null ||
          ndmmSCTCharData.SCTRecievedENum !== "")
      ) {
        const Evalue =
          ndmmSCTCharData.SCTRecievedENum == null ||
          ndmmSCTCharData.SCTRecievedENum == ""
            ? null
            : Number(ndmmSCTCharData.SCTRecievedENum);

        const validValue = isValidNumber(Evalue);
        ndmmSCTCharData.SCTRecievedE = 1;
        errors.SCTRecievedENumEmpty = !validValue;
        if (!validValue) {
          valid = false;
        }
      }

      //F
      if (
        ndmmSCTCharData.SCTRecievedF === null &&
        ndmmSCTCharData.SCTRecievedFTxt === null
      ) {
        errors.SCTRecievedFEmpty = true;
        valid = false;
      }

      if (ndmmSCTCharData.SCTRecievedF === null) {
        errors.SCTRecievedFEmpty = true;
        valid = false;
      } else if (
        ndmmSCTCharData.SCTRecievedF === 6 &&
        !ndmmSCTCharData.SCTRecievedFTxt
      ) {
        errors.SCTRecievedFTxtEmpty = true;
        valid = false;
      }
    }

    setErrorMsg(errors);

    return valid;
  };

  useEffect(() => {
    LoadData();
    GetCohortTreatmentName();
  }, []);

  const GetCohortTreatmentName = () => {
    if (cohortTreatmentModelListt.length > 0) {
      const treatment = cohortTreatmentModelListt[0];

      if (treatment.ndmm === true) {
        if (treatment.cohortTreatmentName === "DKRd (TE or TIE)") {
          if (treatment.cohortTreatmentSCT === 1) {
            setCohortTreatmentName("DKRd (TE)");
          } else if (treatment.cohortTreatmentSCT === 2) {
            setCohortTreatmentName("DKRd (TIE)");
          } else {
            setCohortTreatmentName(treatment.cohortTreatmentName);
          }
        } else {
          // Handle other NDMM treatments
          setCohortTreatmentName(treatment.cohortTreatmentName);
        }
      } else {
        // Handle non-NDMM treatments
        setCohortTreatmentName(treatment.cohortTreatmentName);
      }
    } else {
      setCohortTreatmentName(""); // or some default value
    }
  };

  async function LoadData() {
    try {
      if (pid > 0) {
        ToggleLoader(true);
        const response =
          await AdditionalTreatmentService.LoadNdmmsctcharacteristics(pid);

        ToggleLoader(false);
        if (response?.status === HTTPResponse.OK) {
          const ndmmSCTCharData = response.data;
          if (ndmmSCTCharData) {
            setNdmmSCTCharData((prev) => ({
              ...prev,
              InductionEndDate: ndmmSCTCharData.inductionAgentEndDate,
              ndmmEndFollowupDate: ndmmSCTCharData.ndmmEndOfFollowupDate,
              consolidationAgentStartDate:
                ndmmSCTCharData.consolidationAgentStartDate,
              maintainenceStartDate: ndmmSCTCharData.maintainenceStartDate,
              endOfFollowupDate: ndmmSCTCharData.endOfFollowupDate,

              CohortTreatmentId: cohortTreatmentModelListt[0].cohortTreatmentID,
              NdmmCharId: ndmmSCTCharData.ndmmscttcharacteristicsid,
              ComorbidMedAge: ndmmSCTCharData.transplantineligibilityReasonAge,
              ComorbidMedComorbidity:
                ndmmSCTCharData.transplantineligibilityReasonComorbidity,
              ComorbidMedOrganFunction:
                ndmmSCTCharData.transplantineligibilityReasonOrganfunction,
              ComorbidMedPatientPreference:
                ndmmSCTCharData.transplantineligibilityReasonPatientpreference,
              ComorbidMedOther:
                ndmmSCTCharData.transplantineligibilityReasonOth,
              ComorbidMedOtherTxt:
                ndmmSCTCharData.transplantineligibilityReasonOthTxt,
              ComorbidMedUnk: ndmmSCTCharData.transplantineligibilityReasonUnk,

              SCTRecievedADateValid: false,

              //Question 35

              SCTTreatment:
                ndmmSCTCharData.receivedSct === null
                  ? null
                  : ndmmSCTCharData.receivedSct
                  ? 1
                  : 2,

              //Question 35.1
              NotRecieveSCT: ndmmSCTCharData.notreceivedSctReason,

              //35.2.A
              SCTRecievedADate: ndmmSCTCharData.sctTransplantDate,
              SCTRecievedA: ndmmSCTCharData.sctTransplantDateUnk
                ? 99
                : !isStringEmpty(ndmmSCTCharData.sctTransplantDate)
                ? 1
                : null,

              //35.2.B
              SCTRecievedB: ndmmSCTCharData.sctType,

              //35.2.C
              SCTRecievedCNum: ndmmSCTCharData.noOfStemcellAtTransplant,
              SCTRecievedC: ndmmSCTCharData.noOfStemcellAtTransplantUnk
                ? 99
                : isNumberOrEmpty(ndmmSCTCharData.noOfStemcellAtTransplant)
                ? 1
                : null,

              //35.2.D
              SCTRecievedDNum: ndmmSCTCharData.noOfStemcellTransplanted,
              SCTRecievedD: ndmmSCTCharData.noOfStemcellTransplantedUnk
                ? 99
                : isNumberOrEmpty(ndmmSCTCharData.noOfStemcellTransplanted)
                ? 1
                : null,

              //35.2.E
              SCTRecievedENum: ndmmSCTCharData.noOfMobilizationattempts,
              SCTRecievedE: ndmmSCTCharData.noOfMobilizationattemptsUnk
                ? 99
                : isNumberOrEmpty(ndmmSCTCharData.noOfMobilizationattempts)
                ? 1
                : null,

              //35.2.F
              SCTRecievedF: ndmmSCTCharData.typeOfMobilization,
              SCTRecievedFTxt: ndmmSCTCharData.typeOfMobilizationOthTxt,
            }));

            setErrorMsg((prev) => ({
              ...prev,
              ComorbidMedEmpty: false,
              ComorbidTxtEmpty: false,
              SCTTreatmentEmpty: false,
              NotRecieveSCTEmpty: false,
              SCTRecievedBEmpty: false,
              SCTRecievedCEmpty: false,
              SCTRecievedCNumEmpty: false,
              SCTRecievedDEmpty: false,
              SCTRecievedDNumEmpty: false,
              SCTRecievedEEmpty: false,
              SCTRecievedENumEmpty: false,
              SCTRecievedFEmpty: false,
              SCTRecievedFTxtEmpty: false,
              SCTRecievedAEmpty: false,
              SCTRecievedADateEmpty: false,
              SCTRecievedADateValid: false,
              SCTRecievedAInvalidDate:false,
            }));
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false);

        const payload = {
          patientId: pid,
          userId: GetLoggedInUserID(),
          isValidated: validate,
          Ndmmscttcharacteristicsid: ndmmSCTCharData.NdmmCharId,
          CohortTreatmentId: cohortTreatmentModelListt[0].cohortTreatmentID,

          TransplantineligibilityReasonAge: ndmmSCTCharData.ComorbidMedAge,
          TransplantineligibilityReasonComorbidity:
            ndmmSCTCharData.ComorbidMedComorbidity,
          TransplantineligibilityReasonOrganfunction:
            ndmmSCTCharData.ComorbidMedOrganFunction,
          TransplantineligibilityReasonPatientpreference:
            ndmmSCTCharData.ComorbidMedPatientPreference,
          TransplantineligibilityReasonOth: ndmmSCTCharData.ComorbidMedOther,
          TransplantineligibilityReasonOthTxt:
            ndmmSCTCharData.ComorbidMedOtherTxt,
          TransplantineligibilityReasonUnk: ndmmSCTCharData.ComorbidMedUnk,

          //Question 35
          ReceivedSct: ndmmSCTCharData.SCTTreatment === 1,

          //Question 35.1
          NotreceivedSctReason: ndmmSCTCharData.NotRecieveSCT,

          //35.2.A
         // SctTransplantDate: ndmmSCTCharData.SCTRecievedADate,

         SctTransplantDate: ndmmSCTCharData.SCTRecievedADate != "Invalid Date" && ndmmSCTCharData.SCTRecievedADate
            ? ndmmSCTCharData.SCTRecievedADate :
            ndmmSCTCharData.SCTRecievedADate === "Invalid Date" ? ""
            : "",
          SctTransplantDateUnk: ndmmSCTCharData.SCTRecievedA === 99,
          //35.2.B
          SctType: ndmmSCTCharData.SCTRecievedB,

          //35.2.C
          NoOfStemcellAtTransplant: ndmmSCTCharData.SCTRecievedCNum,
          NoOfStemcellAtTransplantUnk: ndmmSCTCharData.SCTRecievedC === 99,

          //35.2.D
          NoOfStemcellTransplanted: ndmmSCTCharData.SCTRecievedDNum,
          NoOfStemcellTransplantedUnk: ndmmSCTCharData.SCTRecievedD === 99,

          //35.2.E
          NoOfMobilizationattempts: ndmmSCTCharData.SCTRecievedENum,
          NoOfMobilizationattemptsUnk: ndmmSCTCharData.SCTRecievedE === 99,

          //35.2.F
          TypeOfMobilization: ndmmSCTCharData.SCTRecievedF,
          TypeOfMobilizationOthTxt: ndmmSCTCharData.SCTRecievedFTxt,
        };

        if (pid > 0) {
          let response =
            await AdditionalTreatmentService.SaveNdmmCharacteristics(payload);

          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData(pid);
          }

          ToggleLoader(false);
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const Q35NdmmDate =
    ndmmSCTCharData.ndmmEndFollowupDate === ""
      ? ndmmSCTCharData.endOfFollowupDate || "NDMM end of follow up"
      : ndmmSCTCharData.ndmmEndFollowupDate;


      
      const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

//   useEffect(() => {
//  const   sidebar = document.getElementById("mySidebar");
//   const  main = document.getElementById("main");


//     if (isSideMenuActive === true) {
//         sidebar.style.width = "350px";
//         main.style.marginLeft = "350px";
//       } else {
//         sidebar.style.width = "0";
//         main.style.marginLeft = "0";
//       }
//   });
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section" id="non-cohort-page">
        <div>
          <SideBarMenu activePageId={SideMenuItems.NDMMSCTCharacteristics} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
            </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>1L NDMM SCT Characteristics</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  {/* Question 34 */}
                  <div className="question-answer">
                    {cohortTreatmentModelListt.length > 0 &&
                      cohortTreatmentModelListt[0].isEligible === true &&
                      cohortTreatmentModelListt[0].ndmm === true &&
                      (cohortTreatmentName === "DR (TIE)" ||
                        cohortTreatmentName === "DRd (TIE)" ||
                        cohortTreatmentName === "DVR (TIE)" ||
                        cohortTreatmentName === "DVRd (TIE)" ||
                        cohortTreatmentName === "VR (TIE)" ||
                        cohortTreatmentName === "VRd (TIE)" ||
                        cohortTreatmentName === "DKR (TIE)" ||
                        cohortTreatmentName === "DKRd (TIE)" ||
                        cohortTreatmentName === "IsaVR (TIE)" ||
                        cohortTreatmentName === "IsaVRd (TIE)") && (
                        <>
                          <div
                            className="question-bot-sapce"
                            style={{ marginBottom: "25px" }}
                          >
                            <div className="question question-weight">
                              <span>Q34.</span>
                              <span className="quest-text-pad">
                                State the reason(s) for transplant ineligibility
                                while on treatment with{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                >
                                  {cohortTreatmentName}
                                </span>{" "}
                                . Select all that apply.
                              </span>
                            </div>

                            <div
                              className="checked-box-padding"
                              style={{ paddingLeft: "35px", paddingTop: "5px" }}
                            >
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedAge"
                                      checked={ndmmSCTCharData.ComorbidMedAge}
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Age
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedComorbidity"
                                      checked={
                                        ndmmSCTCharData.ComorbidMedComorbidity
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Comorbidity
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedOrganFunction"
                                      checked={
                                        ndmmSCTCharData.ComorbidMedOrganFunction
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Organ Function
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedPatientPreference"
                                      checked={
                                        ndmmSCTCharData.ComorbidMedPatientPreference
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Patient Preference
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedOther"
                                      checked={ndmmSCTCharData.ComorbidMedOther}
                                      onChange={handleFieldChange}
                                    />

                                    <span style={{ paddingLeft: "15px" }}>
                                      Other, please state
                                    </span>
                                  </label>
                                </div>

                                <label>
                                  <textarea
                                    style={{
                                      width: "264px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                    className="input-dash"
                                    type="text"
                                    name="ComorbidMedOtherTxt"
                                    value={ndmmSCTCharData.ComorbidMedOtherTxt}
                                    maxLength={300}
                                    onChange={handleFieldChange}
                                  />
                                </label>
                              </div>
                              <div className="">
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <label>
                                    <input
                                      type="radio"
                                      name="ComorbidMedUnk"
                                      value={99}
                                      checked={ndmmSCTCharData.ComorbidMedUnk}
                                      onChange={handleFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ErrorField
                              show={errorMsg.ComorbidMedEmpty}
                              message={NDMMSCTCharecteristics_Msgs.Q34Empty}
                            />

                            <ErrorField
                              show={errorMsg.ComorbidTxtEmpty}
                              message={NDMMSCTCharecteristics_Msgs.Q34Text}
                            />
                          </div>
                        </>
                      )}
                  </div>
                  {/* QUESTION 35 */}
                  {cohortTreatmentModelListt[0].ndmm == true &&
                    cohortTreatmentModelListt[0].isEligible == true &&
                    (cohortTreatmentName === "DR (TE)" ||
                      cohortTreatmentName === "DRd (TE)" ||
                      cohortTreatmentName === "DVR (TE)" ||
                      cohortTreatmentName === "DVRd (TE)" ||
                      cohortTreatmentName === "VR (TE)" ||
                      cohortTreatmentName === "VRd (TE)" ||
                      cohortTreatmentName === "DKR (TE)" ||
                      cohortTreatmentName === "DKRd (TE)" ||
                      cohortTreatmentName === "IsaVR (TE)" ||
                      cohortTreatmentName === "IsaVRd (TE)") && (
                      <div className="question-answer">
                        <div className="question-bot-space">
                          <div className="question question-weight">
                            <span>Q35.</span>
                            <span className="quest-text-pad">
                              Did the patient receive at least one stem cell
                              transplant (SCT) during treatment with &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {cohortTreatmentName}
                                {/* <sup className="supscript">TM</sup> */}
                              </span>
                              &nbsp; from &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {cohortTreatmentModelListt[0].cohortIndexDate ||
                                  "NDMM index date "}
                              </span>
                              &nbsp; to &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {Q35NdmmDate}
                              </span>
                              &nbsp;?
                            </span>
                          </div>
                          <div className="" style={{ paddingLeft: "35px" }}>
                            <div
                              className="option-without-input"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <label
                                className="long-text-option"
                                htmlFor="SCTTreatmentYes"
                              >
                                <input
                                  type="radio"
                                  id="SCTTreatmentYes"
                                  name="SCTTreatment"
                                  value={1}
                                  checked={ndmmSCTCharData.SCTTreatment == 1}
                                  onChange={handleFieldChange}
                                />
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div
                              className="option-without-input"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <label
                                className="long-text-option"
                                htmlFor="SCTTreatmentNo"
                              >
                                <input
                                  type="radio"
                                  id="SCTTreatmentNo"
                                  name="SCTTreatment"
                                  value={2}
                                  checked={ndmmSCTCharData.SCTTreatment == 2}
                                  onChange={handleFieldChange}
                                />
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={errorMsg.SCTTreatmentEmpty}
                            message={NDMMSCTCharecteristics_Msgs.Q35Empty}
                          />
                        </div>

                        {/* Question 35.1 */}
                        {ndmmSCTCharData.SCTTreatment == 2 && (
                          <div className="question-bot-space">
                            <div className="sub-question">
                              <span className="sub-question-quest">Q35.1.</span>
                              <span
                                className="quest-text-pad"
                                style={{ fontWeight: "500" }}
                              >
                                Reason for not receiving SCT while on treatment
                                with{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {cohortTreatmentName || "NDMM"}
                                </span>
                              </span>
                              <div className="">
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NotRecieveSCTDeclined"
                                    name="NotRecieveSCT"
                                    value={1}
                                    checked={ndmmSCTCharData.NotRecieveSCT == 1}
                                    onChange={handleFieldChange}
                                  />
                                  <label htmlFor="NotRecieveSCTDeclined">
                                    <span className="radio-text-padding">
                                      Declined
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NotRecieveSCTDeffered"
                                    name="NotRecieveSCT"
                                    value={2}
                                    checked={ndmmSCTCharData.NotRecieveSCT == 2}
                                    onChange={handleFieldChange}
                                  />
                                  <label htmlFor="NotRecieveSCTDeffered">
                                    <span className="radio-text-padding">
                                      Deferred
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NotRecieveSCTOther"
                                    name="NotRecieveSCT"
                                    value={99}
                                    checked={
                                      ndmmSCTCharData.NotRecieveSCT == 99
                                    }
                                    onChange={handleFieldChange}
                                  />
                                  <label htmlFor="NotRecieveSCTOther">
                                    <span className="radio-text-padding">
                                      Other/Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={errorMsg.NotRecieveSCTEmpty}
                                message={NDMMSCTCharecteristics_Msgs.Q35Empty}
                              />
                            </div>
                          </div>
                        )}

                        {ndmmSCTCharData.SCTTreatment == 1 && (
                          <>
                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q35.2.</span>
                                <span className="quest-text-pad">
                                  Provide information for&nbsp;
                                  <u> the SCT received</u>&nbsp; during
                                  treatment with &nbsp;
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {
                                      cohortTreatmentModelListt[0]
                                        .cohortTreatmentName
                                    }
                                    {/* <sup className="supscript">TM</sup> */}
                                  </span>
                                  &nbsp; from &nbsp;
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {cohortTreatmentModelListt[0]
                                      .cohortIndexDate || "NDMM index date"}
                                  </span>
                                  &nbsp; to &nbsp;
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {Q35NdmmDate}
                                  </span>
                                  . The date of the SCT must be&nbsp;
                                  <u>no earlier than</u>&nbsp; the last
                                  administration of the last agent for the
                                  induction therapy and&nbsp;
                                  <u>no later than </u>&nbsp;the first
                                  administration of the first agent for the
                                  consolidation/maintenance therapy; if multiple
                                  SCTs were received during this timeframe,
                                  please use the earliest SCT recorded.
                                </span>
                              </div>
                              <div className="sub-question">
                                <table
                                  style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Date of SCT transplant <br />
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                          }}
                                        ></span>
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Type of SCT <br />
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                          }}
                                        ></span>
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Stem cell yield at transplant <br />
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                          }}
                                        ></span>
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Stem cells transplanted <br />
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                          }}
                                        ></span>
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Mobilization attempts at transplant{" "}
                                        <br />
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                          }}
                                        ></span>
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Types of mobilization regimens <br />
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                          }}
                                        ></span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <span 
                                        // className="sub-question-quest"
                                        >
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedADate"
                                              name="SCTRecievedA"
                                              value={1}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedA ==
                                                1
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              {/* <DateControl
                                                ctrlId="SCTRecievedADate"
                                                HandleDateChange={
                                                  HandleDateChange
                                                }
                                                date={
                                                  ndmmSCTCharData.SCTRecievedADate
                                                }
                                              /> */}

                                  <DatePickerComponent
                                     ctrlId="SCTRecievedADate"
                                    // selectedDate={selectedDate}
                                    // onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    //optionId={1}
                                    minDate={dayjs("01-01-1920")} 
                                    maxDate={dayjs()} 
                                    locale="en" // for dd/mm/yyyy use "en-gb"
                                    date={
                                      ndmmSCTCharData.SCTRecievedADate
                                    }
                                  />
                                            </span>
                                          </div>
                                        </span>
                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            id="SCTRecievedAUnk"
                                            name="SCTRecievedA"
                                            value={99}
                                            checked={
                                              ndmmSCTCharData.SCTRecievedA == 99
                                            }
                                            onChange={handleFieldChange}
                                          />
                                          <label htmlFor="SCTRecievedAUnk">
                                            <span className="radio-text-padding">
                                              Date of transplant unknown
                                            </span>
                                          </label>
                                        </div>

                                        <ErrorField
                                          show={errorMsg.SCTRecievedAEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35Empty
                                          }
                                        />
                                         <ErrorField
                                          show={errorMsg.SCTRecievedAInvalidDate}
                                          message={
                                           CommonError_Msgs.InvalidDate
                                          }
                                        />
                                        <ErrorField
                                          show={errorMsg.SCTRecievedADateEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35a
                                          }
                                        />
                                        <ErrorField
                                          show={errorMsg.SCTRecievedADateValid}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35aValid
                                          }
                                        />
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedBAutologous"
                                              name="SCTRecievedB"
                                              value={1}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedB ==
                                                1
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedBAutologous">
                                              <span className="radio-text-padding">
                                                Autologous
                                              </span>
                                            </label>
                                          </div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedBAllogeneic"
                                              name="SCTRecievedB"
                                              value={2}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedB ==
                                                2
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedBAllogeneic">
                                              <span className="radio-text-padding">
                                                Allogeneic
                                              </span>
                                            </label>
                                          </div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedBUnknown"
                                              name="SCTRecievedB"
                                              value={99}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedB ==
                                                99
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedBUnknown">
                                              <span className="radio-text-padding">
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <ErrorField
                                          style={{ padding: "0px" }}
                                          show={errorMsg.SCTRecievedBEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35Empty
                                          }
                                        />
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label
                                            htmlFor="SCTRecievedCNum"
                                            style={{ marginTop: "6px" }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedCNum"
                                              name="SCTRecievedC"
                                              value={1}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedC ==
                                                1
                                              }
                                              onChange={handleFieldChange}
                                            />
                                          </label>
                                          <label
                                            htmlFor="SCTRecievedCNum"
                                            style={{ marginTop: "6px" }}
                                          >
                                            <span className="radio-text-padding">
                                              <input
                                                className="input-dash"
                                                name="SCTRecievedCNum"
                                                type="text"
                                                value={
                                                  ndmmSCTCharData.SCTRecievedCNum
                                                }
                                                onKeyDown={(e) => {
                                                  // Allow backspace, arrow keys, and delete
                                                  if (
                                                    [
                                                      "Backspace",
                                                      "ArrowLeft",
                                                      "ArrowRight",
                                                      "Delete",
                                                      "Tab",
                                                    ].includes(e.key)
                                                  ) {
                                                    return;
                                                  }

                                                  // Prevent non-numeric characters, except '.'
                                                  if (!/[0-9.]/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  const value = e.target.value;
                                                  const selectionStart =
                                                    e.target.selectionStart;
                                                  const newValue =
                                                    value.slice(
                                                      0,
                                                      selectionStart
                                                    ) +
                                                    e.key +
                                                    value.slice(
                                                      e.target.selectionEnd
                                                    );

                                                  // Prevent multiple '.'
                                                  if (
                                                    e.key === "." &&
                                                    value.includes(".")
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  // Prevent '.' as the first character
                                                  if (
                                                    e.key === "." &&
                                                    value === ""
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  // Allow only one digit after decimal point
                                                  if (
                                                    newValue.includes(".") &&
                                                    newValue.split(".")[1]
                                                      .length > 1
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                }}
                                                onChange={handleFieldChange}
                                              />
                                              <br />{" "}X 10<sup>6</sup> &nbsp;stem
                                              cells/kg
                                            </span>
                                          </label>
                                        </div>

                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedCUnknown"
                                              name="SCTRecievedC"
                                              value={99}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedC ==
                                                99
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedCUnknown">
                                              <span className="radio-text-padding">
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <ErrorField
                                          show={errorMsg.SCTRecievedCEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35Empty
                                          }
                                        />
                                        <ErrorField
                                          show={errorMsg.SCTRecievedCNumEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35c
                                          }
                                        />
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label
                                            htmlFor="SCTRecievedDNum"
                                            style={{ marginTop: "6px" }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedD"
                                              name="SCTRecievedD"
                                              value={1}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedD ==
                                                1
                                              }
                                              onChange={handleFieldChange}
                                            />
                                          </label>
                                          <label
                                            htmlFor="SCTRecievedDNum"
                                            style={{ marginTop: "6px" }}
                                          >
                                            <span className="radio-text-padding">
                                              <input
                                                className="input-dash"
                                                id="SCTRecievedDNum"
                                                name="SCTRecievedDNum"
                                                type="text"
                                                value={
                                                  ndmmSCTCharData.SCTRecievedDNum
                                                }
                                                onKeyDown={(e) => {
                                                  // Allow backspace, arrow keys, and delete
                                                  if (
                                                    [
                                                      "Backspace",
                                                      "ArrowLeft",
                                                      "ArrowRight",
                                                      "Delete",
                                                      "Tab",
                                                    ].includes(e.key)
                                                  ) {
                                                    return;
                                                  }

                                                  // Prevent non-numeric characters, except '.'
                                                  if (!/[0-9.]/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  const value = e.target.value;
                                                  const selectionStart =
                                                    e.target.selectionStart;
                                                  const newValue =
                                                    value.slice(
                                                      0,
                                                      selectionStart
                                                    ) +
                                                    e.key +
                                                    value.slice(
                                                      e.target.selectionEnd
                                                    );

                                                  // Prevent multiple '.'
                                                  if (
                                                    e.key === "." &&
                                                    value.includes(".")
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  // Prevent '.' as the first character
                                                  if (
                                                    e.key === "." &&
                                                    value === ""
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  // Allow only one digit after decimal point
                                                  if (
                                                    newValue.includes(".") &&
                                                    newValue.split(".")[1]
                                                      .length > 1
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                }}
                                                onChange={handleFieldChange}
                                              />
                                              <br/>{" "}X 10<sup>6</sup> &nbsp;stem
                                              cells/kg
                                            </span>
                                          </label>
                                        </div>

                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedDUnknown"
                                              name="SCTRecievedD"
                                              value={99}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedD ==
                                                99
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedDUnknown">
                                              <span className="radio-text-padding">
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <ErrorField
                                          show={errorMsg.SCTRecievedDEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35Empty
                                          }
                                        />
                                        <ErrorField
                                          show={errorMsg.SCTRecievedDNumEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35d
                                          }
                                        />
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label
                                            htmlFor="SCTRecievedENum "
                                            style={{ marginTop: "6px" }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedE"
                                              name="SCTRecievedE"
                                              value={1}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedE ==
                                                1
                                              }
                                              onChange={handleFieldChange}
                                            />
                                          </label>
                                          <label
                                            htmlFor="SCTRecievedENum"
                                            style={{ marginTop: "6px" }}
                                          >
                                            <span className="radio-text-padding">
                                              <input
                                                className="input-dash"
                                                id="SCTRecievedENum"
                                                name="SCTRecievedENum"
                                                type="text"
                                                value={
                                                  ndmmSCTCharData.SCTRecievedENum
                                                }
                                                onKeyDown={(e) => {
                                                  // Allow backspace, arrow keys, delete, and tab
                                                  if (
                                                    [
                                                      "Backspace",
                                                      "ArrowLeft",
                                                      "ArrowRight",
                                                      "Delete",
                                                      "Tab",
                                                    ].includes(e.key)
                                                  ) {
                                                    return;
                                                  }

                                                  // Prevent non-numeric characters
                                                  if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }

                                                  const value = e.target.value;
                                                  const selectionStart =
                                                    e.target.selectionStart;
                                                  const newValue =
                                                    value.slice(
                                                      0,
                                                      selectionStart
                                                    ) +
                                                    e.key +
                                                    value.slice(
                                                      e.target.selectionEnd
                                                    );

                                                  // Prevent leading zeros only if more than one digit is present
                                                  if (
                                                    newValue.length > 1 &&
                                                    newValue[0] === "0"
                                                  ) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                }}
                                                onChange={handleFieldChange}
                                              />
                                              attempts
                                            </span>
                                          </label>
                                        </div>

                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedEUnknown"
                                              name="SCTRecievedE"
                                              value={99}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedE ==
                                                99
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedEUnknown">
                                              <span className="radio-text-padding">
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <ErrorField
                                          show={errorMsg.SCTRecievedEEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35Empty
                                          }
                                        />
                                        <ErrorField
                                          show={errorMsg.SCTRecievedENumEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35e
                                          }
                                        />
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedF1"
                                              name="SCTRecievedF"
                                              value={1}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                1
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedF1">
                                              <span className="radio-text-padding">
                                                G-CSF only
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedF2"
                                              name="SCTRecievedF"
                                              value={2}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                2
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedF2">
                                              <span className="radio-text-padding">
                                                G-CSF + upfront plerixafor
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedF3"
                                              name="SCTRecievedF"
                                              value={3}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                3
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedF3">
                                              <span className="radio-text-padding">
                                                G-CSF + plerixafor used when
                                                deemed necessary
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedF4"
                                              name="SCTRecievedF"
                                              value={4}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                4
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedF4">
                                              <span className="radio-text-padding">
                                                Blood CD34+ count after G-CSF
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedF5"
                                              name="SCTRecievedF"
                                              value={5}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                5
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedF5">
                                              <span className="radio-text-padding">
                                                Chemomobilization
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label htmlFor="SCTRecievedFTxt">
                                            <input
                                              type="radio"
                                              id="SCTRecievedF6"
                                              name="SCTRecievedF"
                                              value={6}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                6
                                              }
                                              onChange={handleFieldChange}
                                            />
                                          </label>
                                          <label 
                                            htmlFor="SCTRecievedFTxt"
                                            className="radio-text-padding"
                                          >
                                            Other, please state
                                            <span 
                                            // className="radio-text-padding"
                                            >
                                              <textarea
                                                style={{
                                                  width: "264px",
                                                  display: "flex",
                                                  marginTop: "5px",
                                                }}
                                                className="input-dash"
                                                id="SCTRecievedFTxt"
                                                name="SCTRecievedFTxt"
                                                type="text"
                                                value={
                                                  ndmmSCTCharData.SCTRecievedFTxt
                                                }
                                                maxLength={300}
                                                onChange={handleFieldChange}
                                              />
                                            </span>
                                          </label>
                                        </div>
                                        <div className="">
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id="SCTRecievedF99"
                                              name="SCTRecievedF"
                                              value={99}
                                              checked={
                                                ndmmSCTCharData.SCTRecievedF ==
                                                99
                                              }
                                              onChange={handleFieldChange}
                                            />
                                            <label htmlFor="SCTRecievedF99">
                                              <span className="radio-text-padding">
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <ErrorField
                                          show={errorMsg.SCTRecievedFEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35Empty
                                          }
                                        />
                                        <ErrorField
                                          show={errorMsg.SCTRecievedFTxtEmpty}
                                          message={
                                            NDMMSCTCharecteristics_Msgs.Q35f
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NDMMSCTCharacteristics;
