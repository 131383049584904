import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { isStringEmpty } from "../Helpers/Utilities";
import { now } from "moment";
import dayjs from "dayjs";
const DatePickerComponent = ({
// selectedDate,
 
  minDate,
  maxDate,
  locale,
  hideDay,
  hideMonth,
  marginLeft = true,
  ctrlId,
  HandleDateChange,
  optionId,

  date,
}) => {
//   const [selectedDate, setSelectedDate] = React.useState
//   (isStringEmpty(date)
//     ? "MM/DD/YYYY"
//     :date.format("MM-DD-YYYY"))
// console.log(date)

const [selectedDate, setSelectedDate] = React.useState(date ? dayjs(date) : null);
// Update selectedDate when date prop changes
React.useEffect(() => {
  setSelectedDate(date ? dayjs(date) : null);
}, [date]);


const onDateChange = (newDate) => {
  setSelectedDate(newDate);
  if (newDate) {
    HandleDateChange(ctrlId, newDate.format("MM/DD/YYYY"), optionId);
  } else {
    HandleDateChange(ctrlId, "", optionId);
  }
};

console.log(date)
console.log("selected",selectedDate)
// const onDateChange = (e) => { 
//   debugger
//     setSelectedDate(e.format("MM/DD/YYYY"));
//     HandleDateChange(ctrlId, e.format("MM/DD/YYYY"), optionId);
//   } 

  /* <summary>
  date: 13-10-2023
  Name: AP
  description: Set date to defaut value
  <summary>*/
  // React.useEffect(() => {
  //   setSelectedDate(isStringEmpty(date) ? defaultDate : date);
  // }, [date]);

  /* <summary>
  date: 31-10-2023
  Name: AP
  description: Set the min and max dates for the calendar
  <summary>*/
  // const SetDateRange = (current) => {
  //   return current.isSameOrAfter("01/01/1920") && current.isSameOrBefore(now());
  // };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
      className="custom-datepicker-inputbox"
        minDate={minDate}
        maxDate={maxDate}
        showDaysOutsideCurrentMonth
        // label="Select date"
        value={selectedDate}
        onChange={onDateChange}
        slotProps={{
          textField: { size: "small" },
          popper: {
            sx: datepickercss,
          },
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

const datepickercss = {
  ".MuiPaper-root": {
    border: "1px solid black",
    padding: 1,
    marginTop: 1,
    backgroundColor: "#D4E2F1",
  },
  ".MuiPickersCalendarHeader-root": {
    backgroundColor: "#006bb5",
    color: "#ffffff",
  },
  ".css-1eeiwjo-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon": {
    color: "#ffffff",
  },
  ".css-1lzspe0-MuiSvgIcon-root": {
    color: "#ffffff",
  },
  ".css-i5q14k-MuiDayCalendar-header": {
    backgroundColor: "#006bb5",
  },
  ".css-ufcxyz-MuiTypography-root-MuiDayCalendar-weekDayLabel": {
    color: "#e0f7fa",
  },
};

export default DatePickerComponent;
