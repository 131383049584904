import CryptoJS, { format } from "crypto-js";
import { ArchiveService } from "../WebApiServices/Archive.service";
import ArchiveModel from "../DataModels/ArchiveModel";
import { DatePart } from "./Enums";
import dayjs from "dayjs";
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);

/* <summary>
date: 06-04-2024
Name: AP
description: Get logged in user's ID
<summary>*/
export const GetLoggedInUserID = () => {  
  const userId = GetLocalStorageData("userId");
  return Number(userId);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Add data to local storage in encrypted form
<summary>*/
export const AddLocalStorageData = (storageKey, value) => {
  const encryptedValue = EncryptUsingAES256(JSON.stringify(value));
  const encryptedKey = EncryptUsingAES256(storageKey);
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  }
  localStorage.setItem(encryptedKey, encryptedValue);
};

/* <summary>
date: 06-04-2024
Name: AP
description: get decrypted data from local storage
<summary>*/
export const GetLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return JSON.parse(DecryptUsingAES256(encryptedValue));
    }
  } catch (error) { }
  return encryptedValue;
};

/* <summary>
date: 06-04-2024
Name: AP
description: get decrypted data from local storage
<summary>*/
export const RemoveLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  localStorage.removeItem(encryptedKey);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Check if string is empty/undefined/null
<summary>*/
export const isStringEmpty = (text) => {
  try {
    return !text || text.trim().length === 0;
  } catch (error) { }
};

export const isNumberOrEmpty = (value) => {
  return value === '' || (!isNaN(value) && value !== null && value !== undefined);
};


export const isStringEmptyDh = (value) => {
  if (value === 0 || value === '0') return false;
  return value === null || value === undefined || (value.toString().trim() === "");
};

/* <summary>
date: 06-04-2024
Name: AP
description: Encryption using CryptoJS
<summary>*/
export const EncryptUsingAES256 = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/* <summary>
date: 06-04-2024
Name: AP
description: Decryption using CryptoJS
<summary>*/
export const DecryptUsingAES256 = (decString) => {
  const decrypted = CryptoJS.AES.decrypt(decString, key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
/* <summary>
date: 06-04-2024
Name: AP
description: Email Validation
<summary>*/
export const IsEmailValid = (emailAddress) => {
  const emailRegexString =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  ///^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/; from other ag projects
  return emailRegexString.test(emailAddress);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Validates password
<summary>*/
export const IsPasswordValid = (passowrd) => {
  // const passwordRegexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  const passwordRegexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  return passwordRegexString.test(passowrd);
};
/* <summary>
date: 06-04-2024
Name: AP
description: Common fn calling archive service to add archive
<summary>*/
export const AddArchiveFunction = (actionNumber, data, userId, secondaryId) => {
  const payLoad = new ArchiveModel();
  payLoad.archiveaction = actionNumber;
  payLoad.archivedata = data;
  payLoad.userid = userId;
  payLoad.secondaryid = secondaryId;
  //payLoad.CreatedBy = userId;

  ArchiveService.AddArchive(payLoad);
};

//Allow only positive integers
//Value being passed shouldn't be blank
export const AllowOnlyIntegers = (value) => {
  const NUMBER_REGEX = /^[0-9\b]+$/;
  return NUMBER_REGEX.test(value);
};
//Allow only positive Decimals max 2 places
export const AllowPositiveDecimalNumbers = (value) => {
  const NUMBER_REGEX = /^[0-9]*(\.[0-9]{0,2})?$/;
  return NUMBER_REGEX.test(value);
};

//Allow only positive Decimals max 2 places
export const AllowPositiveDecimalsUpToOnePlace = (value) => {
  const NUMBER_REGEX = /^\d+(\.\d{0,1})?$/;
  return NUMBER_REGEX.test(value);
};

//Allow only positive Decimals max 1 places
export const AllowNumberWithOneDecimal = (value) => {
  const NUMBER_REGEX =
    /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;
  //const NUMBER_REGEX = /^(?!0(\.0)?$)([1-9]\d?(\.\d)?|[1-9]\d{0,1}|[1-9]\d{0,1}\.|[1-9]\d?\.|\d|100(\.0?)?|[1-9]\d(\.\d)?|2[0-9]{1,2}(\.\d)?|300(\.0)?)$/;

  return NUMBER_REGEX.test(value);
};

//Allow only one Decimals
export const AllowOnlyOneDecimal = (value) => {
  let parsedValue = parseFloat(value);
  let decimalPart = value.split(".")[1];
  // Check if there are exactly two digits after the decimal
  if (decimalPart.length === 2) {
    parsedValue = value.split(".")[0] + "." + decimalPart.slice(0, 1); // Round to one decimal place
    value = parsedValue.toString();
  }
  return value;
};

//Allow only two Decimals
export const AllowOnlyTwoDecimal = (value) => {
  let parsedValue = parseFloat(value);
  let decimalPart = value.split(".")[1];
  // Check if there are exactly two digits after the decimal
  if (decimalPart.length === 3) {
    parsedValue = value.split(".")[0] + "." + decimalPart.slice(0, 2); // Round to one decimal place
    value = parsedValue.toString();
  }
  return value;
};

export const GetRandomAlphaNumber = () => {
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const randomIndex = Math.floor(Math.random() * alphabets.length);
  const letter = alphabets[randomIndex];
  return letter;
};

//Check if value is within allowed bounds. Both the bounds are inclusive
export const ValueInRange = (value, lowLim, highLim) => {
  return value >= lowLim && value <= highLim;
};

/* <summary>
  date: 18-07-2024
  Name: VK
  description: Date Validation
  <summary>*/

export function convertDateFormat(dateString) {
  // Split the date string by "-"
  const [month, day, year] = dateString.split("-");

  // Return the date string in "MM/DD/YYYY" format
  return `${month}/${day}/${year}`;
}

export function validateRadiotherapyDate(date, cohortIndexDate) {
  // Helper function to parse date string in "MM/DD/YYYY" format
  function parseDate(dateString) {
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  }

  //const requiredCohortIndexDate = convertDateFormat(cohortIndexDate);

  // Convert the input dates to Date objects
  const radiotherapyDate = parseDate(date);
  const indexDate = parseDate(cohortIndexDate);

  // Validate the parsed dates
  if (isNaN(radiotherapyDate) || isNaN(indexDate)) {
    return false; // Invalid date format
  }

  // Calculate the date 91 days before the NDMM index date
  const ninetyOneDaysBeforeIndexDate = new Date(indexDate);
  ninetyOneDaysBeforeIndexDate.setDate(indexDate.getDate() - 91);

  // Check if the last radiotherapy date is within the range
  if (
    radiotherapyDate >= ninetyOneDaysBeforeIndexDate &&
    radiotherapyDate <= indexDate
  ) {
    return true; // Valid
  } else {
    return false; // Invalid
  }
}

// Utility function to calculate the difference in days between two dates
//Check ((NDMM index date – date of birth) < 6575 days) for S6. in NDMM Criteria Page
export const calculateDaysDifference = (birthDate, IndexDate) => {
  const birth = new Date(birthDate);
  const ndmmIndex = new Date(IndexDate);

  // Calculate the time difference in milliseconds
  const differenceInTime = ndmmIndex.getTime() - birth.getTime();

  // Convert time difference from milliseconds to days
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  return differenceInDays;
};

//Check for validation of conditions in S6. NDMM
export const checkEligibileCohortTreatment = (indexDate, cutoffDate) => {
  // const dateFormat = 'MM/DD/YYYY';
  // const ndmmIndexDate = moment(indexDate).format(dateFormat);
  // const cutoff = moment(cutoffDate).format(dateFormat);

  const ndmmIndexDate = new Date(indexDate);
   const cutoff = new Date(cutoffDate);

  // Set both dates to 00:00:00 to ignore the time part
 // ndmmIndexDate.setHours(0, 0, 0, 0);
  //cutoff.setHours(0, 0, 0, 0);

  const isEligible = ndmmIndexDate < cutoff;

  return isEligible;
};

// Function to subtract 183 days from a given date and format as MMDDYYYY
export const formatDateMMDDYYYY = (dateString) => {
  // Create a Date object from the dateString
  let initialDate = new Date(dateString);

  // Subtract 183 days
  initialDate.setDate(initialDate.getDate() - 183);

  // Format the date as MMDDYYYY
  let formattedDate = `${(initialDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${initialDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${initialDate.getFullYear()}`;

  return formattedDate;
};

//Check ((Bispecifics/CAR-T index date – date of birth) >= 6575 days) for S11. in RRMM Criteria Page

export const calculateDaysDifferenceRRMM = (birthDate, rrmmIndexDate) => {
  const birth = new Date(birthDate);

  const rrmmIndex = new Date(rrmmIndexDate);

  // Calculate the time difference in milliseconds

  const differenceInTime = rrmmIndex.getTime() - birth.getTime();

  // Convert time difference from milliseconds to days

  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  return differenceInDays;
};

//Check if Bispecifics/CAR-T index date” > “NDMM index date” if “NDMM index date” is not missing in S11. RRMM Criteria

export const checkCARTDate = (rrmmIndexDate, ndmmIndexDate) => {
  const rrmmIndex = new Date(rrmmIndexDate);

  const ndmmIndex = new Date(ndmmIndexDate);

  // Set both dates to 00:00:00 to ignore the time part

  rrmmIndex.setHours(0, 0, 0, 0);

  ndmmIndex.setHours(0, 0, 0, 0);

  const isEligible = rrmmIndex <= ndmmIndex;

  if (ndmmIndexDate === null) {
    isEligible = false;
  }

  return isEligible;
};

export function validateMeasurementDate(date, cohortIndexDate) {
  // Helper function to parse date string in "MM/DD/YYYY" format
  function parseDate(dateString) {
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  }

  const requiredCohortIndexDate = convertDateFormat(cohortIndexDate);

  // Convert the input dates to Date objects
  const radiotherapyDate = parseDate(date);
  const indexDate = parseDate(requiredCohortIndexDate);

  // Validate the parsed dates
  if (isNaN(radiotherapyDate) || isNaN(indexDate)) {
    return false; // Invalid date format
  }

  // Calculate the date 365 days before the NDMM/RDMM index date
  const daysBeforeIndexDate = new Date(indexDate);
  daysBeforeIndexDate.setDate(indexDate.getDate() - 365);

  // Check if the last radiotherapy date is within the range
  if (
    radiotherapyDate >= daysBeforeIndexDate &&
    radiotherapyDate <= indexDate
  ) {
    return true; // Valid
  } else {
    return false; // Invalid
  }
}

/* <summary>
  date: 21-07-2024
  Name: NN
  description: Function to subtract 365 days from a given date and format as MMDDYYYY
  <summary>*/

export const formatDate = (dateString) => {
  // Create a Date object from the dateString
  let initialDate = new Date(dateString);

  // Subtract 183 days
  initialDate.setDate(initialDate.getDate() - 365);

  // Format the date as MMDDYYYY
  let formattedDate = `${(initialDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${initialDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${initialDate.getFullYear()}`;

  return formattedDate;
};

/* <summary>
date: 23-07-2024
Name: AP
description: Gets the ordinal value of a number
<summary>*/
export const GetOrdinal = (n) => {
  let suffix;
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    suffix = "th";
  } else {
    switch (lastDigit) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }
  }
  return (
    <span>
      {n}
      <sup>{suffix}</sup>
    </span>
  );
};

/* <summary>
date: 30-07-2024
Name: VK
description: 
<summary>*/

export const CompareEndofFollowUpDate = (
  date,
  EndofFollowUpDate,
  lastPatientEncounter
) => {


  // Helper function to parse date strings into Date objects
  const parseDate = (dateStr) => {
    if (!dateStr) return null;
    const [month, day, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  };

  // Parse the given date
  const givenDate = parseDate(date);
  const endDate = parseDate(EndofFollowUpDate);
  const lastEncounterDate = parseDate(lastPatientEncounter);

  // Check if the given date is valid
  if (!givenDate) {
    return false;
  }

  // If both EndofFollowUpDate and lastPatientEncounter are empty, return true
  if (!EndofFollowUpDate && !lastPatientEncounter) {
    return true;
  }

  // If EndofFollowUpDate is empty but lastPatientEncounter is not, return true
  if (!EndofFollowUpDate) {
    return true;
  }

  // Check if the end date is valid
  if (!endDate) {
    return false;
  }

  // Determine the comparison date
  const comparisonDate =
    lastEncounterDate && lastEncounterDate < endDate
      ? lastEncounterDate
      : endDate;

  // Compare givenDate with the comparison date
  return givenDate <= comparisonDate;
};

export function CheckDateValidation(
  DateParameter1,
  DateParameter2,
  DateParameter3
) {
  const Date1 = DateParameter1 ? parseDate(DateParameter1) : null;

  const Date2 = DateParameter2 ? parseDate(DateParameter2) : null;

  const Date3 = DateParameter3 ? parseDate(DateParameter3) : null;

  // Ensure all dates are valid before comparison
  if (Date1 && Date2 && Date3) {
    return Date1 <= Date2 && Date2 <= Date3;
  }

  // If any of the dates are invalid, return false
  return false;
}

const parseDate = (dateStr) => {
  if (!dateStr) return null;
  const [month, day, year] = dateStr.split("/");
  return new Date(year, month - 1, day);
};

/* <summary>
date: 29-07-2024
Name: AP
description: Checks if date1 is beyond date2
//Both dates should be valid
<summary>*/
export const IsDateAfter = (dateStr1, dateStr2) => {

  if (isStringEmpty(dateStr1) || isStringEmpty(dateStr2)) {
    return false;
  }

  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Check if dates are valid
  const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

  if (isValidDate(date1) && isValidDate(date2)) {
    return date1 > date2;
  }
  return false;
}

export function checkValue(value) {
  if (typeof value === 'number' && value >= 0 && value <= 20) {
    return value;
  }
  return '';
}


export function getMaxFirstDate(agentData) {
  const validDates = agentData
    .map(item => item.firstDate)
    .filter(date => date !== null)
    .map(date => {
      const newDate = new Date(date);
      return isNaN(newDate) ? null : newDate; // Check if the date is valid
    })
    .filter(date => date !== null); // Filter out any null values after conversion

  if (validDates.length === 0) {
    return null; // No valid dates found
  }

  const maxDate = validDates.reduce((latest, current) => current > latest ? current : latest);
  return maxDate;
}

export function getMaxLastDate(agentData) {
  const validDates = agentData
    .map(item => item.lastDate)
    .filter(date => date !== null)
    .map(date => {
      const newDate = new Date(date);
      return isNaN(newDate) ? null : newDate; // Check if the date is valid
    })
    .filter(date => date !== null); // Filter out any null values after conversion

  if (validDates.length === 0) {
    return null; // No valid dates found
  }

  const maxDate = validDates.reduce((latest, current) => current > latest ? current : latest);
  return maxDate;
}


/* <summary>
date: 12-08-2024
Name: AP
description: Get the earliest date
<summary>*/
export function GetEarliestDate(dateStrings) {

  // Convert date strings to Date objects and filter out invalid dates or empty dates
  const dateObjects = GetDateObjects(dateStrings);

  // Return the earliest date, if any valid dates exist 
  if (dateObjects.length === 0) {
    return null;
  }
  return new Date(Math.min(...dateObjects.map(date => date.getTime())));
}
function GetDateObjects(dateStrings) {
  return dateStrings
    .map(dateStr => dateStr ? new Date(dateStr) : null)
    .filter(date => date instanceof Date && !isNaN(date.getTime()));
}
/* <summary>
date: 13-08-2024
Name: AP
description: Format date to display
<summary>*/
export const DateDisplayFormat = (date) => {

  const dateObj = new Date(date);

  // Check if the date is valid
  if (!isNaN(dateObj.getTime())) {
    // Format the date components
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${month}/${day}/${year}`;
  } else {
    // Return null for invalid dates
    return null;
  }
}


export function isValidNumberRange(value) {
  // Convert value to a number
  const number = parseFloat(value);

  // Regular expression to match numbers with up to two decimal places
  // const decimalRegex = /^\d{1,2}(\.\d{1,2})?$|^50(\.0{1,2})?$/;
  const decimalRegex = /^\d{1,4}(\.\d{1,2})?$/;

  // Validate that the number is within the range and matches the decimal format
  return (
    !isNaN(number) && number > 0  && number <= 4000 && decimalRegex.test(value)
  );
}

export function DateInRange (date, minDate, maxDate)  { 
  return dayjs(date).isAfter(minDate) && dayjs(date).isBefore(maxDate);
};

export function IsDateValid (date, minDate = dayjs("01-01-1920"), maxDate = dayjs())  { 
  if( date === "Invalid Date") {
    return false;
  } 
  return dayjs(date).isAfter(minDate) && dayjs(date).isBefore(maxDate);
};