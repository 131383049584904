import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import PatientDetails from "../../Components/PatientInformation";
import { ComborbidityErrorMsg, CommonError_Msgs } from "../../Helpers/HelperText";
import { useContext, useEffect, useState } from "react";
import ErrorField from "../../Components/ErrorField";
import { GetLocalStorageData, GetLoggedInUserID, formatDate } from "../../Helpers/Utilities";
import { CombordityService } from "../../WebApiServices/Comorbidity.service";
import { AppContext } from "../../Contexts/AppContextProvider";

const Comorbidities = () => {
  const navigatePaths = {
    prevPage: "/DiseaseHistory",
    currPage: "/Comorbidities",
    nextPage: "/CohortDefiningTreatment",
  };



  const { ShowToast, ToggleLoader, HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive, } =
  useContext(AppContext);

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);



  const ComorbiditiesRow = {
    comorbiditiesid: 0, // pk of comorbidities table
    cohorttreatmentid:0, 

    cohortTreatmentName: "",
    cohortIndexDate: "",
    cohortTreatmentNameSuperscript:"",
    //Question 18

    q18Dementia:false, 
    chronicpulmonarydisease:false,
    chronicpulmonarydiseaseRd:null,

    rheumatologicaldisease:false,
    pepticulcerdisease:false,
    mildliverdisease:false,
	  mildorSevereliverdisease:false,
	  diabetesWoChronicComplication:false,
	  diabetesChronicComplication:false,
	  hemiplegiaOrParaplegia:false,
	  renaldisease:false,
	  chronickidneydisease:false,
	  stage:null,

    dialysisHemodialysis:false,

    kidneyTransplant:false,
    malignancy:false,

    metastaticSolidTumor:false,
    bloodCancerMM:false,
    otherMalignancy:false,
    malignancyUnk:null,

    aidsHIV:false,
    neuropathy:false,
    neuropathyRd:null,


    cardiovascularDisease:false,
    hypertension:false,
    myocardialInfarction:false,
    angina:false,
	  congestiveHeartFailure:false,
	  peripheralVascularDisease:false,
    cardiovascularDiseaseUnk:false,

	  cerebrovascularDisease:false,
	  obesity:false,
	  osteoporosis:false,
	  covid:false,
	  anemia:false,
	  fractures:false,
	  osteoarthritis:false,
	  hypercalcemia:false,
	  noneoftheabove:null,


    q18EmptyHard : false,
    pulmonaryEmptyHard: false,
    KidneyEmptyHard:false,
    neuropathyEmptyHard:false,
    malignancyEmptyHard:false,
    cardiovascularEmptyHard:false,
  }
  const [Comorbidities, setComorbidities] = useState([ComorbiditiesRow]);
  const pid = GetLocalStorageData("patientId") ?? 0;
  const storedData = GetLocalStorageData("CohortTreatment");

  let cohortTreatmentModelListt = []
  if(storedData){
    cohortTreatmentModelListt = JSON.parse(storedData);
    cohortTreatmentModelListt = cohortTreatmentModelListt.filter(c => c.isEligible);
  
  }



  const HandleRepeaterFieldChange = (e) => {
   
    const { name, id, value, checked } = e.target;
    const value_int = Number(value);
    const index = Number(id);
    switch (name) {

//checkbox -Dementia
  case "q18Dementia" + id:
    {
    let updatedArray = [...Comorbidities];
    updatedArray[id].q18Dementia = !updatedArray[id].q18Dementia;
    updatedArray[id].noneoftheabove =null;
    updatedArray[id].q18EmptyHard = false;
    setComorbidities(updatedArray);
    break;
  }

//checkbox - Chronic pulmonary disease
case "chronicpulmonarydisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].chronicpulmonarydisease =!updatedArray[id].chronicpulmonarydisease;
updatedArray[id].chronicpulmonarydiseaseRd = null;
updatedArray[id].noneoftheabove =null;

updatedArray[id].q18EmptyHard = false;
updatedArray[id].pulmonaryEmptyHard = false;

  setComorbidities(updatedArray);
  break;
}


//radio - Chronic obstructive pulmonary disease 
case "chronicpulmonarydiseaseRd" + id:{
  let updatedArray = [...Comorbidities];
  updatedArray[id].chronicpulmonarydiseaseRd = value_int;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].pulmonaryEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}



//checkbox - Rheumatologicaldisease
case "rheumatologicaldisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].rheumatologicaldisease =!updatedArray[id].rheumatologicaldisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  
  setComorbidities(updatedArray);
  break;
}

//checkbox - Pepticulcerdisease
case "pepticulcerdisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].pepticulcerdisease =!updatedArray[id].pepticulcerdisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - Mildliverdisease
case "mildliverdisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].mildliverdisease =!updatedArray[id].mildliverdisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - Moderate or severe liver disease  
case "mildorSevereliverdisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].mildorSevereliverdisease =!updatedArray[id].mildorSevereliverdisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

// checkbox - DiabetesWoChronicComplication
case "diabetesWoChronicComplication" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].diabetesWoChronicComplication =!updatedArray[id].diabetesWoChronicComplication;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - Diabetes with chronic complication 

case "diabetesChronicComplication" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].diabetesChronicComplication =!updatedArray[id].diabetesChronicComplication;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - HemiplegiaOrParaplegia
case "hemiplegiaOrParaplegia" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].hemiplegiaOrParaplegia =!updatedArray[id].hemiplegiaOrParaplegia;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - Renal disease 
case "renaldisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].renaldisease  =!updatedArray[id].renaldisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - Chronic kidney disease
case "chronickidneydisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].chronickidneydisease  =!updatedArray[id].chronickidneydisease;
updatedArray[id].stage = null;
updatedArray[id].noneoftheabove =null;

updatedArray[id].q18EmptyHard = false;
updatedArray[id].KidneyEmptyHard = false;

  setComorbidities(updatedArray);
  break;
}

//radio 
case "stage" + id:{
  let updatedArray = [...Comorbidities];
  updatedArray[id].stage = value_int;

  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].KidneyEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox DialysisHemodialysis
case "dialysisHemodialysis" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].dialysisHemodialysis  =!updatedArray[id].dialysisHemodialysis;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox kidneyTransplant
case "kidneyTransplant" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].kidneyTransplant  =!updatedArray[id].kidneyTransplant;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - malignancy
case "malignancy" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].malignancy  =!updatedArray[id].malignancy;
  updatedArray[id].metastaticSolidTumor =false;
  updatedArray[id].bloodCancerMM=false;
  updatedArray[id].otherMalignancy=false;
  updatedArray[id].malignancyUnk=null;
  updatedArray[id].noneoftheabove =null;

  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].malignancyEmptyHard = false;
 
  setComorbidities(updatedArray);
  break;
}


//checkbox - metastaticSolidTumor
case "metastaticSolidTumor" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].metastaticSolidTumor  =!updatedArray[id].metastaticSolidTumor;
updatedArray[id].malignancyUnk=null;
updatedArray[id].q18EmptyHard = false;
updatedArray[id].malignancyEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - Blood cancer other than MM 
case "bloodCancerMM" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].bloodCancerMM  =!updatedArray[id].bloodCancerMM;
  updatedArray[id].malignancyUnk=null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].malignancyEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox -otherMalignancy
case "otherMalignancy" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].otherMalignancy =!updatedArray[id].otherMalignancy;
  updatedArray[id].malignancyUnk = null;
  updatedArray[id].q18EmptyHard = false;
  
  updatedArray[id].malignancyEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//radio - malignancyUnk
case "malignancyUnk" + id:{
  let updatedArray = [...Comorbidities];
  updatedArray[id].malignancyUnk = value_int;
  updatedArray[id].metastaticSolidTumor =false;
  updatedArray[id].bloodCancerMM =false;
  updatedArray[id].otherMalignancy =false;

  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].malignancyEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - aidsHIV
case "aidsHIV" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].aidsHIV =!updatedArray[id].aidsHIV;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - neuropathy
case "neuropathy" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].neuropathy =!updatedArray[id].neuropathy;
updatedArray[id].neuropathyRd = null;
updatedArray[id].noneoftheabove =null;

updatedArray[id].q18EmptyHard = false;
updatedArray[id].neuropathyEmptyHard = false;

  setComorbidities(updatedArray);
  break;
}

//radio - neuropathy
case "neuropathyRd" + id:{
  let updatedArray = [...Comorbidities];
  updatedArray[id].neuropathyRd = value_int;

  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].neuropathyEmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - cardiovascularDisease
case "cardiovascularDisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].cardiovascularDisease =!updatedArray[id].cardiovascularDisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularEmptyHard =false;
  updatedArray[id].hypertension =false;
  updatedArray[id].myocardialInfarction = false;
  updatedArray[id].angina = false;
  updatedArray[id].congestiveHeartFailure = false;
  updatedArray[id].peripheralVascularDisease = false;
  updatedArray[id].cardiovascularDiseaseUnk = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - hypertension
case "hypertension" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].hypertension =!updatedArray[id].hypertension;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularDiseaseUnk =false;
  updatedArray[id].cardiovascularEmptyHard =false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - myocardialInfarction
case "myocardialInfarction" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].myocardialInfarction =!updatedArray[id].myocardialInfarction;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularDiseaseUnk =false;
  updatedArray[id].cardiovascularEmptyHard =false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - angina
case "angina" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].angina =!updatedArray[id].angina;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularDiseaseUnk =false;
  updatedArray[id].cardiovascularEmptyHard =false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - congestiveHeartFailure
case "congestiveHeartFailure" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].congestiveHeartFailure =!updatedArray[id].congestiveHeartFailure;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularDiseaseUnk =false;
  updatedArray[id].cardiovascularEmptyHard =false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - peripheralVascularDisease
case "peripheralVascularDisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].peripheralVascularDisease =!updatedArray[id].peripheralVascularDisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularDiseaseUnk =false;
  updatedArray[id].cardiovascularEmptyHard =false;
  setComorbidities(updatedArray);
  break;
}
case "cardiovascularDiseaseUnk" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].cardiovascularDiseaseUnk =!updatedArray[id].cardiovascularDiseaseUnk;  
  updatedArray[id].hypertension = false;
  updatedArray[id].myocardialInfarction = false;
  updatedArray[id].angina = false;
  updatedArray[id].congestiveHeartFailure = false;
  updatedArray[id].peripheralVascularDisease = false;

  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  updatedArray[id].cardiovascularEmptyHard =false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - cerebrovascularDisease
case "cerebrovascularDisease" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].cerebrovascularDisease =!updatedArray[id].cerebrovascularDisease;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - obesity
case "obesity" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].obesity =!updatedArray[id].obesity;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - osteoporosis
case "osteoporosis" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].osteoporosis =!updatedArray[id].osteoporosis;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - covid
case "covid" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].covid =!updatedArray[id].covid;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

//checkbox - anemia
case "anemia" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].anemia =!updatedArray[id].anemia;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - fractures
case "fractures" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].fractures =!updatedArray[id].fractures;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - osteoarthritis
case "osteoarthritis" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].osteoarthritis =!updatedArray[id].osteoarthritis;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//checkbox - hypercalcemia
case "hypercalcemia" + id: {
  let updatedArray = [...Comorbidities];
  updatedArray[id].hypercalcemia =!updatedArray[id].hypercalcemia;
  updatedArray[id].noneoftheabove =null;
  updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}


//radio - noneoftheabove
case "noneoftheabove" + id:{
  let updatedArray = [...Comorbidities];
  updatedArray[id].noneoftheabove = value_int;

updatedArray[id].q18Dementia = false;
updatedArray[id].chronicpulmonarydisease = false;
updatedArray[id].rheumatologicaldisease = false;
updatedArray[id].pepticulcerdisease = false;
updatedArray[id].mildliverdisease = false;
updatedArray[id].mildorSevereliverdisease = false;
updatedArray[id].diabetesWoChronicComplication = false;
updatedArray[id].diabetesChronicComplication = false;
updatedArray[id].hemiplegiaOrParaplegia = false;
updatedArray[id].renaldisease = false;
updatedArray[id].chronickidneydisease = false;
updatedArray[id].dialysisHemodialysis = false;
updatedArray[id].kidneyTransplant = false;
updatedArray[id].malignancy = false;
updatedArray[id].aidsHIV = false;
updatedArray[id].neuropathy = false;
updatedArray[id].cardiovascularDisease = false;
updatedArray[id].hypertension = false;
updatedArray[id].myocardialInfarction = false;
updatedArray[id].hypertension = false;
updatedArray[id].myocardialInfarction = false;
updatedArray[id].angina = false;
updatedArray[id].congestiveHeartFailure = false;
updatedArray[id].peripheralVascularDisease = false;
updatedArray[id].cerebrovascularDisease = false;
updatedArray[id].obesity = false;
updatedArray[id].osteoporosis = false;
updatedArray[id].covid = false;
updatedArray[id].anemia = false;
updatedArray[id].fractures = false;
updatedArray[id].osteoarthritis = false;
updatedArray[id].hypercalcemia = false;


updatedArray[id].q18EmptyHard = false;
  setComorbidities(updatedArray);
  break;
}

default:
  break;
}
};




const LoadData = async () => {

  try {
    if (pid > 0) {
      const response = await CombordityService.LoadComorbidityData(
        pid
      );

      if (response?.status === HTTPResponse.OK) {
        const { data } = response;

     


      const cohortTreatmentMap = new Map();
      if (cohortTreatmentModelListt) {
        
        cohortTreatmentModelListt.forEach((item) => {
          if (item.isEligible) {
          cohortTreatmentMap.set(item.cohortTreatmentID, {
              cohortTreatmentName: (item.cohortTreatmentName === "DKRd (TE or TIE)") ? (item.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (item.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : item.cohortTreatmentName)) : item.cohortTreatmentName,
            cohortIndexDate: item.cohortIndexDate,
          });
        }
        });
      }

      let repeaterData = [];

      // Check if there is existing comorbidity data
      if (
        data.comorbidityHistory.comorbiditiesData &&
        data.comorbidityHistory.comorbiditiesData.length !== 0
      ) {
        repeaterData = data.comorbidityHistory.comorbiditiesData.map(
          (item) => {
            const cohortTreatment =
              cohortTreatmentMap.get(item.cohorttreatmentid) || {};
            return {
              cohorttreatmentid: item.cohorttreatmentid,
              cohortTreatmentName: cohortTreatment.cohortTreatmentName || null,
              cohortIndexDate: cohortTreatment.cohortIndexDate || null,
              cohortTreatmentNameSuperscript:item.cohortTreatmentNameSuperscript,

              comorbiditiesid: item.comorbiditiesid,
              q18Dementia: item.comorbidDementia,
              chronicpulmonarydisease: item.comorbidChronic,
              chronicpulmonarydiseaseRd: item.comorbidChronicPulmonary,
              rheumatologicaldisease: item.comorbidRheumatological,
              pepticulcerdisease: item.comorbidUlcer,
              mildliverdisease: item.comorbidMildliver,
              mildorSevereliverdisease: item.comorbidModerateliver,
              diabetesWoChronicComplication:
                item.comorbidChronicwithoutdiabeties,
              diabetesChronicComplication: item.comorbidChronicwithdiabeties,
              hemiplegiaOrParaplegia: item.comorbidHemiplegia,
              renaldisease: item.comorbidRenal,
              chronickidneydisease: item.comorbidKidney,
              stage: item.comorbidKidneyStage,
              dialysisHemodialysis: item.comorbidHemodialysis,
              kidneyTransplant: item.comorbidKidneytransplant,
              malignancy: item.comorbidLeukemia,
              metastaticSolidTumor: item.comorbidLeukemiaMetastatic,
              bloodCancerMM: item.comorbidLeukemiaBloodcancer,
              otherMalignancy: item.comorbidLeukemiaOthmalignancy,
              malignancyUnk: item.comorbidLeukemiaUnk === true ? 99 : null,
              aidsHIV: item.comorbidHiv,
              neuropathy: item.comorbidNeuropathy,
              neuropathyRd: item.comorbidNeuropathyDisease,
              cardiovascularDisease: item.comorbidCardiovascular,
              hypertension: item.comorbidHypertension,
              myocardialInfarction: item.comorbidMyocardial,
              angina: item.comorbidAngina,
              congestiveHeartFailure: item.comorbidHeartfailure,
              peripheralVascularDisease: item.comorbidVasculardisease,
              cardiovascularDiseaseUnk : item.comorbidCardiovascularUnk,
              cerebrovascularDisease: item.comorbidCerebrovascular,
              obesity: item.comorbidObseity,
              osteoporosis: item.comorbidOsteoporosis,
              covid: item.comorbidCovid,
              anemia: item.comorbidAnemia,
              fractures: item.comorbidFractures,
              osteoarthritis: item.comorbidOsteoarthritis,
              hypercalcemia: item.comorbidHypercalcemia,
              noneoftheabove: item.comorbidNone === true ? 99 : null,

              q18EmptyHard: false,
              pulmonaryEmptyHard: false,
              KidneyEmptyHard: false,
              malignancyEmptyHard: false,
              neuropathyEmptyHard: false,
              cardiovascularEmptyHard: false,
            };
          }
        );

        // Merge with cohortTreatmentModelListt
        cohortTreatmentModelListt.forEach((cohort) => {
          if (
            !repeaterData.some(
              (item) => item.cohorttreatmentid === cohort.cohortTreatmentID
            )
          ) {
            repeaterData.push({
              cohorttreatmentid: cohort.cohortTreatmentID,
                cohortTreatmentName: ((cohort.cohortTreatmentName === "DKRd (TE or TIE)") ? (cohort.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (cohort.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : cohort.cohortTreatmentName)) : cohort.cohortTreatmentName) ,
              cohortIndexDate: cohort.cohortIndexDate,
              cohortTreatmentNameSuperscript: cohort.cohortTreatmentNameSuperscript || null,

              comorbiditiesid: 0,
              q18EmptyHard: false,
              pulmonaryEmptyHard: false,
              KidneyEmptyHard: false,
              malignancyEmptyHard: false,
              neuropathyEmptyHard: false,
              cardiovascularEmptyHard: false
            });
          }
        });

        setComorbidities(repeaterData);
      } else {
        // No comorbidity data, use cohortTreatmentModelListt
        if (cohortTreatmentModelListt != null) {
          repeaterData = cohortTreatmentModelListt.map((item, index) => ({
            cohorttreatmentid: item.cohortTreatmentID,
              cohortTreatmentName:((item.cohortTreatmentName === "DKRd (TE or TIE)") ? (item.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (item.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : item.cohortTreatmentName)) : item.cohortTreatmentName) ,

            cohortIndexDate: item.cohortIndexDate,
            cohortTreatmentNameSuperscript:item.cohortTreatmentNameSuperscript || null,

            comorbiditiesid: 0,
            q18EmptyHard: false,
            pulmonaryEmptyHard: false,
            KidneyEmptyHard: false,
            malignancyEmptyHard: false,
            neuropathyEmptyHard: false,
            cardiovascularEmptyHard: false
          }));

          setComorbidities(repeaterData);
        }
      }
        }
       else if (response?.status === HTTPResponse.Unauthorized) {
       HandleSessionTimeout();
     }
    }
  } catch (error) {
    ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
  }
};




const SavePage = async (validate) => {

    try {
      ToggleLoader(true);

      let isValid = validate ? ValidateForm() : true;

  
      if (isValid) {
        setShowTopErrMsg(false);


        const comorbiditiesData = Comorbidities.map((test) => ({ 
          Comorbiditiesid:test.comorbiditiesid,
          Cohorttreatmentid: test.cohorttreatmentid,
          ComorbidDementia:test.q18Dementia,
          ComorbidChronic:test.chronicpulmonarydisease,
          ComorbidChronicPulmonary: test.chronicpulmonarydisease?test.chronicpulmonarydiseaseRd :null,
          ComorbidRheumatological:test.rheumatologicaldisease,
          ComorbidUlcer:test.pepticulcerdisease,
          ComorbidMildliver:test.mildliverdisease,
          ComorbidModerateliver:test.mildorSevereliverdisease,
          ComorbidChronicwithoutdiabeties:test.diabetesWoChronicComplication,
          ComorbidChronicwithdiabeties:test.diabetesChronicComplication,
          ComorbidHemiplegia:test.hemiplegiaOrParaplegia,
          ComorbidRenal:test.renaldisease,
          ComorbidKidney:test.chronickidneydisease,
          ComorbidKidneyStage: test.chronickidneydisease ?test.stage :null,
          ComorbidHemodialysis:test.dialysisHemodialysis,
          ComorbidKidneytransplant:test.kidneyTransplant,
          ComorbidLeukemia:test.malignancy,
          ComorbidLeukemiaMetastatic:test.metastaticSolidTumor,
          ComorbidLeukemiaBloodcancer:test.bloodCancerMM,
          ComorbidLeukemiaOthmalignancy:test.otherMalignancy,
          ComorbidLeukemiaUnk:test.malignancyUnk,
          ComorbidHiv:test.aidsHIV,
          ComorbidNeuropathy :test.neuropathy,
          ComorbidNeuropathyDisease :test.neuropathy?test.neuropathyRd : null,
          ComorbidCardiovascular :test.cardiovascularDisease,
          ComorbidHypertension :test.cardiovascularDisease?test.hypertension:false,
          ComorbidMyocardial :test.cardiovascularDisease?test.myocardialInfarction:false,
          ComorbidAngina :test.cardiovascularDisease?test.angina:false,
          ComorbidHeartfailure :test.cardiovascularDisease?test.congestiveHeartFailure:false,
          ComorbidVasculardisease:test.cardiovascularDisease?test.peripheralVascularDisease:false,
          ComorbidCardiovascularUnk:test.cardiovascularDisease?test.cardiovascularDiseaseUnk:false,
          ComorbidCerebrovascular :test.cerebrovascularDisease,
          ComorbidObseity :test.obesity,
          ComorbidOsteoporosis : test.osteoporosis,
          ComorbidCovid :test.covid,
          ComorbidAnemia :test.anemia,
          ComorbidFractures :test.fractures,
          ComorbidOsteoarthritis : test.osteoarthritis,
          ComorbidHypercalcemia :test.hypercalcemia,
          ComorbidNone :test.noneoftheabove === 99? true : false,


        }));
 

        const comborditiesModel = {
          Patientid: pid,
          UserId: GetLoggedInUserID(),
          isValidated: validate,
          ComorbiditiesData: comorbiditiesData,

        
        };

        if (pid > 0) {
          let response = await CombordityService.SaveComorbidityData(
            comborditiesModel
          );
        
          if (response.status !== HTTPResponse.OK) {
           
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();

            } else throw response.error;
          } else{
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData(pid);
          }
             
          ToggleLoader(false);
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }

  }







  const ValidateForm = () => {
    let result = true;

    //Repeater
    if (Comorbidities && Comorbidities.length > 0) {
      const updatedRepeaterDiseaseHist = Comorbidities.map((row, idx) => {
        let updatedRow = { ...row };

        if (
          !row.q18Dementia &&
          !row.chronicpulmonarydisease &&
          !row.rheumatologicaldisease &&
          !row.pepticulcerdisease &&
          !row.mildliverdisease &&
          !row.mildorSevereliverdisease &&
          !row.diabetesWoChronicComplication &&
          !row.diabetesChronicComplication &&
          !row.hemiplegiaOrParaplegia &&
          !row.renaldisease &&
          !row.chronickidneydisease &&
          !row.dialysisHemodialysis &&
          !row.kidneyTransplant &&
          !row.malignancy &&
          !row.metastaticSolidTumor &&
          !row.bloodCancerMM &&
          !row.otherMalignancy &&
          !row.malignancyUnk &&
          !row.aidsHIV &&
          !row.neuropathy &&
          !row.neuropathyRd &&
          !row.cardiovascularDisease &&
          !row.hypertension &&
          !row.myocardialInfarction &&
          !row.angina &&
          !row.congestiveHeartFailure &&
          !row.peripheralVascularDisease &&
          !row.cardiovascularDiseaseUnk &&
          !row.cerebrovascularDisease &&
          !row.obesity &&
          !row.osteoporosis &&
          !row.covid &&
          !row.anemia &&
          !row.fractures &&
          !row.osteoarthritis &&
          !row.hypercalcemia &&
          !row.noneoftheabove
        ) {
          updatedRow.q18EmptyHard = true;
          result = false;
        }
        else {
          updatedRow.q18EmptyHard = false;
        }


        if(row.chronicpulmonarydisease === true && row.chronicpulmonarydiseaseRd === null)
        {
          updatedRow.pulmonaryEmptyHard = true;
          result = false;
        }


        if(row.chronickidneydisease === true && row.stage === null)
        {
          updatedRow.KidneyEmptyHard = true;
          result = false;
        }


        if(row.malignancy === true && (!row.metastaticSolidTumor && !row.bloodCancerMM  && !row.otherMalignancy  && row.malignancyUnk === null))
        {
          updatedRow.malignancyEmptyHard = true;
          result = false;
        }


        
        if(row.neuropathy === true && row.neuropathyRd === null)
        {
          updatedRow.neuropathyEmptyHard = true;
          result = false;
        }
        if(row.cardiovascularDisease && (!row.hypertension&&!row.myocardialInfarction&&!row.angina&&!row.congestiveHeartFailure&&!row.peripheralVascularDisease&&!row.cardiovascularDiseaseUnk))
          {
            updatedRow.cardiovascularEmptyHard = true;
            result = false;
          }      
        return updatedRow;
      });
      setComorbidities(updatedRepeaterDiseaseHist);
    }

    return result;
  };
  
  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);
  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});
    return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
<div >
<SideBarMenu activePageId={SideMenuItems.Comorbidities} />

<>
<button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

  <div className="col-pad-left" id="main">
    <div className="survey-section-content">
      <div className="survey-section-content-header">
        <h4> Comorbidities </h4>
      </div>
   {showTopErrMsg && (
        <div className="clean-error ">
          <ErrorField
            show={showTopErrMsg}
            message={CommonError_Msgs.Hard_Empty}
          />
        </div>
      )}
      <div className="survey-question-section">
        <div className="survey-question-content">
          <div className="question-answer">

            <div className="vertical-pad-15">
        
            </div>
                   
         
            <div className="dependent-section">   

             {
                     Comorbidities  && Comorbidities.length > 0 && (
                      Comorbidities.map((test, index) => (
                <div className="sub-question-bot-sapce answer-pad-left">
                  {/* <div className='cohort-header'>
                    <span>
                      {test.cohortTreatmentName === "TALVEY" || test.cohortTreatmentName === "ELREXFIO" ? (
                      <>
                        {test.cohortTreatmentName}
                        <sup className="supscript">TM</sup>
                      </>
                      ) : (
                        test.cohortTreatmentName
                      )}
                      
                    </span>
                  </div> */}
                  <div className='cohort-header'>
                  <span>
                                        
                    {test.cohortTreatmentName}
                    {test.cohortTreatmentNameSuperscript == null ? (
                      ''
                    ) : (
                      <>
                      <sup className="supscript">{test.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                      {test.cohortTreatmentNameSuperscript.slice(2)}
                      </>
                      )}
                  </span>

                  </div>  
                  <div className="question question-weight">
                    <span>Q18.</span>
                    <span className="quest-text-pad">
                    Select the comorbid medical conditions reported in the patient’s chart on or within 12 months prior to treatment with      <span style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }}>
                                {/* {test.cohortTreatmentName} */}

                                {/* {test.cohortTreatmentName === "TALVEY" || test.cohortTreatmentName === "ELREXFIO" ? (
                                  <>
                                    {test.cohortTreatmentName}
                                    <sup className="supscript">TM</sup>
                                  </>
                                ) : (
                                  test.cohortTreatmentName
                                )} */}

                                {test.cohortTreatmentName}
                                {test.cohortTreatmentNameSuperscript == null ? (
                                  ''
                                ) : (
                                  <>
                                  <sup className="supscript">{test.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                  {test.cohortTreatmentNameSuperscript.slice(2)}
                                  </>
                                  )}


                                
                              </span>{" "}(i.e., from <span style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }}> {formatDate(test.cohortIndexDate)} </span> to  <span style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }}>{test.cohortIndexDate}</span>). 
                              {" "}<span style={{fontWeight: "normal",fontStyle: "italic"}}>Select all that apply.</span> 
                    </span>
                   </div>
                  <br/>
                  <div className="double-dig-answer">
                          <div className="">
                          <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"q18Dementia" + index}
                               checked={test.q18Dementia}
                               onChange={HandleRepeaterFieldChange}
                            />
                          
                              <span className="radio-text-padding">
                              Dementia
                              </span>
                            </label>
                          </div>


                          <div className="">
                            <label >
                              <input
                                type="checkbox"
                                id={index}
                                name={"chronicpulmonarydisease" + index}
                                checked={test.chronicpulmonarydisease }
                                onChange={HandleRepeaterFieldChange}
                              />
                          
                                <span className="radio-text-padding">
                                Chronic pulmonary disease 
                                </span>
                              </label>



                              {test.chronicpulmonarydisease && (
                              <div className="dependent-sub-section" style={{
                                backgroundColor: '#F6F6F6',
                                marginLeft: '28px',
                                marginBlock: '6px',
                                padding: '6px 28px'
                              }}>
                                <div className="">
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"chronicpulmonarydiseaseRd" + index}
                                    value={1}
                                     checked={test.chronicpulmonarydiseaseRd === 1}
                                     onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Chronic obstructive pulmonary disease </span>
                                  </label>
                                </div>

                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"chronicpulmonarydiseaseRd" + index}
                                    value={2}
                                    checked={test.chronicpulmonarydiseaseRd === 2}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                
                                    <span className="radio-text-padding">Other chronic pulmonary disease </span>
                                  </label>
                                </div>


                                 <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"chronicpulmonarydiseaseRd" + index}
                                    value={99}
                                     checked={test.chronicpulmonarydiseaseRd === 99}
                                     onChange={HandleRepeaterFieldChange}
                                  />
                                
                                    <span className="radio-text-padding">Unknown </span>
                                  </label>
                                </div>

                              <ErrorField show={test.pulmonaryEmptyHard} message={ComborbidityErrorMsg.pulmonaryEmptyHard} />    
                              </div>
                            </div>
                            )}

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"rheumatologicaldisease" + index}
                               checked={test.rheumatologicaldisease  }
                               onChange={HandleRepeaterFieldChange}
                            />
                            
                              <span className="radio-text-padding">
                              Rheumatological disease  
                              </span>
                            </label>
                          </div>

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"pepticulcerdisease" + index}
                               checked={test.pepticulcerdisease }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Peptic ulcer disease  
                              </span>
                            </label>
                          </div>

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"mildliverdisease" + index}
                               checked={test.mildliverdisease }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Mild liver disease  
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"mildorSevereliverdisease" + index}
                               checked={test.mildorSevereliverdisease }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Moderate or severe liver disease  
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"diabetesWoChronicComplication" + index}
                               checked={test.diabetesWoChronicComplication  }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Diabetes without chronic complication 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"diabetesChronicComplication" + index}
                               checked={test.diabetesChronicComplication  }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Diabetes with chronic complication 
                              </span>
                            </label>
                          </div>
                          

                          
                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"hemiplegiaOrParaplegia" + index}
                               checked={test.hemiplegiaOrParaplegia    }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Hemiplegia or paraplegia  
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"renaldisease" + index}
                               checked={test.renaldisease   }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Renal disease 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"chronickidneydisease" + index}
                               checked={test.chronickidneydisease}
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Chronic kidney disease 
                              </span>
                            </label>
                          </div>

  
                          {test.chronickidneydisease && (
                            <div className="dependent-sub-section" style={{
                              backgroundColor: '#F6F6F6',
                              marginLeft: '28px',
                              marginBlock: '6px',
                              padding: '6px 28px'
                            }}>
                              <div className="">
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={1}
                                     checked={test.stage  === 1}
                                     onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Stage 1 </span>
                                  </label>
                                </div>

                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={2}
                                    checked={test.stage === 2}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Stage 2 </span>
                                  </label>
                                </div>


                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={3}
                                     checked={test.stage === 3}
                                     onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Stage 3 </span>
                                  </label>
                                </div>

                                
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={4}
                                    checked={test.stage === 4}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  
                                    <span className="radio-text-padding">Stage 4 </span>
                                  </label>
                                </div>

                                
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={5}
                                    checked={test.stage === 5}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  
                                    <span className="radio-text-padding">Stage 5 </span>
                                  </label>
                                </div>

                                
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={6}
                                    checked={test.stage === 6}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  
                                    <span className="radio-text-padding">End Stage </span>
                                  </label>
                                </div>

                                
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"stage" + index}
                                    value={99}
                                    checked={test.stage === 99}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  
                                    <span className="radio-text-padding">Unknown </span>
                                  </label>
                                </div>

                              </div>
                              <ErrorField show={test.KidneyEmptyHard} message={ComborbidityErrorMsg.KidneyEmptyHard} />
                            </div>
                          )}


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"dialysisHemodialysis" + index}
                               checked={test.dialysisHemodialysis }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Dialysis/hemodialysis 
                              </span>
                            </label>
                          </div>

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"kidneyTransplant" + index}
                               checked={test.kidneyTransplant  }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Kidney transplant 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"malignancy" + index}
                               checked={test.malignancy  }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Any malignancy, including lymphoma and leukemia, except malignant neoplasm of skin 
                              </span>
                            </label>
                          </div>

                          {test.malignancy && (
                            <div className="dependent-sub-section" style={{
                              backgroundColor: '#F6F6F6',
                              marginLeft: '28px',
                              marginBlock: '6px',
                              padding: '6px 28px'
                            }}>

                              
                              <div className="">
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="checkbox"
                                    id={index}
                                    name={"metastaticSolidTumor" + index}
                                    checked={test.metastaticSolidTumor}
                                    onChange={HandleRepeaterFieldChange}
                                  />                            
                                    <span className="radio-text-padding">
                                    Metastatic solid tumor 
                                    </span>
                                  </label>
                                </div>
                         
                      
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="checkbox"
                                    id={index}
                                    name={"bloodCancerMM" + index}
                                    checked={test.bloodCancerMM  }
                                    onChange={HandleRepeaterFieldChange}
                                  />                            
                                    <span className="radio-text-padding">
                                    Blood cancer other than MM 
                                    </span>
                                  </label>
                                </div>


                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="checkbox"
                                    id={index}
                                    name={"otherMalignancy" + index}
                                    checked={test.otherMalignancy   }
                                    onChange={HandleRepeaterFieldChange}
                                  />                            
                                    <span className="radio-text-padding">
                                    Other malignancy other than MM
                                    </span>
                                  </label>
                                </div>

                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"malignancyUnk" + index}
                                    value={99}
                                     checked={test.malignancyUnk === 99}
                                     onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Unknown </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField show={test.malignancyEmptyHard} message={ComborbidityErrorMsg.malignancyEmptyHard} /> 
                            </div>
                          )}

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"aidsHIV" + index}
                               checked={test.aidsHIV }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              AIDS / HIV 
                              </span>
                            </label>
                          </div>



                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"neuropathy" + index}
                               checked={test.neuropathy  }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Neuropathy 
                              </span>
                            </label>
                          </div>



                          {test.neuropathy && (
                            <div className="dependent-sub-section" style={{
                              backgroundColor: '#F6F6F6',
                              marginLeft: '28px',
                              marginBlock: '6px',
                              padding: '6px 28px'
                            }}>
                              <div className="">
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"neuropathyRd" + index}
                                    value={1}
                                     checked={test.neuropathyRd === 1}
                                     onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Peripheral neuropathy </span>
                                  </label>
                                </div>

                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"neuropathyRd" + index}
                                    value={2}
                                    checked={test.neuropathyRd === 2}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                 
                                    <span className="radio-text-padding">Other neuropathy </span>
                                  </label>
                                </div>
                         
                                <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"neuropathyRd" + index}
                                    value={99}
                                    checked={test.neuropathyRd === 99}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  
                                    <span className="radio-text-padding">Unknown </span>
                                  </label>
                                </div>

                              </div>
                              <ErrorField show={test.neuropathyEmptyHard} message={ComborbidityErrorMsg.neuropathyEmptyHard} /> 
                            </div>
                          )}



                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"cardiovascularDisease" + index}
                               checked={test.cardiovascularDisease   }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Cardiovascular disease 
                              </span>
                            </label>
                          </div>
                          {test.cardiovascularDisease && (
                              <div className="dependent-sub-section" style={{
                                backgroundColor: '#F6F6F6',
                                marginLeft: '28px',
                                marginBlock: '6px',
                                padding: '6px 28px'
                              }}>
                                <div>
                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"hypertension" + index}
                               checked={test.hypertension    }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Hypertension 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"myocardialInfarction" + index}
                               checked={test.myocardialInfarction     }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Myocardial infarction 
                              </span>
                            </label>
                          </div>



                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"angina" + index}
                               checked={test.angina}
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Angina 
                              </span>
                            </label>
                          </div>


                          
                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"congestiveHeartFailure" + index}
                               checked={test.congestiveHeartFailure }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Congestive heart failure 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"peripheralVascularDisease" + index}
                               checked={test.peripheralVascularDisease }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Peripheral vascular disease 
                              </span>
                            </label>
                          </div>
                                  <div className="">
                                  <label >
                                    <input
                                      type="radio"
                                      id={index}
                                      name={"cardiovascularDiseaseUnk" + index}
                                      checked={test.cardiovascularDiseaseUnk }
                                      onChange={HandleRepeaterFieldChange}
                                    />                            
                                      <span className="radio-text-padding">
                                      Unknown
                                      </span>
                                    </label>
                                </div>
                                </div>
                              <ErrorField show={test.cardiovascularEmptyHard} message={ComborbidityErrorMsg.cardiovascularEmptyHard} /> 
                            </div>
                          )}

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"cerebrovascularDisease" + index}
                               checked={test.cerebrovascularDisease  }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Cerebrovascular disease 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"obesity" + index}
                               checked={test.obesity}
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Obesity  
                              </span>
                            </label>
                          </div>

                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"osteoporosis" + index}
                               checked={test.osteoporosis    }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Osteoporosis 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"covid" + index}
                               checked={test.covid    }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              COVID-19 
                              </span>
                            </label>
                          </div>


                          
                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"anemia" + index}
                               checked={test.anemia     }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Anemia 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"fractures" + index}
                               checked={test.fractures      }
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Fractures 
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"osteoarthritis" + index}
                               checked={test.osteoarthritis}
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Osteoarthritis  
                              </span>
                            </label>
                          </div>


                          <div className="">
                           <label >
                            <input
                              type="checkbox"
                              id={index}
                              name={"hypercalcemia" + index}
                               checked={test.hypercalcemia}
                               onChange={HandleRepeaterFieldChange}
                            />                            
                              <span className="radio-text-padding">
                              Hypercalcemia 
                              </span>
                            </label>
                          </div>



                               <div className="option-without-input">
                                <label >
                                  <input
                                    type="radio"
                                    id={index}
                                    name={"noneoftheabove" + index}
                                    value={99}
                                    checked={test.noneoftheabove === 99}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  
                                    <span className="radio-text-padding">None of the above </span>
                                  </label>
                              </div>                             
                          </div>
                  </div>
                   <ErrorField show={test.q18EmptyHard} message={ComborbidityErrorMsg.q18EmptyHard} />                          
                </div>                 
                      )))}     
                </div>   
          </div>
        </div>
      </div>
      <div className="survey-section-content-foot">
        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
      </div>
    </div>
  </div>
</div>
</section>
    </>
  );
};

export default Comorbidities;