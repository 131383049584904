import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { AppContext } from "../../../Contexts/AppContextProvider";
import DateControl from "../../../Components/DateControl";
import ErrorField from "../../../Components/ErrorField";
import { NDMMTreatmentCohortService } from "../../../WebApiServices/NDMMTreatmentCohortService.service";
import { predefinedReasons, NDMMTreatmentCohort_Msgs, CommonError_Msgs } from "../../../Helpers/HelperText";
import DatePickerComponent from "../../../Components/DatePickerComponent";
import dayjs from "dayjs";
//import Select from 'react-select';

const NDMMAgentData = ({ phaseid, agentList, agentData, setAgentData, listToDelete }) => {
    const { appState } = useContext(AppContext);

    const [agentListToBind, setAgentListToBind] = useState([]);
    const [showAddMore, setShowAddMore] = useState(true);

    useEffect(() => {
       
        if (agentList.length > 0 && agentData.length > 0) {
            async function FetchData() {
                let addedDynamicRowsCount = agentData.filter(a => a.defaultAgent === false);
                if ((phaseid === 1 || phaseid === 4) && addedDynamicRowsCount.length === 3)
                    setShowAddMore(false);

                // Create a set of agentid from agentData
                const agentDataIds = new Set(agentData.map(agent => agent.agentid));

                // Filter out agents from agentList that exist in agentData
                const filteredAgentList = agentList.filter(agent => !agentDataIds.has(agent.agentid) || agent.agentName.includes('Other'));

                let agentListBind = filteredAgentList.filter(a => a.isDynamic);
                setAgentListToBind(agentListBind);
            }
            FetchData();
        }
    }, [agentList, agentData]);

    const addAgentRow = () => {
        debugger
        const emptyAgentData = {
            agentName: null,
            agentOtherValue: null,
            agentid: null,
            defaultAgent: false,
            discontinueAgent: null,
            firstDate: null,
            firstDateRadio: null,
            firstDateUnk: null,
            lastDate: null,
            lastDateRadio: null,
            lastDateUnk: null,
            ndmmtreatmentagentbridgeid: null,
            noOfAdmission: null,
            noOfAdmissionRadio: null,
            noOfAdmissionUnk: null,
            doseAdministrationRadiomg: null,
            doseAdministrationmg:null,
            doseAdministrationRadiokg: null,
            doseAdministrationkg: null,
            doseAdministrationRadiomgm: null,
            doseAdministrationmgm:null,
            phaseid: phaseid,
            reasonForDiscontinuingAgents: predefinedReasons.map(reason => ({
                ...reason,
                isSelected: null, reasonUnk: null // Ensure isSelected is set to null
            })),
            showColAError: false,
            showColAOthError: false,
            showColAOthDupError: false,
            showColBError: false,
            showColBInvalidDate: false,
            showColB_IdxDateError: false,
            showColB_SFDateError: false,
            showColB_SEDateError: false,
            showColB_SEFDateError: false,
            showColCError: false,
            showColCInvalidDate: false,
            showColC_EFDateError: false,
            showColC_SEFDateError: false,
            showColDError: false,
            showColEError: false,
            showColF: false,
            showColFError: false,
            showColFOthError: false,
            showColGError: false,
        };
        setAgentData([...agentData, emptyAgentData]);

        let addedDynamicRowsCount = agentData.filter(a => a.defaultAgent === false);
        if ((phaseid === 1 || phaseid === 4) && addedDynamicRowsCount.length === 3)
            setShowAddMore(false);
    }

    const HandleRepeaterFieldChange = (e, agentName, reasonName, parentIdx, itemidx) => {

        let { name, id, value } = e.target;
        let updatedArray = [...agentData];
        const index = id.split('_').pop(); // Extract the index from the id
        switch (name) {
            case `q22a_${phaseid}_${agentName}_txt_${index}`: {
                updatedArray[index].showColAError = false;
                updatedArray[index].showColAOthError = false;
                updatedArray[index].showColAOthDupError = false;
                if (value.length <= 300) {
                    updatedArray[index].agentOtherValue = value;
                }
                else {
                    updatedArray[index].agentOtherValue = value.substring(0, 300);
                }
            }
                break;
            case `q22b_${phaseid}_FRadio_${agentName}_${index}`: {
                updatedArray[index].firstDateRadio = 1;
                updatedArray[index].firstDate = null;
                updatedArray[index].firstDateUnk = "";
                updatedArray[index].showColBError = false;
                updatedArray[index].showColBInvalidDate = false;
                updatedArray[index].showColB_IdxDateError = false;
                updatedArray[index].showColB_SFDateError = false;
                updatedArray[index].showColB_SEDateError = false;
                updatedArray[index].showColB_SEFDateError = false;

                updatedArray = updatedArray.map(item => {
                    return { ...item, showColB_IdxDateError: false };
                });
            }
                break;
            case `q22b_${phaseid}_${agentName}_FUnk_${index}`: {
                updatedArray[index].firstDateRadio = "";
                updatedArray[index].firstDate = null;
                updatedArray[index].firstDateUnk = Number(value);
                updatedArray[index].showColBError = false;
                updatedArray[index].showColBInvalidDate = false;
                updatedArray[index].showColB_IdxDateError = false;
                updatedArray[index].showColB_SFDateError = false;
                updatedArray[index].showColB_SEDateError = false;
                updatedArray[index].showColB_SEFDateError = false;
                updatedArray = updatedArray.map(item => {
                    return { ...item, showColB_IdxDateError: false };
                });
            }
                break;
            case `q22c_${phaseid}_LRadio_${agentName}_${index}`: {
                updatedArray[index].lastDateRadio = 1;
                updatedArray[index].lastDate = null;
                updatedArray[index].lastDateUnk = "";
                updatedArray[index].showColCError = false;
                updatedArray[index].showColCInvalidDate = false;
                updatedArray[index].showColC_EFDateError = false;
                updatedArray[index].showColC_SEFDateError = false;
            }
                break;
            case `q22c_${phaseid}_${agentName}_LUnk_${index}`: {
                updatedArray[index].lastDateRadio = "";
                updatedArray[index].lastDate = null;
                updatedArray[index].lastDateUnk = Number(value);
                updatedArray[index].showColCError = false;
                updatedArray[index].showColCInvalidDate = false;
                updatedArray[index].showColC_EFDateError = false;
                updatedArray[index].showColC_SEFDateError = false;
            }
                break;
            case `q22d_${phaseid}_${agentName}_Noa_${index}`: {
                updatedArray[index].noOfAdmissionRadio = Number(value);
                updatedArray[index].noOfAdmission = "";
                updatedArray[index].noOfAdmissionUnk = "";
                updatedArray[index].showColDError = false;
            }
                break;
            case `q22d_${phaseid}_${agentName}_Noatxt_${index}`: {
                updatedArray[index].noOfAdmissionRadio = 1;
                updatedArray[index].noOfAdmission =(value);
                updatedArray[index].noOfAdmissionUnk = "";
                updatedArray[index].showColDError = false;
            }
                break;
            case `q22d_${phaseid}_${agentName}_NoaUnk_${index}`: {
                updatedArray[index].noOfAdmissionRadio = "";
                updatedArray[index].noOfAdmission = "";
                updatedArray[index].noOfAdmissionUnk = Number(value);
                updatedArray[index].showColDError = false;
            }
                break;

            case `q22g_${phaseid}_${agentName}_mg_${index}`: {
                updatedArray[index].doseAdministrationRadiomg = Number(value);
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].showColGError = false;
            }
                break;
            case `q22g_${phaseid}_${agentName}_mgtxt_${index}`: {
                updatedArray[index].doseAdministrationRadiomg = 1;
                updatedArray[index].doseAdministrationmg = (value);
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].showColGError = false;
            }
                break;
            case `q22g_${phaseid}_${agentName}_kg_${index}`: {
                updatedArray[index].doseAdministrationRadiokg = Number(value);
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].showColGError = false;
            }
                break;
            case `q22g_${phaseid}_${agentName}_kgtxt_${index}`: {
                updatedArray[index].doseAdministrationRadiokg = 1;
                updatedArray[index].doseAdministrationkg = (value);
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].doseAdministrationRadiomgm = null;
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].showColGError = false;
            }
                break;
            case `q22g_${phaseid}_${agentName}_mgm_${index}`: {
                updatedArray[index].doseAdministrationRadiomgm = Number(value);
                updatedArray[index].doseAdministrationmgm = "";
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].showColGError = false;
            }
                break;
            case `q22g_${phaseid}_${agentName}_mgmtxt_${index}`: {
                updatedArray[index].doseAdministrationRadiomgm = 1;
                updatedArray[index].doseAdministrationmgm = (value);
                updatedArray[index].doseAdministrationRadiokg = null;
                updatedArray[index].doseAdministrationkg = "";
                updatedArray[index].doseAdministrationRadiomg = null;
                updatedArray[index].doseAdministrationmg = "";
                updatedArray[index].showColGError = false;
            }
                break;
            case `q22e_${phaseid}_${agentName}_PdaYes_${index}`:
            case `q22e_${phaseid}_${agentName}_PdaNo_${index}`:
            case `q22e_${phaseid}_${agentName}_PdaUnk_${index}`: {
                updatedArray[index].discontinueAgent = Number(value);
                updatedArray[index].showColEError = false;
                updatedArray[index].showColFError = false;
                updatedArray[index].showColFOthError = false;
                updatedArray[index].showColF = Number(value) === 1 ? true : false;
                if (Number(value) !== 1)
                    updatedArray[index].reasonForDiscontinuingAgents = updatedArray[index].reasonForDiscontinuingAgents.map(item => {
                        return { ...item, isSelected: false, reasonUnk: "" };
                    });
            }
                break;
            case `discont_${phaseid}_${agentName}_${reasonName}_${itemidx}`: {
                updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].agentid = updatedArray[parentIdx].agentid;
                updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].isSelected = !updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].isSelected;
                updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].reasonText = (updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].reasonName.includes('Other')) && '';
                updatedArray[parentIdx].reasonForDiscontinuingAgents[0].reasonUnk = "";
                updatedArray[parentIdx].showColFError = false;
                updatedArray[parentIdx].showColFOthError = false;
            }
                break;
            case `discont_unk_${phaseid}_${agentName}_${parentIdx}`: {
                updatedArray[parentIdx].showColFError = false;
                updatedArray[parentIdx].showColFOthError = false;
                updatedArray[parentIdx].reasonForDiscontinuingAgents = updatedArray[parentIdx].reasonForDiscontinuingAgents.map(item => {
                    return { ...item, isSelected: false, reasonUnk: 1, reasonText: '' };
                });
            }
                break;
            case `discont_${phaseid}_${agentName}_${reasonName}txt_${itemidx}`: {

                updatedArray[parentIdx].showColFError = false;
                updatedArray[parentIdx].showColFOthError = false;
                updatedArray[parentIdx].reasonForDiscontinuingAgents[0].reasonUnk = "";
                updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].isSelected = (updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].reasonName.includes('Other')) ? true : false;

                if (value.length <= 300) {
                    updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].reasonText = value;
                }
                else {
                    updatedArray[parentIdx].reasonForDiscontinuingAgents[itemidx].reasonText = value.substring(0, 300);
                }
            }
                break;
            default:
                break;
        }
        setAgentData(updatedArray);
    };

    const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {
        let updatedArray = [...agentData];
        const idx = name.split('_').pop();

        switch (name) {
            case `q22b_${phaseid}_FDate_${idx}`: {
                updatedArray[idx].firstDateRadio = 1;
                updatedArray[idx].firstDate = date;
                updatedArray[idx].firstDateUnk = "";
                updatedArray[idx].showColBError = false;
                updatedArray[idx].showColBInvalidDate = false;
                updatedArray[idx].showColB_IdxDateError = false;
                updatedArray[idx].showColB_SFDateError = false;
                updatedArray[idx].showColB_SEDateError = false;
                updatedArray[idx].showColB_SEFDateError = false;
                updatedArray = updatedArray.map(item => {
                    return { ...item, showColB_IdxDateError: false };
                });
            }
                break;
            case `q22c_${phaseid}_LDate_${idx}`: {
                updatedArray[idx].lastDateRadio = 1;
                updatedArray[idx].lastDate = date;
                updatedArray[idx].lastDateUnk = "";
                updatedArray[idx].showColCError = false;
                updatedArray[idx].showColCInvalidDate = false;

                updatedArray[idx].showColC_EFDateError = false;
                updatedArray[idx].showColC_SEFDateError = false;
            }
                break;
            default:
                break;
        }
        setAgentData(updatedArray);
    };

    const HandleDDChange = (item, idx) => {
        let updatedArray = [...agentData];

        updatedArray[idx].agentid = Number(item.agentid);
        updatedArray[idx].agentName = item.agentName;
        updatedArray[idx].showColAError = false;
        updatedArray[idx].agentOtherValue = "";

        if (!item.agentName.includes('Other')) {
            //Remove selected items from agent List=
            let agentListBind = agentListToBind.filter(a => a.agentid !== item.agentid);
            setAgentListToBind(agentListBind);
        }
        else {
            setAgentListToBind(agentListToBind);
        }

        setAgentData(updatedArray);
    }

    async function OnDeleteRow(item, idx) {
        let updatedArray = [...agentData];
        if (item.agentid === null) {
            // Remove item by index if agentid is null
            updatedArray.splice(idx, 1);
        } else {
            // Filter out the item based on agentid
            updatedArray = updatedArray.filter(a => a.agentid !== item.agentid);
        }

        setAgentData(updatedArray);

        let agentListBind = agentList.filter(a => a.agentid === item.agentid);
        if (agentListBind.length > 0) {
            agentListToBind.push(agentListBind[0]);
        }
        setAgentListToBind(agentListToBind);

        let addedDynamicRowsCount = updatedArray.filter(a => a.defaultAgent === false);
        if ((phaseid === 1 || phaseid === 4) && addedDynamicRowsCount.length === 3)
            setShowAddMore(false);
        else
            setShowAddMore(true);

        //Fill agent bridge id to delete from the table
        if (item.ndmmtreatmentagentbridgeid !== 0) {
            listToDelete.push({ ndmmtreatmentagentbridgeid: item.ndmmtreatmentagentbridgeid });
        }

    }

    const groupAndSortByCategory = (agentList) => {
        return agentList.sort((a, b) => a.sortOrder - b.sortOrder).reduce((acc, item) => {
            const category = item.agentCategory || 'uncategorized';
            (acc[category] = acc[category] || []).push(item);
            return acc;
        }, {});
    };

    const sortAgents = (agents) => {
        return agents.sort((a, b) => a.sortOrder - b.sortOrder);
    };

    const groupedAgents = groupAndSortByCategory(agentListToBind.filter(f => f.isDynamic && !f.isFilled));
    return (

        <div className="" style={{ overflowX: 'auto' }}>
            <table
                style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    width: "100%",
                }}
            >
                <thead>
                    <tr>
                        <th style={{
                            border: "1px solid black",
                            padding: "8px",
                            textAlign: "center",
                        }}>
                            Agent
                        </th>
                        <th style={{
                            border: "1px solid black",
                            padding: "8px",
                            textAlign: "center",
                        }}>
                            Date of first administration
                        </th>
                        <th style={{
                            border: "1px solid black",
                            padding: "8px",
                            textAlign: "center",
                        }}>
                            Date of last administration
                        </th>
                        <th style={{
                            border: "1px solid black",
                            padding: "8px",
                            textAlign: "center",
                            minWidth: '150px'
                        }}>
                            Number of administrations
                        </th>
                        {(phaseid === 1 || phaseid === 4) &&
                            <th style={{
                                border: "1px solid black",
                                padding: "8px",
                                textAlign: "center",
                                minWidth: '260px'
                            }}>
                                Dose at first administration
                            </th>}
                        <th style={{
                            border: "1px solid black",
                            padding: "8px",
                            textAlign: "center",
                            minWidth: '250px'
                        }}>
                            Did patient discontinue agent?
                        </th>
                        <th style={{
                            border: "1px solid black",
                            padding: "8px",
                            textAlign: "center",
                            minWidth: '300px'
                        }}>
                            Reason for discontinuing
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>

                    {/* Populate static rows */}
                    {agentData.length > 0 && agentData?.map((data, idx) => (
                        <tr key={idx}>
                            {(phaseid === 1 || phaseid === 4) ? (
                                <td
                                    style={{
                                        border: "1px solid black",
                                        alignContent: "start",
                                        textAlign: "center",
                                        padding: "8px",
                                    }}
                                >
                                    { }
                                    <div className="radio-text-padding" style={{ display: 'flex' }}>
                                        {(data.defaultAgent || data.ndmmtreatmentagentbridgeid !== null) && (
                                            <div>{data.agentName}</div>
                                        )}

                                        {(!data.defaultAgent && data.ndmmtreatmentagentbridgeid === null) && (
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`} name={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`} className="custom-dropdown-button"
                                                    style={{ width: '200px' }}>
                                                    <span style={{ textWrap: "wrap", wordBreak: "break-word" }}>
                                                        {data.agentName || "Select Agent"}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{
                                                    maxHeight: '200px',
                                                    overflowY: 'scroll',
                                                    overflowX: 'hidden'
                                                }}>
                                                    {agentListToBind.filter(f => f.isDynamic && !f.isFilled).map((item) => (
                                                        <Dropdown.Item
                                                            as="button"
                                                            id={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`}
                                                            name={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`}
                                                            key={item.agentid}
                                                            value={item.agentid}
                                                            className={`custom-dropdown-item`}
                                                            style={{ width: "100% !important" }}
                                                            onClick={() => { HandleDDChange(item, idx) }}
                                                        >
                                                            <span className="radio-text-padding">
                                                                {item.agentName}
                                                                {item.supscripttext && (
                                                                    <>
                                                                        <sup className="supscript">{item.supscripttext.slice(0, 2)}</sup>
                                                                        {item.supscripttext.slice(2)}
                                                                    </>
                                                                )}
                                                            </span>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </div>
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColAError}
                                        message={NDMMTreatmentCohort_Msgs.q22_25a}
                                    />
                                </td>) :
                                (<td
                                    style={{
                                        border: "1px solid black",
                                        alignContent: "start",
                                        textAlign: "center",
                                        padding: "8px",
                                    }}
                                >
                                    { }
                                    <div className="radio-text-padding" style={{ display: 'flex' }}>
                                        {(data.ndmmtreatmentagentbridgeid !== null) && (
                                            <div>{data.agentName}</div>
                                        )}

                                        {(data.ndmmtreatmentagentbridgeid === null) && (
                                            <div>

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`} name={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`} className="custom-dropdown-button"
                                                        style={{ width: '200px' }}>
                                                        <span style={{ textWrap: "wrap", wordBreak: "break-word" }}>
                                                            {data.agentName || "Select Agent"}
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{
                                                        maxHeight: '200px',
                                                        overflowY: 'scroll',
                                                        overflowX: 'hidden',
                                                        zIndex: 9999,  // Ensures dropdown appears on top of other elements
                                                    }}>
                                                        {Object.entries(groupedAgents).map(([category, agents]) => (
                                                            <React.Fragment key={category}>
                                                                {category !== 'uncategorized' && (
                                                                    <Dropdown.ItemText className="custom-dropdown-category">
                                                                        <b>{category}</b>
                                                                    </Dropdown.ItemText>
                                                                )}
                                                                {sortAgents(agents).map(item => (
                                                                    <Dropdown.Item
                                                                        as="button"
                                                                        id={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`}
                                                                        name={`q22a_${phaseid}_${data.agentName}_Agent_${idx}`}
                                                                        key={item.agentid}
                                                                        value={item.agentid}
                                                                        className="custom-dropdown-item"
                                                                        style={{ width: "100%", marginLeft: "10px" }}
                                                                        onClick={() => { HandleDDChange(item, idx) }}
                                                                    >
                                                                        <span className="radio-text-padding">
                                                                            {item.agentName}
                                                                            {item.supscripttext && (
                                                                                <>
                                                                                    <sup className="supscript">{item.supscripttext.slice(0, 2)}</sup>
                                                                                    {item.supscripttext.slice(2)}
                                                                                </>
                                                                            )}
                                                                        </span>
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        )}
                                        {data.agentName !== null && data.agentName.includes('Other') &&
                                            (<div className="input-container">
                                                <label htmlFor={`q22a_${phaseid}_${data.agentName}_txt_${idx}`} style={{ width: '200px' }}>
                                                    <span className="radio-text-padding">
                                                        <input
                                                            className="input-dash"
                                                            id={`q22a_${phaseid}_${data.agentName}_txt_${idx}`}
                                                            name={`q22a_${phaseid}_${data.agentName}_txt_${idx}`}
                                                            style={{ width: "170px", marginLeft: '-20px' }}
                                                            type="text"
                                                            value={data.agentOtherValue}
                                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, idx, null)}
                                                        />


                                                    </span>
                                                </label>
                                            </div>)}
                                    </div>
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColAError}
                                        message={NDMMTreatmentCohort_Msgs.q22_25a}
                                    />
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColAOthError}
                                        message={NDMMTreatmentCohort_Msgs.q22_25aOth}
                                    />
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColAOthDupError}
                                        message={NDMMTreatmentCohort_Msgs.qDuplicateAgent}
                                    />
                                </td>)
                            }
                            <td
                                style={{
                                    border: "1px solid black",
                                    alignContent: "start",
                                    textAlign: "center",
                                    padding: "8px",
                                }}
                            >
                                <div className="">
                                    <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <input
                                            type="radio"
                                            id={`q22b_${phaseid}_FRadio_${data.agentName}_${idx}`}
                                            name={`q22b_${phaseid}_FRadio_${data.agentName}_${idx}`}
                                            style={{ marginTop: '6px' }}
                                            value={1}
                                            checked={data.firstDateRadio === 1}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                        />
                                        <span className="custom-calender-position radio-text-padding" style={{ paddingLeft: '5px' }}>
                                            {/* <DateControl
                                                ctrlId={`q22b_${phaseid}_FDate_${idx}`}
                                                HandleDateChange={HandleDateChange}
                                                defDate={data.firstDate}
                                                optionId={1}
                                                date={data.firstDate}
                                            /> */}

                                            <DatePickerComponent
                                                ctrlId={`q22b_${phaseid}_FDate_${idx}`}                                           
                                                HandleDateChange={HandleDateChange}                                               
                                                minDate={dayjs("01-01-1920")} 
                                                maxDate={dayjs()} 
                                                locale="en" // for dd/mm/yyyy use "en-gb"
                                                date={data.firstDate}                                            
                                            />
                                        </span>
                                    </div>
                                    <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <input
                                            type="radio"
                                            id={`q22b_${phaseid}_${data.agentName}_FUnk_${idx}`}
                                            name={`q22b_${phaseid}_${data.agentName}_FUnk_${idx}`}
                                            style={{ marginTop: '6px' }}
                                            value={1}
                                            checked={data.firstDateUnk === 1}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                        />
                                        <label htmlFor={`q22b_${phaseid}_${data.agentName}_FUnk_${idx}`} style={{ marginLeft: '-5px', textAlign: 'start' }}>
                                            <span className="radio-text-padding">
                                                Date of initiation unknown
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColBError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25b}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColB_SEFDateError}
                                    message={(agentData[idx].phaseid === 1 || agentData[idx].phaseid === 4) ? NDMMTreatmentCohort_Msgs.q22_25c_sef : agentData[idx].phaseid === 2 ? NDMMTreatmentCohort_Msgs.q22_25c_sef_p2 : NDMMTreatmentCohort_Msgs.q22_25c_sef_p3}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColB_IdxDateError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25b_idx}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColB_SFDateError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25b_sf}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColB_SEDateError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25b_se}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColBInvalidDate}
                                    message={CommonError_Msgs.InvalidDate}
                                />
                            </td>
                            <td
                                style={{
                                    border: "1px solid black",
                                    alignContent: "flex-start",
                                    padding: "8px",
                                }}
                            >
                                <div className="">
                                    <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <input
                                            type="radio"
                                            id={`q22c_${phaseid}_LRadio_${data.agentName}_${idx}`}
                                            name={`q22c_${phaseid}_LRadio_${data.agentName}_${idx}`}
                                            style={{ marginTop: '6px' }}
                                            value={1}
                                            checked={data.lastDateRadio === 1}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                        />
                                        <span className="custom-calender-position radio-text-padding" style={{ paddingLeft: '5px' }}>
                                            {/* <DateControl
                                                ctrlId={`q22c_${phaseid}_LDate_${idx}`}
                                                HandleDateChange={HandleDateChange}
                                                defDate={data.lastDate}
                                                optionId={1}
                                                date={data.lastDate}
                                            /> */}
                                            <DatePickerComponent
                                                 ctrlId={`q22c_${phaseid}_LDate_${idx}`}                                           
                                                HandleDateChange={HandleDateChange}                                               
                                                minDate={dayjs("01-01-1920")} 
                                                maxDate={dayjs()} 
                                                locale="en" // for dd/mm/yyyy use "en-gb"
                                                date={data.lastDate}                                            
                                            />
                                        </span>
                                    </div>
                                    <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <input
                                            type="radio"
                                            id={`q22c_${phaseid}_${data.agentName}_LUnk_${idx}`}
                                            name={`q22c_${phaseid}_${data.agentName}_LUnk_${idx}`}
                                            style={{ marginTop: '6px' }}
                                            value={1}
                                            checked={data.lastDateUnk === 1}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                        />
                                        <label htmlFor={`q22c_${phaseid}_${data.agentName}_LUnk_${idx}`} style={{ marginLeft: '-5px', textAlign: 'start' }}>
                                            <span className="radio-text-padding">
                                                Date of last administration unknown
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColCError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25c}
                                />
                                  <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColCInvalidDate}
                                    message={CommonError_Msgs.InvalidDate}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColC_EFDateError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25c_ef}
                                />
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColC_SEFDateError}
                                    message={(agentData[idx].phaseid === 1 || agentData[idx].phaseid === 4) ? NDMMTreatmentCohort_Msgs.q22_25c_sef : agentData[idx].phaseid === 2 ? NDMMTreatmentCohort_Msgs.q22_25c_sef_p2 : NDMMTreatmentCohort_Msgs.q22_25c_sef_p3}
                                />
                            </td>
                            <td
                                style={{
                                    border: "1px solid black",
                                    alignContent: "flex-start",
                                    padding: "8px",
                                }}
                            >
                                <div className="option-fbox">
                                    <label htmlFor={`q22d_${phaseid}_${data.agentName}_Noa_${idx}`}>
                                        <input type="radio"
                                            id={`q22d_${phaseid}_${data.agentName}_Noa_${idx}`}
                                            name={`q22d_${phaseid}_${data.agentName}_Noa_${idx}`}
                                            value={1}
                                            checked={
                                                data.noOfAdmissionRadio === 1
                                            }
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                        />
                                    </label>
                                    <label htmlFor={`q22d_${phaseid}_${data.agentName}_Noatxt_${idx}`}>
                                        <span className="radio-text-padding">
                                            <input
                                                className="input-dash"
                                                id={`q22d_${phaseid}_${data.agentName}_Noatxt_${idx}`}
                                                name={`q22d_${phaseid}_${data.agentName}_Noatxt_${idx}`}
                                                type="text"
                                                value={data.noOfAdmission}
                                                min={1}
                                                max={4000}
                                                //maxLength={3}
                                                pattern="\d*"
                                                onKeyDown={(e) => {
                                                    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
                                        
                                                    if (
                                                        !allowedKeys.includes(e.key) &&
                                                        (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key)))
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                
                                              
                                                onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                            />
                                        </span>
                                    </label>
                                    {/* <label><span className="radio-text-padding" style={{ marginLeft: '15px', textAlign: 'start' }}>(Range: 1 to 550)</span></label> */}
                                </div>

                                <div className="">
                                    <input
                                        type="radio"
                                        id={`q22d_${phaseid}_${data.agentName}_NoaUnk_${idx}`}
                                        name={`q22d_${phaseid}_${data.agentName}_NoaUnk_${idx}`}
                                        value={1}
                                        checked={data.noOfAdmissionUnk === 1}
                                        onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                    />
                                    <label htmlFor={`q22d_${phaseid}_${data.agentName}_NoaUnk_${idx}`}>
                                        <span className="radio-text-padding">
                                            Unknown
                                        </span>
                                    </label>
                                </div>
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColDError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25d}
                                />
                            </td>

                            {(phaseid === 1 || phaseid === 4) &&
                                <td
                                    style={{
                                        border: "1px solid black",
                                        alignContent: "flex-start",
                                        padding: "8px",
                                    }}
                                >
                                    <div className="option-fbox">
                                        <label htmlFor={`q22g_${phaseid}_${data.agentName}_mg_${idx}`}>
                                            <input type="radio"
                                                id={`q22g_${phaseid}_${data.agentName}_mg_${idx}`}
                                                name={`q22g_${phaseid}_${data.agentName}_mg_${idx}`}
                                                value={1}
                                                checked={
                                                    data.doseAdministrationRadiomg === 1
                                                }
                                                onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                            />
                                        </label>
                                        <label htmlFor={`q22g_${phaseid}_${data.agentName}_mgtxt_${idx}`}>
                                            <span className="radio-text-padding">
                                                <input
                                                    className="input-dash-custom"
                                                    id={`q22g_${phaseid}_${data.agentName}_mgtxt_${idx}`}
                                                    name={`q22g_${phaseid}_${data.agentName}_mgtxt_${idx}`}
                                                    type="text"
                                                    value={data.doseAdministrationmg}
                                                    min={0}
                                                    max={4000}
                                                    //maxLength={3}
                                                    //pattern="\d*"
                                                    onKeyDown={(e) => {
                                                        // Allow backspace, arrow keys, delete, and tab
                                                        if (["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"].includes(e.key)) {
                                                            return;
                                                        }
                                                        // Prevent non-numeric characters, except '.' (and only allow one decimal point)
                                                        if (!/[0-9.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        const value = e.target.value;
                                                        const selectionStart = e.target.selectionStart;
                                                        const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                        // Prevent '.' as the first character
                                                        if (e.key === '.' && value === '') {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        // Allow only one digit after decimal point
                                                        if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                            e.preventDefault();
                                                        }
                                                    }}


                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                                />
                                            </span>&nbsp; mg
                                        </label>
                                    </div>
                                    <br />
                                    <div className="option-fbox">
                                        <label htmlFor={`q22g_${phaseid}_${data.agentName}_kg_${idx}`}>
                                            <input type="radio"
                                                id={`q22g_${phaseid}_${data.agentName}_kg_${idx}`}
                                                name={`q22g_${phaseid}_${data.agentName}_kg_${idx}`}
                                                value={1}
                                                checked={
                                                    data.doseAdministrationRadiokg === 1
                                                }
                                                onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                            />
                                        </label>
                                        <label htmlFor={`q22g_${phaseid}_${data.agentName}_kgtxt_${idx}`}>
                                            <span className="radio-text-padding">
                                                <input
                                                    className="input-dash-custom"
                                                    id={`q22g_${phaseid}_${data.agentName}_kgtxt_${idx}`}
                                                    name={`q22g_${phaseid}_${data.agentName}_kgtxt_${idx}`}
                                                    type="text"
                                                    value={data.doseAdministrationkg}
                                                    min={0}
                                                    max={4000}
                                                    //maxLength={3}
                                                    //pattern="\d*"
                                                    onKeyDown={(e) => {
                                                        // Allow backspace, arrow keys, delete, and tab
                                                        if (["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"].includes(e.key)) {
                                                            return;
                                                        }
                                                        // Prevent non-numeric characters, except '.' (and only allow one decimal point)
                                                        if (!/[0-9.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        const value = e.target.value;
                                                        const selectionStart = e.target.selectionStart;
                                                        const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                        // Prevent '.' as the first character
                                                        if (e.key === '.' && value === '') {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        // Allow only one digit after decimal point
                                                        if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                            e.preventDefault();
                                                        }
                                                    }}


                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                                />
                                            </span> &nbsp; mg/kg
                                        </label>
                                    </div>
                                    <br />
                                    <div className="option-fbox">
                                        <label htmlFor={`q22g_${phaseid}_${data.agentName}_mgm_${idx}`}>
                                            <input type="radio"
                                                id={`q22g_${phaseid}_${data.agentName}_mgm_${idx}`}
                                                name={`q22g_${phaseid}_${data.agentName}_mgm_${idx}`}
                                                value={1}
                                                checked={
                                                    data.doseAdministrationRadiomgm === 1
                                                }
                                                onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                            />
                                        </label>
                                        <label htmlFor={`q22g_${phaseid}_${data.agentName}_mgmtxt_${idx}`}>
                                            <span className="radio-text-padding">
                                                <input
                                                    className="input-dash-custom"
                                                    id={`q22g_${phaseid}_${data.agentName}_mgmtxt_${idx}`}
                                                    name={`q22g_${phaseid}_${data.agentName}_mgmtxt_${idx}`}
                                                    type="text"
                                                    value={data.doseAdministrationmgm}
                                                    min={0}
                                                    max={4000}
                                                    //maxLength={3}
                                                    //pattern="\d*"
                                                    onKeyDown={(e) => {
                                                        // Allow backspace, arrow keys, delete, and tab
                                                        if (["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"].includes(e.key)) {
                                                            return;
                                                        }
                                                        // Prevent non-numeric characters, except '.' (and only allow one decimal point)
                                                        if (!/[0-9.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        const value = e.target.value;
                                                        const selectionStart = e.target.selectionStart;
                                                        const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                        // Prevent '.' as the first character
                                                        if (e.key === '.' && value === '') {
                                                            e.preventDefault();
                                                            return;
                                                        }
                                                        // Allow only one digit after decimal point
                                                        if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                            e.preventDefault();
                                                        }
                                                    }}


                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                                />
                                            </span> &nbsp; mg/m<sup>2</sup>
                                        </label>
                                    </div>
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColGError}
                                        message={NDMMTreatmentCohort_Msgs.q22_25g}
                                    />
                                </td>
                            }









                            <td
                                style={{
                                    border: "1px solid black",
                                    alignContent: "flex-start",
                                    padding: "8px",
                                }}
                            >
                                <div className="">
                                    <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <input
                                            type="radio"
                                            id={`q22e_${phaseid}_${data.agentName}_PdaYes_${idx}`}
                                            name={`q22e_${phaseid}_${data.agentName}_PdaYes_${idx}`}
                                            value={1}
                                            checked={data.discontinueAgent === 1}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                            style={{ marginTop: '6px' }}
                                        />
                                        <label htmlFor={`q22e_${phaseid}_${data.agentName}_PdaYes_${idx}`} style={{ textAlign: 'start' }}>
                                            <span className="radio-text-padding">
                                                Yes, agent discontinued
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <input
                                            type="radio"
                                            id={`q22e_${phaseid}_${data.agentName}_PdaNo_${idx}`}
                                            name={`q22e_${phaseid}_${data.agentName}_PdaNo_${idx}`}
                                            value={2}
                                            checked={data.discontinueAgent === 2}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                            style={{ marginTop: '6px' }}
                                        />
                                        <label htmlFor={`q22e_${phaseid}_${data.agentName}_PdaNo_${idx}`} style={{ textAlign: 'start' }}>
                                            <span className="radio-text-padding">
                                                No, medication ongoing at time of last encounter
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="option-without-input">
                                        <input
                                            type="radio"
                                            id={`q22e_${phaseid}_${data.agentName}_PdaUnk_${idx}`}
                                            name={`q22e_${phaseid}_${data.agentName}_PdaUnk_${idx}`}
                                            value={99}
                                            checked={data.discontinueAgent === 99}
                                            onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, null, null)}
                                        />
                                        <label htmlFor={`q22e_${phaseid}_${data.agentName}_PdaUnk_${idx}`}>
                                            <span className="radio-text-padding">
                                                Unknown
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <ErrorField style={{ padding: '0px' }}
                                    show={agentData[idx].showColEError}
                                    message={NDMMTreatmentCohort_Msgs.q22_25e}
                                />
                            </td>
                            {agentData[idx].showColF ? (
                                <td
                                    style={{
                                        border: "1px solid black",
                                        alignContent: "flex-start",
                                        textAlign: "center",
                                        padding: "8px",
                                        textAlign: 'start'
                                    }}
                                >
                                    <div className="">
                                        {data.reasonForDiscontinuingAgents?.map((item, itemidx) => (
                                            <div className="" key={itemidx}>
                                                <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                    <input type="checkbox"
                                                        key={item.reasonid}
                                                        id={`discont_${phaseid}_${data.agentName}_${item.reasonName + item.supscripttext}_${itemidx}`}
                                                        name={`discont_${phaseid}_${data.agentName}_${item.reasonName + item.supscripttext}_${itemidx}`}
                                                        style={{ marginTop: '6px' }}
                                                        checked={
                                                            item.isSelected
                                                        }
                                                        onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, item.reasonName + item.supscripttext, idx, itemidx)}
                                                    />
                                                    <label htmlFor={`discont_${phaseid}_${data.agentName}_${item.reasonName + item.supscripttext}_${itemidx}`}>
                                                        <span className="radio-text-padding">
                                                            {item.reasonName}
                                                            {item.supscripttext === null ? (
                                                                ''
                                                            ) : (
                                                                <>
                                                                    <sup className="supscript">{item.supscripttext.slice(0, 2)}</sup>
                                                                    {item.supscripttext.slice(2)}
                                                                </>
                                                            )}
                                                        </span>
                                                    </label>

                                                </div>
                                                {item.reasonName.includes('Other') &&
                                                    (<label htmlFor={`discont_${phaseid}_${data.agentName}_${item.reasonName}txt_${itemidx}`}>
                                                        <span className="radio-text-padding" >
                                                            {/* <input
                                                                    className="input-dash"
                                                                    id={`discont_${phaseid}_${data.agentName}_${item.reasonName}txt_${itemidx}`}
                                                                    name={`discont_${phaseid}_${data.agentName}_${item.reasonName}txt_${itemidx}`}
                                                                    type="text"
                                                                    value={item.reasonText}
                                                                    onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, item.reasonName + item.supscripttext, idx, itemidx)}
                                                                /> */}
                                                            <textarea
                                                                //className="input-dash"
                                                                style={{ width: "264px" }}
                                                                id={`discont_${phaseid}_${data.agentName}_${item.reasonName}txt_${itemidx}`}
                                                                name={`discont_${phaseid}_${data.agentName}_${item.reasonName}txt_${itemidx}`}
                                                                type="text"
                                                                value={item.reasonText}
                                                                onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, item.reasonName + item.supscripttext, idx, itemidx)}
                                                            />
                                                        </span>
                                                    </label>)}

                                            </div>
                                        ))}
                                        <div className="">
                                            <input
                                                type="radio"
                                                id={`discont_unk_${phaseid}_${data.agentName}_${idx}`}
                                                name={`discont_unk_${phaseid}_${data.agentName}_${idx}`}
                                                value={1}
                                                checked={data.reasonForDiscontinuingAgents[0].reasonUnk ? 1 : ""}
                                                onChange={(e) => HandleRepeaterFieldChange(e, data.agentName, null, idx, null)}
                                            />
                                            <label htmlFor={`discont_unk_${phaseid}_${data.agentName}_${idx}`} >
                                                <span className="radio-text-padding">Unknown</span></label>
                                        </div>
                                    </div>
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColFError}
                                        message={NDMMTreatmentCohort_Msgs.q22_25f}
                                    />
                                    <ErrorField style={{ padding: '0px' }}
                                        show={agentData[idx].showColFOthError}
                                        message={NDMMTreatmentCohort_Msgs.q22_25fa}
                                    />
                                </td>) : (<td
                                    style={{
                                        border: "1px solid black",
                                        //alignContent: "flex-start",
                                        textAlign: "center",
                                        padding: "8px",
                                        // textAlign: 'start'
                                    }}
                                >
                                    <span className="not-applicable" style={{ textAlign: "center" }}>Not Applicable </span>
                                </td>)}

                            {agentData[idx].defaultAgent ? (
                                <td
                                    style={{
                                        border: "1px solid black",
                                        alignContent: "flex-start",
                                        textAlign: "center",
                                        padding: "8px",
                                        textAlign: 'start'
                                    }}
                                >
                                    <div style={{ width: '18px' }} />
                                    {/* Do not show delete icon as this is default rows basis the ndmm selection */}
                                </td>) : (<td
                                    style={{
                                        border: "1px solid black",
                                        alignContent: "flex-start",
                                        textAlign: "center",
                                        padding: "8px",
                                        textAlign: 'start'
                                    }}
                                >
                                    <img
                                        width="18px"
                                        src="../Assets/images/Icon-material-delete.png"
                                        alt=""
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => OnDeleteRow(agentData[idx], idx)}
                                    />
                                </td>)}
                        </tr>
                    ))}

                    {/* HyperLink to add more */}
                    {showAddMore &&
                        (<tr>
                            <div className="" style={{ display: 'flex', alignItems: 'flex-start', margin: '8px', width: 'max-content' }}>
                                <div className="answer-list-text">
                                    <a>
                                        <img
                                            width="18px"
                                            onClick={addAgentRow}
                                            src="../Assets/images/plus.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        className="radio-text-padding"
                                        onClick={addAgentRow}
                                    >
                                        Click to enter another agent
                                    </a>
                                </div>
                            </div>
                        </tr>)}
                </tbody>
            </table>
        </div>
    );
};

export default NDMMAgentData;
