import WebServiceWrapper from "./WebServiceWrapper";

export const PatientService = {
  AddNewPatient,
  GetAllPatients,
  SavePatient,
  LoadPatient,
  IsEligiblePatient,
  GetPatientConfigData,
  LockPatient,
  IsLocked,
  SetPatientSubmitValue,
  UnlockPatient,
  GetPatientDataToExport,
  SaveZipFile,
  DownloadDataDictionary
};

function AddNewPatient(payload) {
  return WebServiceWrapper.Post("/patient/AddNewPatient", payload);
}
function GetAllPatients(centerId) {
  return WebServiceWrapper.Get(`/patient/GetAllPatients?centerId=${centerId}`);
}
function SavePatient(payload) {
  return WebServiceWrapper.Post("/patient/SavePatient", payload);
}
function LoadPatient(pid) {
  return WebServiceWrapper.Get(`/patient/LoadPatient?pid=${pid}`);
}
function IsEligiblePatient(pid) {
  return WebServiceWrapper.Get(`/patient/IsPatientEligible?patientId=${pid}`);
}
function GetPatientConfigData(pid) {
  return WebServiceWrapper.Get(`/patient/OnValidateSubmitClick?patientId=${pid}`);
}
function LockPatient(payload) {
  return WebServiceWrapper.Post("/patient/LockPatient", payload);
}
function IsLocked(pid) {
  return WebServiceWrapper.Get(`/patient/IsLocked?patientId=${pid}`);
}
function SetPatientSubmitValue(payload) {
  return WebServiceWrapper.Post("/patient/SetPatientSubmitValue", payload);
}
function UnlockPatient(payload) {
  return WebServiceWrapper.Post("/patient/UnlockPatient", payload);
}
function GetPatientDataToExport(pid, exportType, userId, centerId) {
    return WebServiceWrapper.Get(`/patient/GetPatientDataToExport?patientId=${pid}&exportType=${exportType}&userId=${userId}&centerId=${centerId}`);
}

function SaveZipFile(payload) {
  return WebServiceWrapper.PostWithHeader("/patient/SaveZipFile", payload, undefined, { "Content-Type": "multipart/form-data", });
}
function DownloadDataDictionary() {
  return WebServiceWrapper.Get("/patient/DownloadDataDictionary");
}