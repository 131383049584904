import React, { useContext, useEffect, useState, useRef } from 'react'
import PatientDetails from '../../Components/PatientInformation';
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessageType, ToastMessages } from '../../Helpers../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import { Dropdown } from 'react-bootstrap';
import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs, demographicScreen_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import { GetLocalStorageData, GetLoggedInUserID, isStringEmpty, AllowNumberWithOneDecimal, AllowOnlyIntegers, AllowOnlyTwoDecimal, AllowOnlyOneDecimal, validateMeasurementDate, DateInRange } from "../../Helpers/Utilities";
import { DemographicService } from "../../WebApiServices/Demographic.service";
import DateControl from "../../Components/DateControl";
import DatePickerComponent from '../../Components/DatePickerComponent';
import dayjs from 'dayjs';

const Demographics = () => {
    const navigatePaths = {
        prevPage: "/TransitionToChartAbstraction",
        currPage: "/Demographics",
        nextPage: "/DiseaseHistory"
    }

    const inputRef = useRef(null);
    const pid = GetLocalStorageData("patientId") ?? 0;
    const { ShowToast, ToggleLoader, HandleSessionTimeout,isSideMenuActive,setIsSideMenuActive } = useContext(AppContext);

    // DN 15/7/24 Initial load
    useEffect(() => {
        async function FetchData() {
            LoadUSStates(); // Fetch all US states from table 'USState'
            if (!isNaN(pid) && Number(pid) !== 0) {
                ToggleLoader(true);
                await LoadDemographicData();// Fetch all demographic data of the patient
                ToggleLoader(false);
            }
        }
        FetchData();
    }, [])

    useEffect(() => {
        const handleWheel = (event) => {
            if (inputRef.current && inputRef.current.contains(event.target)) {
                event.preventDefault();
                event.stopPropagation(); // Stop the event from bubbling up
            }
        };

        const inputElement = inputRef.current;

        inputElement.addEventListener('wheel', handleWheel, { passive: false });
        inputElement.addEventListener('mousewheel', handleWheel, { passive: false }); // For older browsers

        return () => {
            inputElement.removeEventListener('wheel', handleWheel);
            inputElement.removeEventListener('mousewheel', handleWheel);
        };
    }, []);

    //DN 16/7/2024 Bind demographics data
    const [ptDemographicsDetails, setPtDemographicsDetails] = useState({
        userId: GetLoggedInUserID(),
        gender: null,
        raceAmericanindian: null,
        raceAsian: null,
        raceBlack: null,
        raceNativeamerican: null,
        raceWhite: null,
        raceOth: null,
        raceUnk: null,
        ethnicity: null,
        states: [],
    });

    //DN 16/7/2024 Bind repeater
    const [repeaterQ4ToQ10, setRepeaterQ4ToQ10] = useState(
        [{
            cohorttreatmentid: null,
            cohorttreatmentDisplayName: null,
            cohorttreatmentSupScript: null,
            cohorttreatmentDisplayDate: null,
            cohorttreatmentDisplayDateMinus365: null,

            stateRadio: null,
            stateid: null,
            stateName: "Select State",
            stateUnk: null,
            q4Empty: false,

            insuranceCommercial: false,
            insuranceMedicare: false,
            insuranceMedicaid: false,
            insuranceMilitary: false,
            insuranceOth: false,
            insuranceNone: null,
            insuranceUnk: null,
            q5Empty: false,

            ecogStatus: null,
            q6Empty: false,

            imwgStatus: null,
            q7Empty: false,

            covidVaccine: null,
            q8Empty: false,

            egfrMesurementEqualsRadio: null,
            egfrMesurementEquals: null,
            egfrMesurementGreaterthanRadio: null,
            egfrMesurementGreaterthan: null,
            egfrMesurementLessthanRadio: null,
            egfrMesurementLessthan: null,
            egfrMesurementUnk: null,
            q9Empty: false,
            q9aEmpty: false,

            weightDateRadio: null,
            weightDate: null,
            weightNotassessed: null,
            weightValueRadio: null,
            weightValueKg: null,
            weightValueLbs: null,
            //weightUnk: null,
            //weightUnit: null,
            heightDate: null,
            heightDateRadio: null,
            heightNotassessed: null,
            heightValueRadio: null,
            heightValueFt: null,
            heightValueInch: null,
            heightValueCm: null,
            //heightUnk: null,
            //heightUnit: null,

            showWMeasurement: false,
            //showWUnit: false,
            showHMeasurement: false,
            //showHUnit: false,

            q10aWEmpty: false,
            q10aWDInvalid: false,
            q10bWEmpty: false,
            q10aWDateInvalid:false,
            

            //q10cWEmpty: false,
            q10aHEmpty: false,
            q10aHDInvalid: false,
            q10bHEmpty: false,
            q10aHDateInvalid:false,
            //q10cHEmpty: false,
        }]
    );

    //DN 17/7/2024 Handle error messages to be shown on change of selection [Dynamic data Q4-Q10]
    const HandleRepeaterFieldChange = (e) => {
        let { name, id, value } = e.target;
        let updatedArray = [...repeaterQ4ToQ10];
        const index = id.split('_').pop(); // Extract the index from the id


        const numericValue = Number(value);


        switch (name) {
            case `q4_StateRadio_${index}`:
                updatedArray[index].stateRadio = numericValue;
                updatedArray[index].stateName = "Select State";
                updatedArray[index].stateUnk = "";
                updatedArray[index].stateid = null;
                updatedArray[index].q4Empty = false;
                break;
            case `q4_Unk_${index}`:
                updatedArray[index].stateUnk = numericValue;
                updatedArray[index].stateName = "Select State";
                updatedArray[index].stateRadio = "";
                updatedArray[index].stateid = null;
                updatedArray[index].q4Empty = false;
                break;
            case `q5_C_${index}`:
                updatedArray[index].insuranceCommercial = !updatedArray[index].insuranceCommercial;
                updatedArray[index].insuranceNone = "";
                updatedArray[index].insuranceUnk = "";
                updatedArray[index].q5Empty = false;
                break;
            case `q5_Mcr_${index}`:
                updatedArray[index].insuranceMedicare = !updatedArray[index].insuranceMedicare;
                updatedArray[index].insuranceNone = "";
                updatedArray[index].insuranceUnk = "";
                updatedArray[index].q5Empty = false;
                break;
            case `q5_Mcd_${index}`:
                updatedArray[index].insuranceMedicaid = !updatedArray[index].insuranceMedicaid;
                updatedArray[index].insuranceNone = "";
                updatedArray[index].insuranceUnk = "";
                updatedArray[index].q5Empty = false;
                break;
            case `q5_Mlt_${index}`:
                updatedArray[index].insuranceMilitary = !updatedArray[index].insuranceMilitary;
                updatedArray[index].insuranceNone = "";
                updatedArray[index].insuranceUnk = "";
                updatedArray[index].q5Empty = false;
                break;
            case `q5_O_${index}`:
                updatedArray[index].insuranceOth = !updatedArray[index].insuranceOth;
                updatedArray[index].insuranceNone = "";
                updatedArray[index].insuranceUnk = "";
                updatedArray[index].q5Empty = false;
                break;
            case `q5_NI_${index}`:
                updatedArray[index].insuranceCommercial = false;
                updatedArray[index].insuranceMedicare = false;
                updatedArray[index].insuranceMedicaid = false;
                updatedArray[index].insuranceMilitary = false;
                updatedArray[index].insuranceOth = false;
                updatedArray[index].insuranceNone = numericValue;
                updatedArray[index].insuranceUnk = false;
                updatedArray[index].q5Empty = false;
                break;
            case `q5_Unk_${index}`:
                updatedArray[index].insuranceCommercial = false;
                updatedArray[index].insuranceMedicare = false;
                updatedArray[index].insuranceMedicaid = false;
                updatedArray[index].insuranceMilitary = false;
                updatedArray[index].insuranceOth = false;
                updatedArray[index].insuranceNone = false;
                updatedArray[index].insuranceUnk = numericValue;
                updatedArray[index].q5Empty = false;
                break;
            case `q6_option1_${index}`:
            case `q6_option2_${index}`:
            case `q6_option3_${index}`:
            case `q6_option4_${index}`:
            case `q6_option5_${index}`:
            case `q6_option99_${index}`:
                updatedArray[index].ecogStatus = numericValue;
                updatedArray[index].q6Empty = false;
                break;
            case `q7_option1_${index}`:
            case `q7_option2_${index}`:
            case `q7_option3_${index}`:
            case `q7_option99_${index}`:
                updatedArray[index].imwgStatus = numericValue;
                updatedArray[index].q7Empty = false;
                break;
            case `q8_option1_${index}`:
            case `q8_option2_${index}`:
            case `q8_option99_${index}`:
                updatedArray[index].covidVaccine = numericValue;
                updatedArray[index].q8Empty = false;
                break;
            case `q9_E_${index}`:
                updatedArray[index].egfrMesurementEqualsRadio = numericValue;
                updatedArray[index].egfrMesurementEquals = null;
                updatedArray[index].egfrMesurementGreaterthanRadio = null;
                updatedArray[index].egfrMesurementGreaterthan = "";
                updatedArray[index].egfrMesurementLessthanRadio = null;
                updatedArray[index].egfrMesurementLessthan = "";
                updatedArray[index].egfrMesurementUnk = "";
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q9_Etxt_${index}`:
                if (value !== "" && AllowNumberWithOneDecimal(value)) {
                    let parsedValue = parseFloat(value);

                    if (!isNaN(parsedValue) && value.includes('.')) {
                        parsedValue = AllowOnlyOneDecimal(value)
                    }

                    updatedArray[index].egfrMesurementEquals = parsedValue;
                }
                else
                    updatedArray[index].egfrMesurementEquals = null;

                updatedArray[index].egfrMesurementEqualsRadio = 1;
                updatedArray[index].egfrMesurementGreaterthanRadio = null;
                updatedArray[index].egfrMesurementGreaterthan = "";
                updatedArray[index].egfrMesurementLessthanRadio = null;
                updatedArray[index].egfrMesurementLessthan = "";
                updatedArray[index].egfrMesurementUnk = "";
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q9_GT_${index}`:
                updatedArray[index].egfrMesurementGreaterthanRadio = numericValue;
                updatedArray[index].egfrMesurementGreaterthan = null;
                updatedArray[index].egfrMesurementLessthanRadio = null;
                updatedArray[index].egfrMesurementLessthan = "";
                updatedArray[index].egfrMesurementEqualsRadio = null;
                updatedArray[index].egfrMesurementEquals = "";
                updatedArray[index].egfrMesurementUnk = "";
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q9_GTtxt_${index}`:
                if (value !== "" && AllowNumberWithOneDecimal(value)) {
                    let parsedValue = parseFloat(value);

                    if (!isNaN(parsedValue) && value.includes('.')) {
                        parsedValue = AllowOnlyOneDecimal(value)
                    }

                    updatedArray[index].egfrMesurementGreaterthan = parsedValue;
                }
                else
                    updatedArray[index].egfrMesurementGreaterthan = null;

                updatedArray[index].egfrMesurementGreaterthanRadio = 1;
                updatedArray[index].egfrMesurementLessthanRadio = null;
                updatedArray[index].egfrMesurementLessthan = "";
                updatedArray[index].egfrMesurementEqualsRadio = null;
                updatedArray[index].egfrMesurementEquals = "";
                updatedArray[index].egfrMesurementUnk = "";
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q9_LT_${index}`:
                updatedArray[index].egfrMesurementLessthanRadio = numericValue;
                updatedArray[index].egfrMesurementLessthan = null;
                updatedArray[index].egfrMesurementEqualsRadio = null;
                updatedArray[index].egfrMesurementEquals = "";
                updatedArray[index].egfrMesurementGreaterthanRadio = null;
                updatedArray[index].egfrMesurementGreaterthan = "";
                updatedArray[index].egfrMesurementUnk = "";
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q9_LTtxt_${index}`:
                if (value !== "" && AllowNumberWithOneDecimal(value)) {
                    let parsedValue = parseFloat(value);

                    if (!isNaN(parsedValue) && value.includes('.')) {
                        parsedValue = AllowOnlyOneDecimal(value)
                    }
                    updatedArray[index].egfrMesurementLessthan = parsedValue;
                }
                else
                    updatedArray[index].egfrMesurementLessthan = null;

                updatedArray[index].egfrMesurementLessthanRadio = 1;
                updatedArray[index].egfrMesurementEqualsRadio = null;
                updatedArray[index].egfrMesurementEquals = "";
                updatedArray[index].egfrMesurementGreaterthanRadio = null;
                updatedArray[index].egfrMesurementGreaterthan = "";
                updatedArray[index].egfrMesurementUnk = "";
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q9_Unk_${index}`:
                updatedArray[index].egfrMesurementEqualsRadio = null;
                updatedArray[index].egfrMesurementGreaterthanRadio = null;
                updatedArray[index].egfrMesurementLessthanRadio = null;
                updatedArray[index].egfrMesurementEquals = "";
                updatedArray[index].egfrMesurementGreaterthan = "";
                updatedArray[index].egfrMesurementLessthan = "";
                updatedArray[index].egfrMesurementUnk = numericValue;
                updatedArray[index].q9Empty = false;
                updatedArray[index].q9aEmpty = false;
                break;
            case `q10_WDateRadio_${index}`:
                updatedArray[index].weightDateRadio = numericValue;
                updatedArray[index].weightDate = null;
                updatedArray[index].weightNotassessed = "";
                updatedArray[index].weightValueRadio = "";
                updatedArray[index].weightValueKg = "";
                updatedArray[index].weightValueLbs = "";
                updatedArray[index].showWMeasurement = true;
                updatedArray[index].q10aWEmpty = false;
                updatedArray[index].q10aWDInvalid = false;
                updatedArray[index].q10aWDateInvalid = false;
                updatedArray[index].q10bWEmpty = false;
                break;
            case `q10_WDateNA_${index}`:
                updatedArray[index].weightDateRadio = null;
                updatedArray[index].weightDate = null;
                updatedArray[index].weightNotassessed = numericValue;
                updatedArray[index].weightValueRadio = "";
                updatedArray[index].weightValueKg = "";
                updatedArray[index].weightValueLbs = "";
                updatedArray[index].showWMeasurement = false;
                updatedArray[index].q10aWEmpty = false;
                updatedArray[index].q10aWDInvalid = false;
                updatedArray[index].q10aWDateInvalid = false;
                updatedArray[index].q10bWEmpty = false;
                break;
            case `q10_WMesKg_${index}`:
            case `q10_WMesLbs_${index}`:
                updatedArray[index].weightValueRadio = numericValue;
                updatedArray[index].weightValueKg = "";
                updatedArray[index].weightValueLbs = "";
                updatedArray[index].q10bWEmpty = false;
                break;
            case `q10_WMesKgtxt_${index}`:
                let parsedValueWkg = parseFloat(value);
                if (value !== "" && AllowNumberWithOneDecimal(value)) {
                    if (!isNaN(parsedValueWkg) && value.includes('.')) {
                        parsedValueWkg = AllowOnlyTwoDecimal(value)
                    }
                    updatedArray[index].weightValueKg = parsedValueWkg;
                }
                else {
                    updatedArray[index].weightValueKg = null;
                }

                updatedArray[index].weightValueRadio = 1;
                updatedArray[index].weightValueLbs = "";
                updatedArray[index].q10bWEmpty = false;
                break;
            case `q10_WMesLbstxt_${index}`:
                let parsedValueWLbs = parseFloat(value);
                if (value !== "" && AllowNumberWithOneDecimal(value)) {
                    if (!isNaN(parsedValueWLbs) && value.includes('.')) {
                        parsedValueWLbs = AllowOnlyTwoDecimal(value)
                    }
                    updatedArray[index].weightValueLbs = parsedValueWLbs;
                }
                else {
                    updatedArray[index].weightValueKg = null;
                    updatedArray[index].weightValueLbs = null;
                }

                updatedArray[index].weightValueRadio = 2;
                updatedArray[index].weightValueKg = "";
                updatedArray[index].q10bWEmpty = false;
                break;
            case `q10_WMesUnk_${index}`:
                updatedArray[index].weightValueRadio = 99;
                updatedArray[index].weightValueKg = "";
                updatedArray[index].weightValueLbs = "";
                updatedArray[index].q10bWEmpty = false;
                break;
            case `q10_HDateRadio_${index}`:
                updatedArray[index].heightDateRadio = numericValue;
                updatedArray[index].heightDate = null;
                updatedArray[index].heightNotassessed = "";
                updatedArray[index].heightValueRadio = "";
                updatedArray[index].heightValueFt = "";
                updatedArray[index].heightValueInch = "";
                updatedArray[index].heightValueCm = "";
                updatedArray[index].showHMeasurement = true;
                updatedArray[index].q10aHEmpty = false;
                updatedArray[index].q10aHDInvalid = false;
                updatedArray[index].q10bHEmpty = false;
                updatedArray[index].q10aHDateInvalid = false;
                break;
            case `q10_HDateNA_${index}`:
                updatedArray[index].heightDateRadio = null;
                updatedArray[index].heightDate = null;
                updatedArray[index].heightNotassessed = numericValue;
                updatedArray[index].heightValueRadio = "";
                updatedArray[index].heightValueFt = "";
                updatedArray[index].heightValueInch = "";
                updatedArray[index].heightValueCm = "";
                updatedArray[index].showHMeasurement = false;
                updatedArray[index].q10aHEmpty = false;
                updatedArray[index].q10aHDInvalid = false;
                updatedArray[index].q10bHEmpty = false;
                updatedArray[index].q10aHDateInvalid = false;
                break;
            case `q10_HMesC_${index}`:
            case `q10_HMesFtI_${index}`:
                updatedArray[index].heightValueRadio = numericValue;
                updatedArray[index].heightValueFt = "";
                updatedArray[index].heightValueInch = "";
                updatedArray[index].heightValueCm = "";
                updatedArray[index].q10bHEmpty = false;
                break;
            case `q10_HMesFttxt_${index}`:
                let parsedValueFt = !isStringEmpty(value) ? parseFloat(value) : null;
                updatedArray[index].heightValueFt = parsedValueFt;
                updatedArray[index].heightValueRadio = 1;
                updatedArray[index].heightValueCm = "";
                updatedArray[index].q10bHEmpty = false;
                break;
            case `q10_HMesItxt_${index}`:
                let parsedValueInch = !isStringEmpty(value) ? parseFloat(value) : null;
                updatedArray[index].heightValueInch = parsedValueInch;
                updatedArray[index].heightValueRadio = 1;
                updatedArray[index].heightValueCm = "";
                updatedArray[index].q10bHEmpty = false;
                break;
            case `q10_HMesCtxt_${index}`:
                let parsedValueCm = !isStringEmpty(value) ? parseFloat(value) : null;
                updatedArray[index].heightValueCm = parsedValueCm;
                updatedArray[index].heightValueRadio = 2;
                updatedArray[index].heightValueFt = "";
                updatedArray[index].heightValueInch = "";
                updatedArray[index].q10bHEmpty = false;
                break;
            case `q10_HMesUnk_${index}`:
                updatedArray[index].heightValueRadio = 99;
                updatedArray[index].heightValueFt = "";
                updatedArray[index].heightValueInch = "";
                updatedArray[index].heightValueCm = "";
                updatedArray[index].q10bHEmpty = false;
                updatedArray[index].q10bHEmpty = false;
                updatedArray[index].q10bHEmpty = false;
                break;
            default:
                break;
        }

        setRepeaterQ4ToQ10(updatedArray);
    };

    ///DN 15/7/24 Get all States from table 'USState'
    async function LoadUSStates() {
        const response = await DemographicService.GetUSStates();
        if (response?.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized)
                HandleSessionTimeout();
            else if (response?.status === HTTPResponse.BadRequest)
                throw response.error;
        } else {
            const { data } = response;
            setPtDemographicsDetails((prev) => ({
                ...prev,
                states: data,
            }));
        }
    }

    //DN 16/7/24 Fetch all demographic data of the patient basis the pid and bind it to all qstns, bind how many times repeater should come
    async function LoadDemographicData() {
        try {
            if (pid > 0) {
                const response = await DemographicService.GetDemographicData(pid);
                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else if (response?.status === HTTPResponse.BadRequest)
                        throw response.error;
                } else {
                    const { data } = response;
                    if (
                        data.cohortTreatmentLists != null &&
                        data.cohortTreatmentLists.length > 0
                    ) {
                        let i = 0;
                        let cohortArray = [];
                        for (i; i < data.cohortTreatmentLists.length; i++) {
                            let cohortData = data.cohortTreatmentLists;
                            setSelectedState(cohortData[i].stateid !== "" && cohortData[i].stateid !== null ? cohortData[i].stateid : "Select State");
                            let cohortcomponent = {
                                cohorttreatmentid: cohortData[i].cohorttreatmentid,
                                cohorttreatmentDisplayName: cohortData[i].cohorttreatmentDisplayName,
                                cohorttreatmentSupScript: cohortData[i].cohorttreatmentSupScript,
                                cohorttreatmentDisplayDate: cohortData[i].cohorttreatmentDisplayDate,
                                cohorttreatmentDisplayDateMinus365: cohortData[i].cohorttreatmentDisplayDateMinus365,

                                stateRadio: cohortData[i].stateRadio === true ? 1 : null,
                                stateName: !isStringEmpty(cohortData[i].stateName) ? cohortData[i].stateName : "Select State",
                                stateid: cohortData[i].stateid,
                                stateUnk: cohortData[i].stateUnk === true ? 1 : null,

                                insuranceCommercial: cohortData[i].insuranceCommercial,
                                insuranceMedicare: cohortData[i].insuranceMedicare,
                                insuranceMedicaid: cohortData[i].insuranceMedicaid,
                                insuranceMilitary: cohortData[i].insuranceMilitary,
                                insuranceOth: cohortData[i].insuranceOth,
                                insuranceNone: cohortData[i].insuranceNone === true ? 1 : null,
                                insuranceUnk: cohortData[i].insuranceUnk === true ? 1 : null,

                                ecogStatus: cohortData[i].ecogStatus,

                                imwgStatus: cohortData[i].imwgStatus,

                                covidVaccine: cohortData[i].covidVaccine,

                                egfrMesurementEqualsRadio: cohortData[i].egfrMesurementEqualsRadio === true ? 1 : null,
                                egfrMesurementEquals: cohortData[i].egfrMesurementEquals,
                                egfrMesurementGreaterthanRadio: cohortData[i].egfrMesurementGreaterthanRadio === true ? 1 : null,
                                egfrMesurementGreaterthan: cohortData[i].egfrMesurementGreaterthan,
                                egfrMesurementLessthanRadio: cohortData[i].egfrMesurementLessthanRadio === true ? 1 : null,
                                egfrMesurementLessthan: cohortData[i].egfrMesurementLessthan,
                                egfrMesurementUnk: cohortData[i].egfrMesurementUnk === true ? 1 : null,

                                weightDateRadio: cohortData[i].weightDateRadio === true ? 1 : null,
                                weightDate: cohortData[i].weightDate,
                                weightNotassessed: cohortData[i].weightNotassessed === true ? 1 : null,
                                weightValueRadio: cohortData[i].weightValueRadio,
                                weightValueKg: cohortData[i].weightValueKg,
                                weightValueLbs: cohortData[i].weightValueLbs,
                                //weightUnk: cohortData[i].weightUnk === true ? 1 : null,
                                //weightUnit: cohortData[i].weightUnit,
                                heightDateRadio: cohortData[i].heightDateRadio === true ? 1 : null,
                                heightDate: cohortData[i].heightDate,
                                heightNotassessed: cohortData[i].heightNotassessed === true ? 1 : null,
                                heightValueRadio: cohortData[i].heightValueRadio,
                                heightValueFt: cohortData[i].heightValueFt,
                                heightValueInch: cohortData[i].heightValueInch,
                                heightValueCm: cohortData[i].heightValueCm,
                                //heightUnk: cohortData[i].heightUnk === true ? 1 : null,
                                //heightUnit: cohortData[i].heightUnit,

                                showWMeasurement: cohortData[i].weightDateRadio,
                                //showWUnit: cohortData[i].weightValueRadio,
                                showHMeasurement: cohortData[i].heightDateRadio,
                                //showHUnit: cohortData[i].heightValueRadio,

                                q4Empty: false,
                                q5Empty: false,
                                q6Empty: false,
                                q7Empty: false,
                                q8Empty: false,
                                q9Empty: false,
                                q9aEmpty: false,
                                q10aWEmpty: false,
                                q10aWDInvalid: false,
                                q10aWDateInvalid:false,
                                q10bWEmpty: false,
                                //q10cWEmpty: false,
                                q10aHEmpty: false,
                                q10aHDInvalid: false,
                                q10bHEmpty: false,
                                q10aHDateInvalid : false,
                                //q10cHEmpty: false,
                            }
                            cohortArray.push(cohortcomponent);
                        }

                        setRepeaterQ4ToQ10(cohortArray);
                    }
                    else {
                        setRepeaterQ4ToQ10(null);
                    }

                    setPtDemographicsDetails((prev) => ({
                        ...prev,
                        gender: data.gender != null ? data.gender : "",
                        raceAmericanindian: data.raceAmericanindian != null ? data.raceAmericanindian : false,
                        raceAsian: data.raceAsian != null ? data.raceAsian : false,
                        raceBlack: data.raceBlack != null ? data.raceBlack : false,
                        raceNativeamerican: data.raceNativeamerican != null ? data.raceNativeamerican : false,
                        raceWhite: data.raceWhite != null ? data.raceWhite : false,
                        raceOth: data.raceOth != null ? data.raceOth : false,
                        raceUnk: data.raceUnk == true ? 1 : null,
                        ethnicity: data.ethnicity != null ? data.ethnicity : "",
                    }))

                }
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }

    //DN 16/7/24 Save static demographic data of the patient in table 'patientdemographic'
    const SavePage = async (validate) => {
        try {
            ToggleLoader(true);
            setHardErrList(InitialHardErrList);
            let updatedArray = [...repeaterQ4ToQ10];
            if (repeaterQ4ToQ10.length > 0) {
                for (let i = 0; i < repeaterQ4ToQ10.length; i++) {
                    updatedArray[i].q4Empty = false;
                    updatedArray[i].q5Empty = false;
                    updatedArray[i].q6Empty = false;
                    updatedArray[i].q7Empty = false;
                    updatedArray[i].q8Empty = false;
                    updatedArray[i].q9Empty = false;
                    updatedArray[i].q9aEmpty = false;
                    updatedArray[i].q10aWEmpty = false;
                    updatedArray[i].q10aWDInvalid = false;
                    updatedArray[i].q10aWDateInvalid = false;
                    updatedArray[i].q10aHDateInvalid = false;
                    updatedArray[i].q10aHEmpty = false;
                    updatedArray[i].q10aHDInvalid = false;
                    updatedArray[i].q10bHEmpty = false;
                
                }
                setRepeaterQ4ToQ10(updatedArray);
            }


            let isValid = validate ? ValidateForm() : true;
            if (isValid) {
                setShowTopErrMsg(false)
                let demographicModel = {
                    patientId: pid,
                    gender: ptDemographicsDetails.gender,
                    raceAmericanindian: ptDemographicsDetails.raceAmericanindian,
                    raceAsian: ptDemographicsDetails.raceAsian,
                    raceBlack: ptDemographicsDetails.raceBlack,
                    raceNativeamerican: ptDemographicsDetails.raceNativeamerican,
                    raceWhite: ptDemographicsDetails.raceWhite,
                    raceOth: ptDemographicsDetails.raceOth,
                    raceUnk: ptDemographicsDetails.raceUnk == 1 ? true : false,
                    ethnicity: ptDemographicsDetails.ethnicity,
                    cohortTreatmentLists: [],
                    loggedInUserId: GetLoggedInUserID(),
                    isValidated: validate
                }
                if (repeaterQ4ToQ10.length > 0) {
                 
                    for (let i = 0; i < repeaterQ4ToQ10.length; i++) {
                        let repeaterData = {
                            cohorttreatmentid: repeaterQ4ToQ10[i].cohorttreatmentid,
                            stateid: repeaterQ4ToQ10[i].stateid,
                            stateName: repeaterQ4ToQ10[i].stateName,
                            stateUnk: repeaterQ4ToQ10[i].stateUnk === 1 ? true : false,
                            insuranceCommercial: repeaterQ4ToQ10[i].insuranceCommercial,
                            insuranceMedicare: repeaterQ4ToQ10[i].insuranceMedicare,
                            insuranceMedicaid: repeaterQ4ToQ10[i].insuranceMedicaid,
                            insuranceMilitary: repeaterQ4ToQ10[i].insuranceMilitary,
                            insuranceOth: repeaterQ4ToQ10[i].insuranceOth,
                            insuranceNone: repeaterQ4ToQ10[i].insuranceNone === 1 ? true : false,
                            insuranceUnk: repeaterQ4ToQ10[i].insuranceUnk === 1 ? true : false,
                            ecogStatus: repeaterQ4ToQ10[i].ecogStatus,
                            imwgStatus: repeaterQ4ToQ10[i].imwgStatus,
                            covidVaccine: repeaterQ4ToQ10[i].covidVaccine,
                            egfrMesurementEquals: repeaterQ4ToQ10[i].egfrMesurementEquals > 0 ? parseFloat(repeaterQ4ToQ10[i].egfrMesurementEquals).toFixed(1) : null,
                            egfrMesurementGreaterthan: repeaterQ4ToQ10[i].egfrMesurementGreaterthan > 0 ? parseFloat(repeaterQ4ToQ10[i].egfrMesurementGreaterthan).toFixed(1) : null,
                            egfrMesurementLessthan: repeaterQ4ToQ10[i].egfrMesurementLessthan > 0 ? parseFloat(repeaterQ4ToQ10[i].egfrMesurementLessthan).toFixed(1) : null,
                            egfrMesurementUnk: repeaterQ4ToQ10[i].egfrMesurementUnk === 1 ? true : false,
                           // weightDate: repeaterQ4ToQ10[i].weightDate,
                            weightDate:repeaterQ4ToQ10[i].weightDate != "Invalid Date" && repeaterQ4ToQ10[i].weightDate
                            ? repeaterQ4ToQ10[i].weightDate :
                            repeaterQ4ToQ10[i].weightDate === "Invalid Date"?""
                            : "",
                            weightNotassessed: repeaterQ4ToQ10[i].weightNotassessed === 1 ? true : false,
                            weightValueRadio: repeaterQ4ToQ10[i].weightValueRadio,
                            weightValueKg: repeaterQ4ToQ10[i].weightValueKg > 0 ? parseFloat(repeaterQ4ToQ10[i].weightValueKg).toFixed(2) : null,
                            weightValueLbs: repeaterQ4ToQ10[i].weightValueLbs > 0 ? parseFloat(repeaterQ4ToQ10[i].weightValueLbs).toFixed(2) : null,
                            heightDate: repeaterQ4ToQ10[i].heightDate,
                            heightNotassessed: repeaterQ4ToQ10[i].heightNotassessed === 1 ? true : false,
                            heightValueRadio: repeaterQ4ToQ10[i].heightValueRadio,
                            heightValueFt: repeaterQ4ToQ10[i].heightValueFt > 0 ? parseFloat(repeaterQ4ToQ10[i].heightValueFt).toFixed(1) : null,
                            heightValueInch: repeaterQ4ToQ10[i].heightValueInch < 0 ? null : repeaterQ4ToQ10[i].heightValueInch,
                            heightValueCm: repeaterQ4ToQ10[i].heightValueCm > 0 ? parseFloat(repeaterQ4ToQ10[i].heightValueCm).toFixed(1) : null,
                        };
                        demographicModel.cohortTreatmentLists.push(repeaterData);
                    }
                }

                if (pid > 0) {
                    const response = await DemographicService.SaveDemographicData(demographicModel);
                    ToggleLoader(false);
                    if (response.status !== HTTPResponse.OK) {
                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        }
                        else
                            throw response.error;
                    }
                    else
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                }
                return true;
            }
            else {
                ToggleLoader(false);
                setShowTopErrMsg(true)
                return false;
            }
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    }

    //DN 17/7/2024 Show/Hide hard error message
    const [showTopErrMsg, setShowTopErrMsg] = useState(false)

    //DN 17/7/2024 Hard error message List
    const InitialHardErrList = {
        q1Empty: false,
        q2Empty: false,
        q3Empty: false,
    }

    const [hardErrList, setHardErrList] = useState(InitialHardErrList)

    const HandleDDChange = (item, idx) => {
        let updatedArray = [...repeaterQ4ToQ10];

        updatedArray[idx].stateRadio = 1;
        updatedArray[idx].stateName = item.statename;
        updatedArray[idx].stateid = Number(item.stateid);
        updatedArray[idx].stateUnk = "";
        updatedArray[idx].q4Empty = false;

        setRepeaterQ4ToQ10(updatedArray);
    }

    //DN 17/7/2024 Handle error messages to be shown on change of selection [Static data Q1-Q3]
    const HandleFieldChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "gender": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value)
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q1Empty: false
                }))
                break;
            }
            case "raceAmericanindian": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: !prev.raceAmericanindian,
                    raceUnk: "",
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false
                }))
                break;
            }
            case "raceAsian": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: !prev.raceAsian,
                    raceUnk: "",
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false
                }))
                break;
            }
            case "raceBlack": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: !prev.raceBlack,
                    raceUnk: "",
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false,
                }))
                break;
            }
            case "raceNativeamerican": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: !prev.raceNativeamerican,
                    raceUnk: ""
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false
                }))
                break;
            }
            case "raceWhite": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: !prev.raceWhite,
                    raceUnk: ""
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false
                }))
                break;
            }
            case "raceOth": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: !prev.raceOth,
                    raceUnk: ""
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false
                }))
                break;
            }
            case "raceUnk": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: Number(value),
                    raceAmericanindian: "",
                    raceAsian: "",
                    raceBlack: "",
                    raceNativeamerican: "",
                    raceWhite: "",
                    raceOth: "",
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q2Empty: false
                }))
                break;
            }
            case "ethnicity": {
                setPtDemographicsDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value)
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q3Empty: false
                }))
                break;
            }

            default:
                break;
        }
    }

    //DN 17/7/2024 Validate the selections done before saving the data 
    const ValidateForm = () => {
   
        let result = true;
        //Q1
        if (isStringEmpty(ptDemographicsDetails.gender) || ptDemographicsDetails.gender === "null") {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q1Empty: true
            }))
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                q1Empty: false
            }))
        }

        //Q2
        if ((isStringEmpty(ptDemographicsDetails.raceAmericanindian) && isStringEmpty(ptDemographicsDetails.raceAsian) &&
            isStringEmpty(ptDemographicsDetails.raceBlack) && isStringEmpty(ptDemographicsDetails.raceNativeamerican) &&
            isStringEmpty(ptDemographicsDetails.raceWhite) && isStringEmpty(ptDemographicsDetails.raceOth) &&
            isStringEmpty(ptDemographicsDetails.raceUnk)) ||
            (ptDemographicsDetails.raceAmericanindian === false && ptDemographicsDetails.raceAsian === false
                && ptDemographicsDetails.raceBlack === false && ptDemographicsDetails.raceNativeamerican === false &&
                ptDemographicsDetails.raceWhite === false && ptDemographicsDetails.raceOth === false &&
                ptDemographicsDetails.raceUnk === "0")) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q2Empty: true
            }))
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                q2Empty: false
            }))
        }

        //Q3
        if (isStringEmpty(ptDemographicsDetails.ethnicity) || ptDemographicsDetails.ethnicity === "null") {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                q3Empty: true
            }))
        }
        else {
            setHardErrList((prev) => ({
                ...prev,
                q3Empty: false
            }))
        }

        let repeaterarray = repeaterQ4ToQ10;
        for (let i = 0; i < repeaterQ4ToQ10.length; i++) {
            //Q4
            if (isStringEmpty(repeaterQ4ToQ10[i].stateid) && isStringEmpty(repeaterarray[i].stateUnk) || repeaterarray[i].stateUnk === "0") {
                result = false;
                repeaterarray[i].q4Empty = true;
            }
            else {
                repeaterarray[i].q4Empty = false;
            }

            //Q5
            if (isStringEmpty(repeaterarray[i].insuranceCommercial) && isStringEmpty(repeaterarray[i].insuranceMedicare) &&
                isStringEmpty(repeaterarray[i].insuranceMedicaid) && isStringEmpty(repeaterarray[i].insuranceMilitary) &&
                isStringEmpty(repeaterarray[i].insuranceOth) && isStringEmpty(repeaterarray[i].insuranceNone) &&
                isStringEmpty(repeaterarray[i].insuranceUnk) ||
                (repeaterarray[i].insuranceCommercial === false && repeaterarray[i].insuranceMedicare === false
                    && repeaterarray[i].insuranceMedicaid === false && repeaterarray[i].insuranceMilitary === false &&
                    repeaterarray[i].insuranceOth === false && repeaterarray[i].insuranceNone &&
                    repeaterarray[i].insuranceUnk === "0")) {
                result = false;
                repeaterarray[i].q5Empty = true;
            }
            else {
                repeaterarray[i].q5Empty = false;
            }

            //Q6
            if (isStringEmpty(repeaterarray[i].ecogStatus) || repeaterarray[i].ecogStatus === "0") {
                result = false;
                repeaterarray[i].q6Empty = true;
            }
            else {
                repeaterarray[i].q6Empty = false;
            }

            //Q7
            if (isStringEmpty(repeaterarray[i].imwgStatus) || repeaterarray[i].imwgStatus === "0") {
                result = false;
                repeaterarray[i].q7Empty = true;
            }
            else {
                repeaterarray[i].q7Empty = false;
            }

            //Q8
            if (isStringEmpty(repeaterarray[i].covidVaccine) || repeaterarray[i].covidVaccine === "0") {
                result = false;
                repeaterarray[i].q8Empty = true;
            }
            else {
                repeaterarray[i].q8Empty = false;
            }

            //Q9
            if (isStringEmpty(repeaterarray[i].egfrMesurementEquals) && isStringEmpty(repeaterarray[i].egfrMesurementGreaterthan) &&
                isStringEmpty(repeaterarray[i].egfrMesurementLessthan) && isStringEmpty(repeaterarray[i].egfrMesurementEqualsRadio) && isStringEmpty(repeaterarray[i].egfrMesurementGreaterthanRadio) &&
                isStringEmpty(repeaterarray[i].egfrMesurementLessthanRadio) && isStringEmpty(repeaterarray[i].egfrMesurementUnk) || repeaterarray[i].egfrMesurementUnk === "0") {
                result = false;
                repeaterarray[i].q9Empty = true;
            }
            else if (((repeaterarray[i].egfrMesurementEqualsRadio === 1) && (repeaterarray[i].egfrMesurementEquals === null || repeaterarray[i].egfrMesurementEquals === "" || repeaterarray[i].egfrMesurementEquals < 0 || repeaterarray[i].egfrMesurementEquals > 300)) ||
                ((repeaterarray[i].egfrMesurementGreaterthanRadio === 1) && (repeaterarray[i].egfrMesurementGreaterthan === null || repeaterarray[i].egfrMesurementGreaterthan === "" || repeaterarray[i].egfrMesurementGreaterthan < 0 || repeaterarray[i].egfrMesurementGreaterthan > 300)) ||
                ((repeaterarray[i].egfrMesurementLessthanRadio === 1) && (repeaterarray[i].egfrMesurementLessthan === null || repeaterarray[i].egfrMesurementLessthan === "" || repeaterarray[i].egfrMesurementLessthan < 0 || repeaterarray[i].egfrMesurementLessthan > 300))) {
                //&& (isStringEmpty(repeaterarray[i].egfrMesurementUnk) || repeaterarray[i].egfrMesurementUnk === "0")
                result = false;
                repeaterarray[i].q9aEmpty = true;
            }
            else {
                repeaterarray[i].q9Empty = false;
                repeaterarray[i].q9aEmpty = false;
            }

          

            //Q10 - Weight
            if ((isStringEmpty(repeaterarray[i].weightDateRadio) && isStringEmpty(repeaterarray[i].weightDate) && isStringEmpty(repeaterarray[i].weightNotassessed)) ||
                (!isStringEmpty(repeaterarray[i].weightDateRadio) && isStringEmpty(repeaterarray[i].weightDate))) {
                result = false;
                repeaterarray[i].q10aWEmpty = true;
            }
            else {
                repeaterarray[i].q10aWEmpty = false;
            }

            if (!isStringEmpty(repeaterarray[i].weightDate) || !isStringEmpty(repeaterarray[i].weightDateRadio)) {
                if ((isStringEmpty(repeaterarray[i].weightValueRadio) && isStringEmpty(repeaterarray[i].weightValueKg) && isStringEmpty(repeaterarray[i].weightValueLbs)) ||
                    (!isStringEmpty(repeaterarray[i].weightValueRadio) && repeaterarray[i].weightValueRadio !== 99 && isStringEmpty(repeaterarray[i].weightValueKg) && isStringEmpty(repeaterarray[i].weightValueLbs))) {

                    result = false;
                    repeaterarray[i].q10bWEmpty = true;
                }
                else if (!isStringEmpty(repeaterarray[i].weightDate) && repeaterarray[i].weightDateRadio === 1 && DateInRange(repeaterarray[i].weightDate, dayjs("01-01-1920"), dayjs())) {
                    const cohortIndexedDate = repeaterarray[i].cohorttreatmentDisplayDate;
                    const dateValid = validateMeasurementDate(repeaterarray[i].weightDate, cohortIndexedDate);
                    if (!dateValid) {
                        result = false;
                        repeaterarray[i].q10aWDInvalid = true;
                    }
                    else
                        repeaterarray[i].q10aWDInvalid = false;
                }
                else if (repeaterarray[i].weightDate == "Invalid Date") {
                    repeaterarray[i].q10aWDateInvalid = true;
                    result = false;
                  }
                else if(repeaterarray[i].weightDate !== "Invalid Date" && repeaterarray[i].weightDate !== "" && !DateInRange(repeaterarray[i].weightDate, dayjs("01-01-1920"), dayjs())){
                    repeaterarray[i].q10aWDateInvalid = true;
                    result = false;
                  }  
              
                else
                    repeaterarray[i].q10bWEmpty = false;

                if (!isStringEmpty(repeaterarray[i].weightValueKg) || !isStringEmpty(repeaterarray[i].weightValueLbs)) {
                    if ((parseFloat(repeaterarray[i].weightValueKg) < 1 || parseFloat(repeaterarray[i].weightValueKg) > 630) ||
                        (parseFloat(repeaterarray[i].weightValueLbs) < 1 || parseFloat(repeaterarray[i].weightValueLbs) > 1400)) {
                        result = false;
                        repeaterarray[i].q10bWEmpty = true;
                    }
                    else
                        repeaterarray[i].q10bWEmpty = false;
                }
            }


            //Q10 - Height
            if ((isStringEmpty(repeaterarray[i].heightDateRadio) && isStringEmpty(repeaterarray[i].heightDate) && isStringEmpty(repeaterarray[i].heightNotassessed)) ||
                (!isStringEmpty(repeaterarray[i].heightDateRadio) && isStringEmpty(repeaterarray[i].heightDate) )) {
                result = false;
                repeaterarray[i].q10aHEmpty = true;
            }
            else {
                repeaterarray[i].q10aHEmpty = false;
            }
  
         
            if (!isStringEmpty(repeaterarray[i].heightDate) || !isStringEmpty(repeaterarray[i].heightDateRadio)) {
                if ((isStringEmpty(repeaterarray[i].heightValueRadio) && isStringEmpty(repeaterarray[i].heightValueFt) && isStringEmpty(repeaterarray[i].heightValueInch) && isStringEmpty(repeaterarray[i].heightValueCm)) ||
                    (!isStringEmpty(repeaterarray[i].heightValueRadio) && repeaterarray[i].heightValueRadio !== 99 && isStringEmpty(repeaterarray[i].heightValueFt) && isStringEmpty(repeaterarray[i].heightValueInch) && isStringEmpty(repeaterarray[i].heightValueCm))) {
                    result = false;
                    repeaterarray[i].q10bHEmpty = true;
                }
                else if (!isStringEmpty(repeaterarray[i].heightDate) && repeaterarray[i].heightDateRadio === 1 && DateInRange(repeaterarray[i].heightDate, dayjs("01-01-1920"), dayjs())) {
                    const cohortIndexedDate = repeaterarray[i].cohorttreatmentDisplayDate;
                    const dateValid = validateMeasurementDate(repeaterarray[i].heightDate, cohortIndexedDate);
                    if (!dateValid) {
                        result = false;
                        repeaterarray[i].q10aHDInvalid = true;
                    }
                    else
                        repeaterarray[i].q10aHDInvalid = false;
                }
                else if (repeaterarray[i].heightDate == "Invalid Date") {
                    repeaterarray[i].q10aHDateInvalid = true;
                    result = false;
                  }
                else if(repeaterarray[i].heightDate !== "Invalid Date" && repeaterarray[i].heightDate !== "" && !DateInRange(repeaterarray[i].heightDate, dayjs("01-01-1920"), dayjs())){
                    repeaterarray[i].q10aHDateInvalid = true;
                    result = false;
                  }  
              
           
                else
                    repeaterarray[i].q10bHEmpty = false;


               if(repeaterarray[i].heightValueRadio == 1   && !isStringEmpty(repeaterarray[i].heightValueFt) && isStringEmpty(repeaterarray[i].heightValueInch)){
                        result = false;
                        repeaterarray[i].q10bHEmpty = true;
                    }
                if(repeaterarray[i].heightValueRadio == 1   && isStringEmpty(repeaterarray[i].heightValueFt) && !isStringEmpty(repeaterarray[i].heightValueInch)){
                        result = false;
                        repeaterarray[i].q10bHEmpty = true;
                    }    

                if (((!isStringEmpty(repeaterarray[i].heightValueFt)) &&(!isStringEmpty(repeaterarray[i].heightValueInch)))  || (!isStringEmpty(repeaterarray[i].heightValueCm))) {
                    if (((parseFloat(repeaterarray[i].heightValueFt) < 1 || parseFloat(repeaterarray[i].heightValueFt) > 9) || (parseFloat(repeaterarray[i].heightValueInch) < 0 || parseFloat(repeaterarray[i].heightValueInch) > 11)) ||
                        (parseFloat(repeaterarray[i].heightValueCm) < 1 || parseFloat(repeaterarray[i].heightValueCm) > 300)) {
                        result = false;
                        repeaterarray[i].q10bHEmpty = true;
                    }
                   // else
                        //repeaterarray[i].q10bHEmpty = false;
                }

            }
        }

        return result;
    }

    const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {
        let updatedArray = [...repeaterQ4ToQ10];
        const idx = name.split('_').pop();

        const cohortIndexedDate = updatedArray[idx].cohorttreatmentDisplayDate;
        const dateValid = validateMeasurementDate(date, cohortIndexedDate);
        switch (name) {
            case `q10_WDate_${idx}`: {
                updatedArray[idx].weightDateRadio = 1;
                updatedArray[idx].weightDate = date;
                updatedArray[idx].weightNotassessed = "";
               // updatedArray[idx].weightValueRadio = "";
               // updatedArray[idx].weightValueKg = "";
                //updatedArray[idx].weightValueLbs = "";
                //updatedArray[idx].weightUnk = "";
                //updatedArray[idx].weightUnit = "";
                updatedArray[idx].showWMeasurement = true;
                //updatedArray[idx].showWUnit = true;
                updatedArray[idx].q10aWEmpty = false;
               // updatedArray[idx].q10aWDInvalid = !dateValid;
               updatedArray[idx].q10aWDateInvalid = false;
                updatedArray[idx].q10bWEmpty = false;
                //updatedArray[idx].q10cWEmpty = false;
            }
                break;
            case `q10_HDate_${idx}`: {
                updatedArray[idx].heightDateRadio = 1;
                updatedArray[idx].heightDate = date;
                updatedArray[idx].heightNotassessed = "";
              //  updatedArray[idx].heightValueRadio = "";
              //  updatedArray[idx].heightValueFt = "";
              //  updatedArray[idx].heightValueInch = "";
              //  updatedArray[idx].heightValueCm = "";
                //updatedArray[idx].heightUnk = "";
                //updatedArray[idx].heightUnit = "";
                updatedArray[idx].showHMeasurement = true;
                //updatedArray[idx].showHUnit = true;
                updatedArray[idx].q10aHEmpty = false;
                updatedArray[idx].q10aHDateInvalid = false;
               // updatedArray[idx].q10aHDInvalid = !dateValid;
                updatedArray[idx].q10bHEmpty = false;
                //updatedArray[idx].q10cHEmpty = false;
            }
                break;
            default:
                break;
        }
        setRepeaterQ4ToQ10(updatedArray);
    };

    const [selectedState, setSelectedState] = useState('Select State');
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.Demographics} />
                    <>
                    <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                        </>
                    <div className="col-pad-left" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>PATIENT DEMOGRAPHICS</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>
                            }
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">

                                        {/* Q1 */}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q1.</span>
                                                <span className="quest-text-pad">
                                                    Patient sex
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div className="option-without-input">
                                                    <input type="radio"
                                                        id="q1Male1"
                                                        name="gender"
                                                        value={1}
                                                        checked={
                                                            ptDemographicsDetails.gender === 1
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q1Male1">
                                                        <span className="radio-text-padding">Male</span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input type="radio"
                                                        id="q1Female2"
                                                        name="gender"
                                                        value={2}
                                                        checked={
                                                            ptDemographicsDetails.gender === 2
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q1Female2">
                                                        <span className="radio-text-padding">Female</span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input type="radio"
                                                        id="q1UnknownSex99"
                                                        name="gender"
                                                        value={99}
                                                        checked={
                                                            ptDemographicsDetails.gender === 99
                                                        }
                                                        onChange={HandleFieldChange} />

                                                    <label htmlFor="q1UnknownSex99">
                                                        <span className="radio-text-padding">Other/ Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q1Empty} message={demographicScreen_Msgs.Q1} />
                                        </div>

                                        {/* Q2 */}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q2.</span>
                                                <span className="quest-text-pad">
                                                    Patient race. <i style={{ fontWeight: 'normal' }}>Select all that apply.</i>
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input type="checkbox"
                                                        id="q2americanindian"
                                                        name="raceAmericanindian"
                                                        checked={
                                                            ptDemographicsDetails.raceAmericanindian
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2americanindian">
                                                        <span className="radio-text-padding">American Indian or Alaska Native</span></label>
                                                </div>
                                                <div className="">
                                                    <input type="checkbox"
                                                        id="q2asian"
                                                        name="raceAsian"
                                                        checked={
                                                            ptDemographicsDetails.raceAsian
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2asian">
                                                        <span className="radio-text-padding">
                                                            Asian
                                                        </span></label>
                                                </div>
                                                <div className="">
                                                    <input type="checkbox"
                                                        id="q2black"
                                                        name="raceBlack"
                                                        checked={
                                                            ptDemographicsDetails.raceBlack
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2black">
                                                        <span className="radio-text-padding">
                                                            Black or African American
                                                        </span></label>
                                                </div>
                                                <div className="">
                                                    <input type="checkbox"
                                                        id="q2nativeamerican"
                                                        name="raceNativeamerican"
                                                        checked={
                                                            ptDemographicsDetails.raceNativeamerican
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2nativeamerican">
                                                        <span className="radio-text-padding">Native Hawaiian or Other Pacific Islander</span></label>
                                                </div>
                                                <div className="">
                                                    <input type="checkbox"
                                                        id="q2white"
                                                        name="raceWhite"
                                                        checked={
                                                            ptDemographicsDetails.raceWhite
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2white">
                                                        <span className="radio-text-padding">
                                                            White
                                                        </span></label>
                                                </div>

                                                <div className="">
                                                    <input type="checkbox"
                                                        id="q2oth"
                                                        name="raceOth"
                                                        checked={
                                                            ptDemographicsDetails.raceOth
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2oth" >
                                                        <span className="radio-text-padding">
                                                            Other
                                                        </span></label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q2unk"
                                                        name="raceUnk"
                                                        value={1}
                                                        checked={
                                                            ptDemographicsDetails.raceUnk === 1
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2unk" >
                                                        <span className="radio-text-padding">Unknown</span></label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q2Empty} message={demographicScreen_Msgs.Q2} />
                                        </div>

                                        {/* Q3 */}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q3.</span>
                                                <span className="quest-text-pad">
                                                    Patient ethnicity
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div className="option-without-input">
                                                    <input type="radio"
                                                        id="q2Hispanic1"
                                                        name="ethnicity"
                                                        value={1}
                                                        checked={
                                                            ptDemographicsDetails.ethnicity === 1
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q2Hispanic1">
                                                        <span className="radio-text-padding">Hispanic or Latino</span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input type="radio"
                                                        id="q3NotHispanic"
                                                        name="ethnicity"
                                                        value={2}
                                                        checked={
                                                            ptDemographicsDetails.ethnicity === 2
                                                        }
                                                        onChange={HandleFieldChange} />
                                                    <label htmlFor="q3NotHispanic">
                                                        <span className="radio-text-padding">Not Hispanic or Latino</span>
                                                    </label>
                                                </div>
                                                <div className="option-without-input">
                                                    <input type="radio"
                                                        id="q3Unknown"
                                                        name="ethnicity"
                                                        value={99}
                                                        checked={
                                                            ptDemographicsDetails.ethnicity === 99
                                                        }
                                                        onChange={HandleFieldChange} />

                                                    <label htmlFor="q3Unknown">
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q3Empty} message={demographicScreen_Msgs.Q3} />
                                        </div>

                                        {repeaterQ4ToQ10?.map((data, idx) => (
                                            <div className="dependent-section" key={idx}>
                                                {/* dependent-section */}
                                                {/* Q4 */}
                                                <div className="question-bot-sapce">
                                                <div className='cohort-header'>
                                                    <span>
                                                        {data.cohorttreatmentDisplayName}
                                                        {!isStringEmpty(data.cohorttreatmentSupScript) && (
                                                        <>
                                                            <sup className="supscript">{data.cohorttreatmentSupScript.slice(0, 2)}</sup>
                                                            {data.cohorttreatmentSupScript.slice(2)}
                                                        </>
                                                        )}
                                                    </span>
                                                </div>
                                                    <div className="question question-weight">
                                                        <span>Q4.</span>
                                                        <span className="quest-text-pad">
                                                            State of residence when initiating{" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayName}</b>
                                                                {data.cohorttreatmentSupScript === null ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                        <sup className="supscript">{data.cohorttreatmentSupScript.slice(0, 2)}</sup>
                                                                        <b>{data.cohorttreatmentSupScript.slice(2)}</b>
                                                                    </>
                                                                )}</span>
                                                            {" "}on{" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayDate}</b></span>
                                                        </span>
                                                    </div>
                                                    <div className="double-dig-answer">
                                                        <div className="">
                                                            <input
                                                                type="radio"
                                                                id={`q4_StateRadio_${idx}`}
                                                                name={`q4_StateRadio_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.stateRadio
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <div className="radio-text-padding">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="" id={`q4_StateId${idx}`} name={`q4_StateId${idx}`} className="custom-dropdown-button" style={{ width: '150px', height: '30px' }}>
                                                                        {data.stateName}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu style={{
                                                                        maxHeight: '150px', overflowY: 'scroll',
                                                                        overflowX: 'hidden',
                                                                        zIndex: 9
                                                                    }}>
                                                                        {ptDemographicsDetails.states.map((item) => (
                                                                            <Dropdown.Item
                                                                                as="button"
                                                                                id={`q4_StateId${idx}`}
                                                                                name={`q4_StateId${idx}`}
                                                                                key={item.stateid}
                                                                                value={item.stateid}
                                                                                className={`custom-dropdown-item ${item.statename === selectedState && 'custom-dropdown-item-selected'}`}
                                                                                style={{ width: "100% !important" }}
                                                                                onClick={() => { setSelectedState(item.statename); HandleDDChange(item, idx) }}
                                                                            >
                                                                                {item.statename}
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>

                                                        <div className="">
                                                            <input
                                                                type="radio"
                                                                id={`q4_Unk_${idx}`}
                                                                name={`q4_Unk_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.stateUnk === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q4_Unk_${idx}`} >
                                                                <span className="radio-text-padding">Unknown</span></label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q4Empty} message={demographicScreen_Msgs.Q4} />
                                                </div>

                                                {/* Q5 */}
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q5.</span>
                                                        <span className="quest-text-pad">
                                                            Insurance type when initiating {" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayName}</b>
                                                                {data.cohorttreatmentSupScript === null ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                        <sup className="supscript">{data.cohorttreatmentSupScript.slice(0, 2)}</sup>
                                                                        <b>{data.cohorttreatmentSupScript.slice(2)}</b>
                                                                    </>
                                                                )}</span>
                                                            {" "}on{" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayDate}</b></span>. <i style={{ fontWeight: 'normal' }}>Select all that apply.</i>
                                                        </span>
                                                    </div>
                                                    <div className=" double-dig-answer">
                                                        <div className="">
                                                            <input type="checkbox"
                                                                id={`q5_C_${idx}`}
                                                                name={`q5_C_${idx}`}
                                                                checked={
                                                                    data.insuranceCommercial
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_C_${idx}`}>
                                                                <span className="radio-text-padding">Commercial/private insurance</span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox"
                                                                id={`q5_Mcr_${idx}`}
                                                                name={`q5_Mcr_${idx}`}
                                                                checked={
                                                                    data.insuranceMedicare
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_Mcr_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    Medicare
                                                                </span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox"
                                                                id={`q5_Mcd_${idx}`}
                                                                name={`q5_Mcd_${idx}`}
                                                                checked={
                                                                    data.insuranceMedicaid
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_Mcd_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    Medicaid
                                                                </span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox"
                                                                id={`q5_Mlt_${idx}`}
                                                                name={`q5_Mlt_${idx}`}
                                                                checked={
                                                                    data.insuranceMilitary
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_Mlt_${idx}`}>
                                                                <span className="radio-text-padding">Military Insurance (Veterans Affairs or active military)</span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox"
                                                                id={`q5_O_${idx}`}
                                                                name={`q5_O_${idx}`}
                                                                checked={
                                                                    data.insuranceOth
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_O_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    Other insurance
                                                                </span></label>
                                                        </div>

                                                        <div className="">
                                                            <input
                                                                type="radio"
                                                                id={`q5_NI_${idx}`}
                                                                name={`q5_NI_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.insuranceNone === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_NI_${idx}`} >
                                                                <span className="radio-text-padding">No insurance</span></label>
                                                        </div>
                                                        <div className="">
                                                            <input
                                                                type="radio"
                                                                id={`q5_Unk_${idx}`}
                                                                name={`q5_Unk_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.insuranceUnk === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q5_Unk_${idx}`} >
                                                                <span className="radio-text-padding">Unknown</span></label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q5Empty} message={demographicScreen_Msgs.Q5} />
                                                </div>

                                                <div className="question question-weight">
                                                    <span>[For the questions {" "}
                                                        <span style={{ color: PageDesignConstants.fontColor }}><b>Q6-Q10</b></span>,
                                                        please use the <u>assessment closest to and within 12 months prior to</u> the initiation of treatment with{" "}
                                                        <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayName}</b>
                                                            {data.cohorttreatmentSupScript === null ? (
                                                                ''
                                                            ) : (
                                                                <>
                                                                    <sup className="supscript">{data.cohorttreatmentSupScript.slice(0, 2)}</sup>
                                                                    <b>{data.cohorttreatmentSupScript.slice(2)}</b>
                                                                </>
                                                            )}</span>
                                                        {" "} (i.e., from <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayDateMinus365}</b></span> to <span style={{ color: PageDesignConstants.fontColor }}><b>{data.cohorttreatmentDisplayDate}</b></span>)]</span>
                                                </div>

                                                {/* Q6 */}
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q6.</span>
                                                        <span className="quest-text-pad">
                                                            Eastern Cooperative Oncology Group (ECOG) performance status
                                                        </span>
                                                    </div>
                                                    <div className=" double-dig-answer">
                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <input type="radio"
                                                                id={`q6_option1_${idx}`}
                                                                name={`q6_option1_${idx}`}
                                                                style={{ marginTop: '6px' }}
                                                                value={1}
                                                                checked={
                                                                    data.ecogStatus === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q6_option1_${idx}`} >
                                                                <span className="radio-text-padding">0 - Fully active. Able to carry on all pre-disease performance without restriction</span></label>
                                                        </div>
                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <input type="radio"
                                                                id={`q6_option2_${idx}`}
                                                                name={`q6_option2_${idx}`}
                                                                style={{ marginTop: '6px' }}
                                                                value={2}
                                                                checked={
                                                                    data.ecogStatus === 2
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q6_option2_${idx}`}>
                                                                <span className="radio-text-padding">1 - Restricted in physically strenuous activity but ambulatory and able to carry out work of a light or sedentary nature, e.g., light housework, office work</span>
                                                            </label>
                                                        </div>
                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <input
                                                                type="radio"
                                                                id={`q6_option3_${idx}`}
                                                                name={`q6_option3_${idx}`}
                                                                style={{ marginTop: '6px' }}
                                                                value={3}
                                                                checked={data.ecogStatus === 3}
                                                                onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <label htmlFor={`q6_option3_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    2 - Ambulatory and capable of all selfcare but unable to carry out any work activities. Up and about more than 50% of waking hours
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <input type="radio"
                                                                id={`q6_option4_${idx}`}
                                                                name={`q6_option4_${idx}`}
                                                                style={{ marginTop: '6px' }}
                                                                value={4}
                                                                checked={
                                                                    data.ecogStatus === 4
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q6_option4_${idx}`} >
                                                                <span className="radio-text-padding">3 - Capable of only limited selfcare, confined to bed or chair more than 50% of waking hours</span></label>
                                                        </div>
                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <input type="radio"
                                                                id={`q6_option5_${idx}`}
                                                                name={`q6_option5_${idx}`}
                                                                style={{ marginTop: '6px' }}
                                                                value={5}
                                                                checked={
                                                                    data.ecogStatus === 5
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q6_option5_${idx}`} >
                                                                <span className="radio-text-padding">4 - Completely disabled. Cannot carry on any selfcare. Totally confined to a bed or chair</span></label>
                                                        </div>
                                                        <div className="" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <input type="radio"
                                                                id={`q6_option99_${idx}`}
                                                                name={`q6_option99_${idx}`}
                                                                style={{ marginTop: '6px' }}
                                                                value={99}
                                                                checked={
                                                                    data.ecogStatus === 99
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q6_option99_${idx}`} >
                                                                <span className="radio-text-padding">Unknown</span></label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q6Empty} message={demographicScreen_Msgs.Q6} />
                                                </div>

                                                {/* Q7 */}
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q7.</span>
                                                        <span className="quest-text-pad">
                                                            International Myeloma Working Group (IMWG) frailty status
                                                        </span>
                                                    </div>

                                                    <div className=" double-dig-answer">
                                                        <div className="">
                                                            <input type="radio" id={`q7_option1_${idx}`}
                                                                name={`q7_option1_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.imwgStatus === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q7_option1_${idx}`}>
                                                                <span className="radio-text-padding">Fit (score = 0)</span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="radio" id={`q7_option2_${idx}`}
                                                                name={`q7_option2_${idx}`}
                                                                value={2}
                                                                checked={
                                                                    data.imwgStatus === 2
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q7_option2_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    Intermediate fitness (score = 1)
                                                                </span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="radio" id={`q7_option3_${idx}`}
                                                                name={`q7_option3_${idx}`}
                                                                value={3}
                                                                checked={
                                                                    data.imwgStatus === 3
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q7_option3_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    Frail (score ≥ 2)
                                                                </span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="radio" id={`q7_option99_${idx}`}
                                                                name={`q7_option99_${idx}`}
                                                                value={99}
                                                                checked={
                                                                    data.imwgStatus === 99
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q7_option99_${idx}`} >
                                                                <span className="radio-text-padding">Unknown</span></label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q7Empty} message={demographicScreen_Msgs.Q7} />
                                                </div>

                                                {/* Q8 */}
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q8.</span>
                                                        <span className="quest-text-pad">
                                                            Coronavirus disease 19 (COVID-19) vaccination
                                                        </span>
                                                    </div>

                                                    <div className=" double-dig-answer">
                                                        <div className="">
                                                            <input type="radio" id={`q8_option1_${idx}`}
                                                                name={`q8_option1_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.covidVaccine === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q8_option1_${idx}`}>
                                                                <span className="radio-text-padding">Yes</span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="radio" id={`q8_option2_${idx}`}
                                                                name={`q8_option2_${idx}`}
                                                                value={2}
                                                                checked={
                                                                    data.covidVaccine === 2
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q8_option2_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    No
                                                                </span></label>
                                                        </div>
                                                        <div className="">
                                                            <input type="radio" id={`q8_option99_${idx}`}
                                                                name={`q8_option99_${idx}`}
                                                                value={99}
                                                                checked={
                                                                    data.covidVaccine === 99
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q8_option99_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    Unknown
                                                                </span></label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q8Empty} message={demographicScreen_Msgs.Q8} />
                                                </div>

                                                {/* Q9 */}
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q9.</span>
                                                        <span className="quest-text-pad">
                                                            Estimated glomerular filtration rate (eGFR) laboratory assessment result
                                                        </span>
                                                    </div>

                                                    <div className="double-dig-answer">
                                                        <div className="option-fbox">
                                                            <label htmlFor={`q9_E_${idx}`}>
                                                                <input type="radio" id={`q9_E_${idx}`}
                                                                    name={`q9_E_${idx}`}
                                                                    value={1}
                                                                    checked={
                                                                        data.egfrMesurementEqualsRadio === 1
                                                                    }
                                                                    onChange={HandleRepeaterFieldChange} />
                                                            </label>
                                                            <label htmlFor={`q9_E_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    &nbsp;&nbsp;
                                                                    <input
                                                                        ref={inputRef}
                                                                        className="input-dash dash-demo"
                                                                        id={`q9_Etxt_${idx}`}
                                                                        name={`q9_Etxt_${idx}`}
                                                                        type="text"
                                                                        pattern="\d*"
                                                                        value={data.egfrMesurementEquals} //tk state
                                                                        min={0}
                                                                        max={300}
                                                                        //maxLength={5}
                                                                        onKeyDown={(e) => {
                                                                            // Allow backspace, arrow keys, and delete
                                                                            if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                              return;
                                                                            }
                                                                            // Prevent non-numeric characters, except '.'
                                                                            if (!/[0-9.]/.test(e.key)) {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                            const value = e.target.value;
                                                                            const selectionStart = e.target.selectionStart;
                                                                            const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                            // Prevent multiple '.'
                                                                            if (e.key === '.' && value.includes('.')) {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                            // Prevent '.' as the first character
                                                                            if (e.key === '.' && value === '') {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                            // Allow only one digit after decimal point
                                                                            if (newValue.includes('.') && newValue.split('.')[1].length > 1) {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                          }}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    mL/min/1.73m2
                                                                </span>
                                                            </label>
                                                        </div>
                                                        {/* pattern="^\d{1,2}(\.\d{0,1})?$" */}
                                                        <div className="option-fbox">
                                                            <label htmlFor={`q9_GT_${idx}`}>
                                                                <input
                                                                    type="radio"
                                                                    id={`q9_GT_${idx}`}
                                                                    name={`q9_GT_${idx}`}
                                                                    value={1}
                                                                    checked={data.egfrMesurementGreaterthanRadio === 1}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                />
                                                            </label>
                                                            <label htmlFor={`q9_GT_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    &gt;
                                                                    <input
                                                                        ref={inputRef}
                                                                        className="input-dash dash-demo"
                                                                        id={`q9_GTtxt_${idx}`}
                                                                        name={`q9_GTtxt_${idx}`}
                                                                        type="text"
                                                                        pattern="\d*"
                                                                        value={data.egfrMesurementGreaterthan}
                                                                        min={0}
                                                                        max={300}
                                                                        //maxLength={5}
                                                                        onKeyDown={(e) => {
                                                                            // Allow backspace, arrow keys, and delete
                                                                            if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                              return;
                                                                            }
                                                                            // Prevent non-numeric characters, except '.'
                                                                            if (!/[0-9.]/.test(e.key)) {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                            const value = e.target.value;
                                                                            const selectionStart = e.target.selectionStart;
                                                                            const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                            // Prevent multiple '.'
                                                                            if (e.key === '.' && value.includes('.')) {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                            // Prevent '.' as the first character
                                                                            if (e.key === '.' && value === '') {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                            // Allow only one digit after decimal point
                                                                            if (newValue.includes('.') && newValue.split('.')[1].length > 1) {
                                                                              e.preventDefault();
                                                                              return;
                                                                            }
                                                                          }}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    mL/min/1.73m2 (if exact eGFR is unavailable and only a lower limit is available)
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="option-fbox">
                                                            <label htmlFor={`q9_LT_${idx}`}>
                                                                <input
                                                                    type="radio"
                                                                    id={`q9_LT_${idx}`}
                                                                    name={`q9_LT_${idx}`}
                                                                    value={1}
                                                                    checked={data.egfrMesurementLessthanRadio === 1}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                />
                                                            </label>
                                                            <label htmlFor={`q9_LT_${idx}`}>
                                                                <span className="radio-text-padding">
                                                                    &lt;
                                                                    <input
                                                                        ref={inputRef}
                                                                        className="input-dash dash-demo"
                                                                        id={`q9_LTtxt_${idx}`}
                                                                        name={`q9_LTtxt_${idx}`}
                                                                        type="text"
                                                                        pattern="\d*"
                                                                        value={data.egfrMesurementLessthan} //tk state
                                                                        min={0}
                                                                        max={300}
                                                                        //maxLength={5}
                                                                        onKeyDown={(e) => {
                                                                            // Allow backspace, arrow keys, and delete
                                                                            if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                                return;
                                                                            }
                                                                            // Prevent non-numeric characters, except '.'
                                                                            if (!/[0-9.]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                                return;
                                                                            }
                                                                            const value = e.target.value;
                                                                            const selectionStart = e.target.selectionStart;
                                                                            const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                            // Prevent multiple '.'
                                                                            if (e.key === '.' && value.includes('.')) {
                                                                                e.preventDefault();
                                                                                return;
                                                                            }
                                                                            // Prevent '.' as the first character
                                                                            if (e.key === '.' && value === '') {
                                                                                e.preventDefault();
                                                                                return;
                                                                            }
                                                                            // Allow only one digit after decimal point
                                                                            if (newValue.includes('.') && newValue.split('.')[1].length > 1) {
                                                                                e.preventDefault();
                                                                                return;
                                                                            }
                                                                        }}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    mL/min/1.73m2 (if exact eGFR is unavailable and only an upper limit is available)
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="option-fbox">
                                                            <input style={{marginTop:"6px"}}
                                                                type="radio"
                                                                id={`q9_Unk_${idx}`}
                                                                name={`q9_Unk_${idx}`}
                                                                value={1}
                                                                checked={
                                                                    data.egfrMesurementUnk === 1
                                                                }
                                                                onChange={HandleRepeaterFieldChange} />
                                                            <label htmlFor={`q9_Unk_${idx}`}>
                                                                <span className="radio-text-padding">Unknown</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q9Empty} message={demographicScreen_Msgs.Q9} />
                                                    <ErrorField show={repeaterQ4ToQ10[idx].q9aEmpty} message={demographicScreen_Msgs.Q9a} />
                                                </div>

                                                {/* Q10 */}
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q10.</span>
                                                        <span className="quest-text-pad">
                                                            Latest recorded weight and height measurement
                                                        </span>
                                                    </div>
                                                    <div className="sub-question">
                                                        <table
                                                            style={{
                                                                border: "1px solid black",
                                                                borderCollapse: "collapse",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        Type of Assessment
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        Date of Assessment
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        Measurement
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        <span className="sub-question-quest"><b>Q10.1.</b></span>
                                                                        <span className="quest-text-pad">Weight</span>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        <div className="double-dig-answer">
                                                                            <div className="option-without-input">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q10_WDateRadio_${idx}`}
                                                                                    name={`q10_WDateRadio_${idx}`}
                                                                                    value={1}
                                                                                    checked={data.weightDateRadio === 1}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding" style={{ marginLeft: '-5px' }}>
                                                                                    {/* <DateControl
                                                                                        ctrlId={`q10_WDate_${idx}`}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        defDate={data.weightDate}
                                                                                        optionId={1}
                                                                                        date={data.weightDate}
                                                                                    /> */}
                                                                                    <DatePickerComponent
                                                                                        ctrlId={`q10_WDate_${idx}`}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        minDate={dayjs("01-01-1920")} 
                                                                                        maxDate={dayjs()} 
                                                                                        locale="en" // for dd/mm/yyyy use "en-gb"
                                                                                        date={data.weightDate}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q10_WDateNA_${idx}`}
                                                                                    name={`q10_WDateNA_${idx}`}
                                                                                    style={{ marginTop: '6px' }}
                                                                                    value={1}
                                                                                    checked={data.weightNotassessed === 1}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <label htmlFor={`q10_WDateNA_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        Weight not assessed or recorded during this time period
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField
                                                                            style={{ padding: '0px' }}
                                                                            show={repeaterQ4ToQ10[idx].q10aWEmpty}
                                                                            message={demographicScreen_Msgs.Q10a}
                                                                        />
                                                                          <ErrorField
                                                                            style={{ padding: '0px' }}
                                                                            show={repeaterQ4ToQ10[idx].q10aWDateInvalid}
                                                                            message={CommonError_Msgs.InvalidDate}
                                                                        />
                                                                        <ErrorField
                                                                            style={{ padding: '0px' }}
                                                                            show={repeaterQ4ToQ10[idx].q10aWDInvalid}
                                                                            message={demographicScreen_Msgs.Q10d}
                                                                        />
                                                                    </td>

                                                                    {repeaterQ4ToQ10[idx].showWMeasurement ? (
                                                                        <td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                            }}
                                                                        >
                                                                            <div className="">
                                                                                <label htmlFor={`q10_WMesKg_${idx}`}>
                                                                                    <input type="radio"
                                                                                        id={`q10_WMesKg_${idx}`}
                                                                                        name={`q10_WMesKg_${idx}`}
                                                                                        value={1}
                                                                                        checked={
                                                                                            data.weightValueRadio === 1
                                                                                        }
                                                                                        onChange={HandleRepeaterFieldChange} />
                                                                                </label>
                                                                                <label htmlFor={`q10_WMesKgtxt_${idx}`} className='bottom-spacing'>
                                                                                    <span className="radio-text-padding">
                                                                                        <input
                                                                                            ref={inputRef}
                                                                                            className="input-dash-custom input-dash"
                                                                                            id={`q10_WMesKgtxt_${idx}`}
                                                                                            name={`q10_WMesKgtxt_${idx}`}
                                                                                            type="text"
                                                                                            pattern="\d*"
                                                                                            value={data.weightValueKg} //tk state
                                                                                            min={1}
                                                                                            max={630}
                                                                                            //maxLength={6}
                                                                                            onKeyDown={(e) => {
                                                                                                // Allow backspace, arrow keys, and delete
                                                                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                                                    return;
                                                                                                }
                                                                                                // Prevent non-numeric characters, except '.'
                                                                                                if (!/[0-9.]/.test(e.key)) {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                                const value = e.target.value;
                                                                                                const selectionStart = e.target.selectionStart;
                                                                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                                                // Prevent multiple '.'
                                                                                                if (e.key === '.' && value.includes('.')) {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                                // Prevent '.' as the first character
                                                                                                if (e.key === '.' && value === '') {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                                // Allow only one digit after decimal point
                                                                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                            }}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                       {""} kg
                                                                                    </span>
                                                                                </label>
                                                                                <br />
                                                                                {/* <label><span className="radio-text-padding" style={{ marginLeft: '12px' }}>(Range: 1 to 630)</span></label> */}
                                                                            </div>

                                                                            <div className="">
                                                                                <label htmlFor={`q10_WMesLbs_${idx}`}>
                                                                                    <input type="radio"
                                                                                        id={`q10_WMesLbs_${idx}`}
                                                                                        name={`q10_WMesLbs_${idx}`}
                                                                                        value={2}
                                                                                        checked={
                                                                                            data.weightValueRadio === 2
                                                                                        }
                                                                                        onChange={HandleRepeaterFieldChange} />
                                                                                </label>
                                                                                <label htmlFor={`q10_WMesLbstxt_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        <input
                                                                                            ref={inputRef}
                                                                                            className="input-dash-custom input-dash"
                                                                                            id={`q10_WMesLbstxt_${idx}`}
                                                                                            name={`q10_WMesLbstxt_${idx}`}
                                                                                            type="text"
                                                                                            pattern="\d*"
                                                                                            value={data.weightValueLbs} //tk state
                                                                                            min={1}
                                                                                            max={1400}
                                                                                            // maxLength={7}
                                                                                            onKeyDown={(e) => {
                                                                                                // Allow backspace, arrow keys, and delete
                                                                                                if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
                                                                                                    return;
                                                                                                }
                                                                                                // Prevent non-numeric characters, except '.'
                                                                                                if (!/[0-9.]/.test(e.key)) {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                                const value = e.target.value;
                                                                                                const selectionStart = e.target.selectionStart;
                                                                                                const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
                                                                                                // Prevent multiple '.'
                                                                                                if (e.key === '.' && value.includes('.')) {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                                // Prevent '.' as the first character
                                                                                                if (e.key === '.' && value === '') {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                                // Allow only one digit after decimal point
                                                                                                if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
                                                                                                    e.preventDefault();
                                                                                                    return;
                                                                                                }
                                                                                            }}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        {""} lbs
                                                                                    </span>
                                                                                </label>
                                                                                <br />
                                                                                {/* <label><span className="radio-text-padding" style={{ marginLeft: '12px' }}>(Range: 1 to 1400)</span></label> */}
                                                                            </div>

                                                                            <div className="">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q10_WMesUnk_${idx}`}
                                                                                    name={`q10_WMesUnk_${idx}`}
                                                                                    value={99}
                                                                                    checked={data.weightValueRadio === 99}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <label htmlFor={`q10_WMesUnk_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        Unknown
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <ErrorField
                                                                                style={{ padding: '0px' }}
                                                                                show={repeaterQ4ToQ10[idx].q10bWEmpty}
                                                                                message={data.weightValueRadio === 1 ? demographicScreen_Msgs.Q10bKgw : data.weightValueRadio === 2 ? demographicScreen_Msgs.Q10bLbsw : demographicScreen_Msgs.Q10bw}
                                                                            />
                                                                        </td>) : (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign:"center"
                                                                            }}
                                                                        >
                                                                            <span className="not-applicable"style={{textAlign:"center"}} >Not applicable</span>
                                                                        </td>)}

                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        <span className="sub-question-quest"><b>Q10.2.</b></span>
                                                                        <span className="quest-text-pad">Height</span>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        <div className="double-dig-answer">
                                                                            <div className="option-without-input">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q10_HDateRadio_${idx}`}
                                                                                    name={`q10_HDateRadio_${idx}`}
                                                                                    value={1}
                                                                                    checked={data.heightDateRadio === 1}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding" style={{ marginLeft: '-5px' }}>
                                                                                    {/* <DateControl
                                                                                        ctrlId={`q10_HDate_${idx}`}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        defDate={data.heightDate}
                                                                                        optionId={1}
                                                                                        date={data.heightDate}
                                                                                    /> */}

                                                                                    <DatePickerComponent
                                                                                        ctrlId={`q10_HDate_${idx}`}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        minDate={dayjs("01-01-1920")} 
                                                                                        maxDate={dayjs()} 
                                                                                        locale="en" // for dd/mm/yyyy use "en-gb"
                                                                                        date={data.heightDate}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="option-without-input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q10_HDateNA_${idx}`}
                                                                                    name={`q10_HDateNA_${idx}`}
                                                                                    style={{ marginTop: '6px' }}
                                                                                    value={1}
                                                                                    checked={data.heightNotassessed === 1}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <label htmlFor={`q10_HDateNA_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        Height not assessed or recorded during this time period
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField
                                                                            style={{ padding: '0px' }}
                                                                            show={repeaterQ4ToQ10[idx].q10aHEmpty}
                                                                            message={demographicScreen_Msgs.Q10a}
                                                                        />
                                                                         <ErrorField
                                                                            style={{ padding: '0px' }}
                                                                            show={repeaterQ4ToQ10[idx].q10aHDateInvalid}
                                                                            message={CommonError_Msgs.InvalidDate}
                                                                        />
                                                                        <ErrorField
                                                                            style={{ padding: '0px' }}
                                                                            show={repeaterQ4ToQ10[idx].q10aHDInvalid}
                                                                            message={demographicScreen_Msgs.Q10d}
                                                                        />
                                                                    </td>

                                                                    {repeaterQ4ToQ10[idx].showHMeasurement ? (
                                                                        <td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                            }}
                                                                        >
                                                                            <div className="option-fbox">
                                                                                <label htmlFor="height_value">
                                                                                    <input type="radio"
                                                                                        id={`q10_HMesFtI_${idx}`}
                                                                                        name={`q10_HMesFtI_${idx}`}
                                                                                        value={1}
                                                                                        checked={
                                                                                            data.heightValueRadio === 1
                                                                                        }
                                                                                        onChange={HandleRepeaterFieldChange} />
                                                                                </label>
                                                                                <div>
                                                                                <label htmlFor={`q10_HMesFttxt_${idx}`} className="radio-text-padding bottom-spacing">
                                                                                    <span >
                                                                                        <input
                                                                                            ref={inputRef}
                                                                                            className="input-dash-custom input-dash"
                                                                                            id={`q10_HMesFttxt_${idx}`}
                                                                                            name={`q10_HMesFttxt_${idx}`}
                                                                                            type="text"
                                                                                            value={data.heightValueFt} //tk state
                                                                                            min={1}
                                                                                            max={9}
                                                                                            
                                                                                            //maxLength={1}
                                                                                            pattern="\d*"
                                                                                            onKeyDown={(e) => {
                                                                                                // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                                if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                    </span>
                                                                                    {""}
                                                                                    <span > feet and</span>
                                                                                </label>
                                                                                <label htmlFor={`q10_HMesItxt_${idx}`} className="radio-text-padding bottom-spacing">
                                                                                    <span >
                                                                                        <input
                                                                                            ref={inputRef}
                                                                                            className="input-dash-custom input-dash"
                                                                                            id={`q10_HMesItxt_${idx}`}
                                                                                            name={`q10_HMesItxt_${idx}`}
                                                                                            type="text"
                                                                                            pattern="\d*"
                                                                                            value={data.heightValueInch} //tk state
                                                                                            min={0}
                                                                                            max={11}
                                                                                            //maxLength={2}
                                                                                            onKeyDown={(e) => {
                                                                                                // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                                if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                    </span>
                                                                                    {" "}
                                                                                    <span>inches</span>
                                                                                </label>
                                                                                </div>
                                                                                <br />
                                                                                {/* <label><span className="radio-text-padding" style={{ marginLeft: '12px' }}>(Range Feet: 1 to 9)</span></label> */}
                                                                                {/* <label><span className="radio-text-padding" style={{ marginLeft: '12px' }}>(Range Inch: 0 to 11)</span></label> */}
                                                                            </div>

                                                                            <div className="">
                                                                                <label htmlFor="height_value">
                                                                                    <input type="radio"
                                                                                        id={`q10_HMesC_${idx}`}
                                                                                        name={`q10_HMesC_${idx}`}
                                                                                        value={2}
                                                                                        checked={
                                                                                            data.heightValueRadio === 2
                                                                                        }
                                                                                        onChange={HandleRepeaterFieldChange} />
                                                                                </label>
                                                                                <label htmlFor={`q10_HMesCtxt_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        <input
                                                                                            ref={inputRef}
                                                                                            className="input-dash-custom input-dash"
                                                                                            id={`q10_HMesCtxt_${idx}`}
                                                                                            name={`q10_HMesCtxt_${idx}`}
                                                                                            type="text"
                                                                                            pattern="\d*"
                                                                                            value={data.heightValueCm} //tk state
                                                                                            min={1}
                                                                                            max={300}
                                                                                            //maxLength={3}
                                                                                            onKeyDown={(e) => {
                                                                                                // Prevent entering '.', 'e', '+', '-', and any non-numeric characters
                                                                                                if (['.', 'e', '+', '-', 'E'].includes(e.key) || (e.key.length === 1 && !/[0-9]/.test(e.key))) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                    </span>
                                                                                   {""} centimeters
                                                                                </label>
                                                                                <br />
                                                                                {/* <label><span className="radio-text-padding" style={{ marginLeft: '12px' }}>(Range: 1 to 300)</span></label> */}
                                                                            </div>

                                                                            <div className="">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`q10_HMesUnk_${idx}`}
                                                                                    name={`q10_HMesUnk_${idx}`}
                                                                                    value={99}
                                                                                    checked={data.heightValueRadio === 99}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <label htmlFor={`q10_HMesUnk_${idx}`}>
                                                                                    <span className="radio-text-padding">
                                                                                        Unknown
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <ErrorField
                                                                                style={{ padding: '0px' }}
                                                                                show={repeaterQ4ToQ10[idx].q10bHEmpty}
                                                                                message={data.heightValueRadio === 1 ? demographicScreen_Msgs.Q10bFtIh : data.heightValueRadio === 2 ? demographicScreen_Msgs.Q10bCmh : demographicScreen_Msgs.Q10bh}
                                                                            />
                                                                        </td>) : (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign:"center"
                                                                            }}
                                                                        >
                                                                            <span className="not-applicable">Not applicable</span>
                                                                        </td>)}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Demographics